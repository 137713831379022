import { useState } from "react";

const useGoogle = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const uploadFileToDrive = async (
    file: File,
    metadata: { name: string }
  ): Promise<string | undefined> => {
    setLoading(true);

    const formData = new FormData();
    formData.append("metadata", JSON.stringify(metadata));

    formData.append("file", file);
    return await fetch(`${import.meta.env.VITE_GOOGLE_DRIVE_API}`, {
      method: "POST",
      body: formData,
    })
      .then(async (res) => {
        setLoading(false);
        if (res.ok) {
          const response = await res.json();
          if (response?.path) {
            return response.path;
          }
          return;
        } else {
          console.error("Failed to upload file", res.status, res.statusText);
          return undefined;
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error("Error uploading file", err);
        return undefined;
      });
  };

  return { loading, uploadFileToDrive };
};

export default useGoogle;
