import { Link, Stack, Typography } from "@mui/material";
import { CaseStudyLayout } from "./CaseStudyLayout";
import { CaseStudyLinkProps } from "@/constants/caseStudies";
import CaseStudyHeading from "./components/CaseStudyHeading";

export const GoodDollar = ({ ...props }: CaseStudyLinkProps) => {
  const { color } = props;
  return (
    <CaseStudyLayout {...props}>
      <Stack spacing={16}>
        <Stack spacing={2}>
          <CaseStudyHeading type='abstract' color={color} />
          <Typography sx={{ fontSize: 20 }}>
            <Link
              href='https://www.gooddollar.org/goodcollective'
              sx={{ color: color, fontWeight: 600 }}>
              GoodCollective
            </Link>{" "}
            is a series of smart contracts and a dApp built atop the{" "}
            <Link
              href='https://www.gooddollar.org/'
              sx={{ color: color, fontWeight: 600 }}>
              GoodDollar
            </Link>{" "}
            protocol. Its objective is twofold:
            <br />
            1) to channel donations (targeted distribution of Universal Basic
            Income), using crypto, to individuals who fit specific criteria
            <br />
            2) to tie those payment to specific people and activities (e.g.
            those verifiably completing climate-positive activities) to
            transparently report on detailed payment flow to frontline
            communities. The platform is designed to maximize the value paid to
            local individuals on the ground, making the most impact while
            reducing intermediaries and costs of both payment deployment and
            climate impact measurement. For the{" "}
            <Link
              href='https://medium.com/gooddollar/case-study-piloting-direct-payments-for-climate-finance-universal-basic-income-with-e55eba5a0bb2'
              sx={{ color: color, fontWeight: 600 }}>
              initial pilot
            </Link>{" "}
            and{" "}
            <Link
              href='https://goodcollective.xyz/collective/0xc1dcdf8e70acb44cdbb688c91a4883cf9052ea9c'
              sx={{ color: color, fontWeight: 600 }}>
              primary payment pool,
            </Link>
            Silvi has served as a climate action verification partner, and the
            infrastructure is designed to work with a variety of similar
            partners.
            <br />
            <br />
            GoodCollective was made possible by a grant from{" "}
            <Link
              href='https://climatecollective.org/'
              sx={{ color: color, fontWeight: 600 }}>
              Climate Collective
            </Link>{" "}
            and in-kind technical and product design support by GoodDollar. dOrg
            teamed up to develop a dApp interface to display activity on the
            underlying GoodCollective contracts.
            <br />
            <br />
            The solution design and payment mechanisms were provided by
            GoodDollar, a mission-driven protocol and cryptocurrency known for
            successfully onboarding hard-to-reach communities to crypto wallets.
            GoodDollar's decentralized payment infrastructure enables direct
            Universal Basic Income payments to individuals.
            <br />
            <br />
            GoodDollar requested our support for the front-end, back-end and
            web3 integrations coding of the GoodDollar/ GoodCollective MVP. We
            used the designs, user flows, and wireframes provided by GoodDollar
            / GoodCollective to facilitate this MVP development.
          </Typography>
        </Stack>

        <Stack spacing={2}>
          <CaseStudyHeading type='background' color={color} />
          <Typography sx={{ fontSize: 20 }}>
            <Link
              href='https://www.gooddollar.org/'
              sx={{ color: color, fontWeight: 600 }}>
              GoodDollar
            </Link>{" "}
            is a permissionless protocol built with the goal of reducing wealth
            inequality and providing economic empowerment to all individuals.
            The protocol creates a sustainable digital currency, known as
            GoodDollar tokens (G$), that can be distributed to users worldwide.
            <br />
            <br />
            The protocol operates on the principles of Universal Basic Income
            (UBI), where a fixed amount of G$ is distributed to users on a
            regular basis. One of the key features of the GoodDollar protocol is
            its use of smart contracts to automate the distribution of G$
            tokens. This ensures transparency and efficiency in the distribution
            process, eliminating the need for intermediaries and reducing
            transaction costs.
            <br />
            <br />
            We developed a mobile-first MVP React Native web app and Subgraph
            database to integrate GoodDollar&apos;s{" "}
            <Link
              href='https://github.com/GoodDollar/GoodCollective'
              sx={{ color: color, fontWeight: 600 }}>
              GoodCollective smart contracts
            </Link>
            .
          </Typography>
        </Stack>

        <Stack spacing={2}>
          <CaseStudyHeading type='focus' color={color} />
          <Typography sx={{ fontSize: 20 }}>
            To get this milestone-based project rolling, we divided it into
            three main milestones: back-end development, front-end development,
            and Web3 integrations.
            <br />
            <br />
            For the back-end development, we focused on creating a database with
            specific attributes like the project name, image, description, and
            links to the website, Twitter, Instagram, and Smart Contract on Celo
            Explorer. We also designed the database schema to include entities
            such as Project, User, Donation, and Transaction, each with detailed
            attributes necessary to the utility of the platform.
            <br />
            <br />
            These entities are interconnected, allowing any user to track
            interactions, financial transactions, and project updates in
            real-time. Our subgraph organizes on-chain data for quicker queries
            in the GoodCollective app. The GoodCollective app updates the
            GoodCollective smart contracts through transactions. The subgraph
            can be queried by any user.{" "}
            <Link
              href='https://github.com/GoodDollar/GoodCollective/blob/master/packages/subgraph/schema.graphql'
              sx={{ color: color, fontWeight: 600 }}>
              Explore the full Subgraph schema
            </Link>{" "}
            for all technical details.
            <br />
            <br />
            Moving on to front-end development and web3 integrations, we built
            mobile-first though made accommodations for how specific elements
            rendered on a desktop browser and we integrated smart contracts
            developed by GoodDollar with both the front-end and back-end of the
            dApp. Improvements and enhancements have been made since dOrg&apos;s
            involvement, executed by the GoodDollar team.
            <br />
            <br />
            The dApp was deployed on Celo but works on any chain, however, the
            transactions can only happen on Celo.
            <br />
            <br />
            The stack to develop the MVP was:
            <br />
            - React Native
            <br />
            - Subgraphs
            <br />
            - TypeScript
            <br />
            - AssemblyScript
            <br />
            - WAGMI
            <br />- GoodDollar and GoodCollective smart contracts systems
          </Typography>
        </Stack>
        <Stack spacing={2}>
          <CaseStudyHeading type='team' color={color} />
          <Typography sx={{ fontSize: 20 }}>
            We provided a dedicated Tech Lead, Front-End, Back-End & Web3
            Integrations Developers and a Project Manager.
          </Typography>
        </Stack>
        <Stack spacing={2}>
          <CaseStudyHeading type='conclusion' color={color} />
          <Typography sx={{ fontSize: 20 }}>
            In essence, GoodCollective now stands as a testament to our joint
            efforts, providing a platform for targeted distribution of segmented
            UBI. This can encompass a range of different initiatives such as
            Conservation Basic Income, where individuals receive fair
            compensation for their valuable climate-positive services, as well
            as initiatives to support socioeconomic collectives like Women&apos;s UBI
            or Emergency Crisis Collectives such as Natural Disaster UBI or
            Refugee UBI, among other potential applications. Ultimately,
            GoodCollective is committed to empowering individuals and
            communities by providing direct digital payments to those who need
            it the most.
          </Typography>
        </Stack>
      </Stack>
    </CaseStudyLayout>
  );
};
