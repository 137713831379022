import React from "react";

export const BaseMap = (
<g>
<path d="M323.596 758.27L319.865 758.234V762.001H323.596V758.27Z" fill="#373B3D"/>
<path d="M438.257 758.27L434.526 758.234V762.001H438.257V758.27Z" fill="#373B3D"/>
<path d="M323.596 750.114L319.865 750.079V753.845H323.596V750.114Z" fill="#373B3D"/>
<path d="M315.404 750.114L311.673 750.079V753.845H315.404V750.114Z" fill="#373B3D"/>
<path d="M364.543 741.956L360.812 741.921V745.687H364.543V741.956Z" fill="#373B3D"/>
<path d="M315.404 741.956L311.673 741.921V745.687H315.404V741.956Z" fill="#373B3D"/>
<path d="M315.404 733.813L311.673 733.777V737.544H315.404V733.813Z" fill="#373B3D"/>
<path d="M790.433 725.655L786.702 725.62V729.386H790.433V725.655Z" fill="#373B3D"/>
<path d="M315.404 725.655L311.673 725.62V729.386H315.404V725.655Z" fill="#373B3D"/>
<path d="M315.404 717.5L311.673 717.464V721.231H315.404V717.5Z" fill="#373B3D"/>
<path d="M1118.03 709.355L1114.3 709.32V713.086H1118.03V709.355Z" fill="#373B3D"/>
<path d="M1109.84 709.355L1106.11 709.32V713.086H1109.84V709.355Z" fill="#373B3D"/>
<path d="M315.404 709.355L311.673 709.32V713.086H315.404V709.355Z" fill="#373B3D"/>
<path d="M1126.23 701.198L1122.49 701.163V704.929H1126.23V701.198Z" fill="#373B3D"/>
<path d="M1118.03 701.198L1114.3 701.163V704.929H1118.03V701.198Z" fill="#373B3D"/>
<path d="M315.404 701.198L311.673 701.163V704.929H315.404V701.198Z" fill="#373B3D"/>
<path d="M1126.22 693.042L1122.49 693.006V696.773H1126.22V693.042Z" fill="#373B3D"/>
<path d="M1044.32 693.042L1040.59 693.006V696.773H1044.32V693.042Z" fill="#373B3D"/>
<path d="M315.404 693.042L311.673 693.006V696.773H315.404V693.042Z" fill="#373B3D"/>
<path d="M1142.61 684.897L1138.88 684.861V688.628H1142.61V684.897Z" fill="#373B3D"/>
<path d="M315.404 684.897L311.673 684.861V688.628H315.404V684.897Z" fill="#373B3D"/>
<path d="M1142.61 676.74L1138.88 676.705V680.471H1142.61V676.74Z" fill="#373B3D"/>
<path d="M1134.42 676.74L1130.69 676.705V680.471H1134.42V676.74Z" fill="#373B3D"/>
<path d="M1044.32 676.74L1040.59 676.705V680.471H1044.32V676.74Z" fill="#373B3D"/>
<path d="M1036.14 676.74L1032.41 676.705V680.471H1036.14V676.74Z" fill="#373B3D"/>
<path d="M1027.95 676.74L1024.22 676.705V680.471H1027.95V676.74Z" fill="#373B3D"/>
<path d="M315.404 676.74L311.673 676.705V680.471H315.404V676.74Z" fill="#373B3D"/>
<path d="M1134.42 668.584L1130.69 668.549V672.315H1134.42V668.584Z" fill="#373B3D"/>
<path d="M1052.51 668.584L1048.78 668.549V672.315H1052.51V668.584Z" fill="#373B3D"/>
<path d="M1044.32 668.584L1040.59 668.549V672.315H1044.32V668.584Z" fill="#373B3D"/>
<path d="M1036.14 668.584L1032.41 668.549V672.315H1036.14V668.584Z" fill="#373B3D"/>
<path d="M1027.95 668.584L1024.22 668.549V672.315H1027.95V668.584Z" fill="#373B3D"/>
<path d="M1019.76 668.584L1016.03 668.549V672.315H1019.76V668.584Z" fill="#373B3D"/>
<path d="M323.596 668.584L319.865 668.549V672.315H323.596V668.584Z" fill="#373B3D"/>
<path d="M1052.51 660.427L1048.78 660.392V664.158H1052.51V660.427Z" fill="#373B3D"/>
<path d="M1044.32 660.427L1040.59 660.392V664.158H1044.32V660.427Z" fill="#373B3D"/>
<path d="M1036.14 660.427L1032.41 660.392V664.158H1036.14V660.427Z" fill="#373B3D"/>
<path d="M1027.95 660.427L1024.22 660.392V664.158H1027.95V660.427Z" fill="#373B3D"/>
<path d="M1019.76 660.427L1016.03 660.392V664.158H1019.76V660.427Z" fill="#373B3D"/>
<path d="M1011.56 660.427L1007.83 660.392V664.158H1011.56V660.427Z" fill="#373B3D"/>
<path d="M946.042 660.427L942.311 660.392V664.158H946.042V660.427Z" fill="#373B3D"/>
<path d="M954.233 660.427L950.502 660.392V664.158H954.233V660.427Z" fill="#373B3D"/>
<path d="M372.734 660.427L369.003 660.392V664.158H372.734V660.427Z" fill="#373B3D"/>
<path d="M323.596 660.427L319.865 660.392V664.158H323.596V660.427Z" fill="#373B3D"/>
<path d="M1060.7 652.283L1056.97 652.248V656.014H1060.7V652.283Z" fill="#373B3D"/>
<path d="M1052.51 652.283L1048.78 652.248V656.014H1052.51V652.283Z" fill="#373B3D"/>
<path d="M1044.32 652.283L1040.59 652.248V656.014H1044.32V652.283Z" fill="#373B3D"/>
<path d="M1036.14 652.283L1032.41 652.248V656.014H1036.14V652.283Z" fill="#373B3D"/>
<path d="M1027.95 652.283L1024.22 652.248V656.014H1027.95V652.283Z" fill="#373B3D"/>
<path d="M1019.76 652.283L1016.03 652.248V656.014H1019.76V652.283Z" fill="#373B3D"/>
<path d="M1011.56 652.283L1007.83 652.248V656.014H1011.56V652.283Z" fill="#373B3D"/>
<path d="M1003.37 652.283L999.642 652.248V656.014H1003.37V652.283Z" fill="#373B3D"/>
<path d="M978.809 652.283L975.078 652.248V656.014H978.809V652.283Z" fill="#373B3D"/>
<path d="M970.617 652.283L966.885 652.248V656.014H970.617V652.283Z" fill="#373B3D"/>
<path d="M962.425 652.283L958.694 652.248V656.014H962.425V652.283Z" fill="#373B3D"/>
<path d="M954.233 652.283L950.502 652.248V656.014H954.233V652.283Z" fill="#373B3D"/>
<path d="M946.042 652.283L942.311 652.248V656.014H946.042V652.283Z" fill="#373B3D"/>
<path d="M651.196 652.283L647.465 652.248V656.014H651.196V652.283Z" fill="#373B3D"/>
<path d="M643.004 652.283L639.273 652.248V656.014H643.004V652.283Z" fill="#373B3D"/>
<path d="M643.004 660.427L639.273 660.392V664.158H643.004V660.427Z" fill="#373B3D"/>
<path d="M634.813 652.283L631.082 652.248V656.014H634.813V652.283Z" fill="#373B3D"/>
<path d="M634.813 660.427L631.082 660.392V664.158H634.813V660.427Z" fill="#373B3D"/>
<path d="M626.633 652.283L622.902 652.248V656.014H626.633V652.283Z" fill="#373B3D"/>
<path d="M626.633 660.427L622.902 660.392V664.158H626.633V660.427Z" fill="#373B3D"/>
<path d="M618.441 652.283L614.71 652.248V656.014H618.441V652.283Z" fill="#373B3D"/>
<path d="M618.441 660.427L614.71 660.392V664.158H618.441V660.427Z" fill="#373B3D"/>
<path d="M380.926 652.283L377.195 652.248V656.014H380.926V652.283Z" fill="#373B3D"/>
<path d="M380.926 660.427L377.195 660.392V664.158H380.926V660.427Z" fill="#373B3D"/>
<path d="M372.735 652.283L369.004 652.248V656.014H372.735V652.283Z" fill="#373B3D"/>
<path d="M323.596 652.283L319.865 652.248V656.014H323.596V652.283Z" fill="#373B3D"/>
<path d="M1060.7 644.127L1056.97 644.091V647.858H1060.7V644.127Z" fill="#373B3D"/>
<path d="M1052.51 644.127L1048.78 644.091V647.858H1052.51V644.127Z" fill="#373B3D"/>
<path d="M1044.32 644.127L1040.59 644.091V647.858H1044.32V644.127Z" fill="#373B3D"/>
<path d="M1036.14 644.127L1032.41 644.091V647.858H1036.14V644.127Z" fill="#373B3D"/>
<path d="M1027.95 644.127L1024.22 644.091V647.858H1027.95V644.127Z" fill="#373B3D"/>
<path d="M1019.76 644.127L1016.03 644.091V647.858H1019.76V644.127Z" fill="#373B3D"/>
<path d="M1011.56 644.127L1007.83 644.091V647.858H1011.56V644.127Z" fill="#373B3D"/>
<path d="M1003.37 644.127L999.642 644.091V647.858H1003.37V644.127Z" fill="#373B3D"/>
<path d="M995.181 644.127L991.45 644.091V647.858H995.181V644.127Z" fill="#373B3D"/>
<path d="M986.989 644.126L983.258 644.091V647.857H986.989V644.126Z" fill="#373B3D"/>
<path d="M978.809 644.127L975.078 644.091V647.858H978.809V644.127Z" fill="#373B3D"/>
<path d="M970.617 644.127L966.886 644.091V647.858H970.617V644.127Z" fill="#373B3D"/>
<path d="M962.425 644.127L958.694 644.091V647.858H962.425V644.127Z" fill="#373B3D"/>
<path d="M954.233 644.127L950.502 644.091V647.858H954.233V644.127Z" fill="#373B3D"/>
<path d="M946.042 644.127L942.311 644.091V647.858H946.042V644.127Z" fill="#373B3D"/>
<path d="M937.85 644.127L934.119 644.091V647.858H937.85V644.127Z" fill="#373B3D"/>
<path d="M659.388 644.127L655.657 644.091V647.858H659.388V644.127Z" fill="#373B3D"/>
<path d="M651.196 644.127L647.465 644.091V647.858H651.196V644.127Z" fill="#373B3D"/>
<path d="M643.005 644.127L639.274 644.091V647.858H643.005V644.127Z" fill="#373B3D"/>
<path d="M634.813 644.127L631.082 644.091V647.858H634.813V644.127Z" fill="#373B3D"/>
<path d="M626.633 644.127L622.902 644.091V647.858H626.633V644.127Z" fill="#373B3D"/>
<path d="M618.441 644.127L614.71 644.091V647.858H618.441V644.127Z" fill="#373B3D"/>
<path d="M389.118 644.127L385.387 644.091V647.858H389.118V644.127Z" fill="#373B3D"/>
<path d="M380.926 644.127L377.195 644.091V647.858H380.926V644.127Z" fill="#373B3D"/>
<path d="M372.734 644.127L369.003 644.091V647.858H372.734V644.127Z" fill="#373B3D"/>
<path d="M323.596 644.127L319.865 644.091V647.858H323.596V644.127Z" fill="#373B3D"/>
<path d="M1060.7 635.97L1056.97 635.935V639.701H1060.7V635.97Z" fill="#373B3D"/>
<path d="M1052.51 635.97L1048.78 635.935V639.701H1052.51V635.97Z" fill="#373B3D"/>
<path d="M1044.32 635.97L1040.59 635.935V639.701H1044.32V635.97Z" fill="#373B3D"/>
<path d="M1036.14 635.97L1032.41 635.935V639.701H1036.14V635.97Z" fill="#373B3D"/>
<path d="M1027.95 635.97L1024.22 635.935V639.701H1027.95V635.97Z" fill="#373B3D"/>
<path d="M1019.76 635.97L1016.03 635.935V639.701H1019.76V635.97Z" fill="#373B3D"/>
<path d="M1011.56 635.97L1007.83 635.935V639.701H1011.56V635.97Z" fill="#373B3D"/>
<path d="M1003.37 635.97L999.642 635.935V639.701H1003.37V635.97Z" fill="#373B3D"/>
<path d="M995.181 635.97L991.45 635.935V639.701H995.181V635.97Z" fill="#373B3D"/>
<path d="M986.989 635.97L983.258 635.935V639.701H986.989V635.97Z" fill="#373B3D"/>
<path d="M978.809 635.97L975.078 635.935V639.701H978.809V635.97Z" fill="#373B3D"/>
<path d="M970.617 635.97L966.886 635.935V639.701H970.617V635.97Z" fill="#373B3D"/>
<path d="M962.425 635.97L958.694 635.935V639.701H962.425V635.97Z" fill="#373B3D"/>
<path d="M954.233 635.97L950.502 635.935V639.701H954.233V635.97Z" fill="#373B3D"/>
<path d="M946.042 635.97L942.311 635.935V639.701H946.042V635.97Z" fill="#373B3D"/>
<path d="M937.85 635.97L934.119 635.935V639.701H937.85V635.97Z" fill="#373B3D"/>
<path d="M659.388 635.97L655.657 635.935V639.701H659.388V635.97Z" fill="#373B3D"/>
<path d="M651.196 635.97L647.465 635.935V639.701H651.196V635.97Z" fill="#373B3D"/>
<path d="M643.005 635.97L639.274 635.935V639.701H643.005V635.97Z" fill="#373B3D"/>
<path d="M634.813 635.97L631.082 635.935V639.701H634.813V635.97Z" fill="#373B3D"/>
<path d="M626.633 635.97L622.902 635.935V639.701H626.633V635.97Z" fill="#373B3D"/>
<path d="M618.441 635.97L614.71 635.935V639.701H618.441V635.97Z" fill="#373B3D"/>
<path d="M610.249 635.97L606.518 635.935V639.701H610.249V635.97Z" fill="#373B3D"/>
<path d="M397.298 635.97L393.567 635.935V639.701H397.298V635.97Z" fill="#373B3D"/>
<path d="M389.118 635.97L385.387 635.935V639.701H389.118V635.97Z" fill="#373B3D"/>
<path d="M380.926 635.97L377.195 635.935V639.701H380.926V635.97Z" fill="#373B3D"/>
<path d="M323.596 635.97L319.865 635.935V639.701H323.596V635.97Z" fill="#373B3D"/>
<path d="M1060.7 627.825L1056.97 627.79V631.556H1060.7V627.825Z" fill="#373B3D"/>
<path d="M1052.51 627.825L1048.78 627.79V631.556H1052.51V627.825Z" fill="#373B3D"/>
<path d="M1044.32 627.825L1040.59 627.79V631.556H1044.32V627.825Z" fill="#373B3D"/>
<path d="M1036.14 627.825L1032.41 627.79V631.556H1036.14V627.825Z" fill="#373B3D"/>
<path d="M1027.95 627.825L1024.22 627.79V631.556H1027.95V627.825Z" fill="#373B3D"/>
<path d="M1019.76 627.825L1016.03 627.79V631.556H1019.76V627.825Z" fill="#373B3D"/>
<path d="M1011.56 627.825L1007.83 627.79V631.556H1011.56V627.825Z" fill="#373B3D"/>
<path d="M1003.37 627.825L999.642 627.79V631.556H1003.37V627.825Z" fill="#373B3D"/>
<path d="M995.181 627.825L991.45 627.79V631.556H995.181V627.825Z" fill="#373B3D"/>
<path d="M986.989 627.825L983.258 627.79V631.556H986.989V627.825Z" fill="#373B3D"/>
<path d="M978.809 627.825L975.078 627.79V631.556H978.809V627.825Z" fill="#373B3D"/>
<path d="M970.617 627.825L966.886 627.79V631.556H970.617V627.825Z" fill="#373B3D"/>
<path d="M962.425 627.825L958.694 627.79V631.556H962.425V627.825Z" fill="#373B3D"/>
<path d="M954.233 627.825L950.502 627.79V631.556H954.233V627.825Z" fill="#373B3D"/>
<path d="M946.042 627.825L942.311 627.79V631.556H946.042V627.825Z" fill="#373B3D"/>
<path d="M937.85 627.825L934.119 627.79V631.556H937.85V627.825Z" fill="#373B3D"/>
<path d="M667.58 627.825L663.849 627.79V631.556H667.58V627.825Z" fill="#373B3D"/>
<path d="M659.388 627.825L655.657 627.79V631.556H659.388V627.825Z" fill="#373B3D"/>
<path d="M651.196 627.825L647.465 627.79V631.556H651.196V627.825Z" fill="#373B3D"/>
<path d="M643.005 627.825L639.274 627.79V631.556H643.005V627.825Z" fill="#373B3D"/>
<path d="M634.813 627.825L631.082 627.79V631.556H634.813V627.825Z" fill="#373B3D"/>
<path d="M626.633 627.825L622.902 627.79V631.556H626.633V627.825Z" fill="#373B3D"/>
<path d="M618.441 627.825L614.71 627.79V631.556H618.441V627.825Z" fill="#373B3D"/>
<path d="M610.249 627.825L606.518 627.79V631.556H610.249V627.825Z" fill="#373B3D"/>
<path d="M397.298 627.825L393.567 627.79V631.556H397.298V627.825Z" fill="#373B3D"/>
<path d="M389.118 627.825L385.387 627.79V631.556H389.118V627.825Z" fill="#373B3D"/>
<path d="M372.734 627.825L369.003 627.79V631.556H372.734V627.825Z" fill="#373B3D"/>
<path d="M331.775 627.825L328.044 627.79V631.556H331.775V627.825Z" fill="#373B3D"/>
<path d="M1052.51 619.669L1048.78 619.633V623.4H1052.51V619.669Z" fill="#373B3D"/>
<path d="M1044.32 619.669L1040.59 619.633V623.4H1044.32V619.669Z" fill="#373B3D"/>
<path d="M1036.14 619.669L1032.41 619.633V623.4H1036.14V619.669Z" fill="#373B3D"/>
<path d="M1027.95 619.669L1024.22 619.633V623.4H1027.95V619.669Z" fill="#373B3D"/>
<path d="M1019.76 619.669L1016.03 619.633V623.4H1019.76V619.669Z" fill="#373B3D"/>
<path d="M1011.56 619.669L1007.83 619.633V623.4H1011.56V619.669Z" fill="#373B3D"/>
<path d="M1003.37 619.669L999.642 619.633V623.4H1003.37V619.669Z" fill="#373B3D"/>
<path d="M995.181 619.669L991.45 619.633V623.4H995.181V619.669Z" fill="#373B3D"/>
<path d="M986.989 619.669L983.258 619.633V623.4H986.989V619.669Z" fill="#373B3D"/>
<path d="M978.809 619.669L975.078 619.633V623.4H978.809V619.669Z" fill="#373B3D"/>
<path d="M970.617 619.669L966.886 619.633V623.4H970.617V619.669Z" fill="#373B3D"/>
<path d="M962.425 619.669L958.694 619.633V623.4H962.425V619.669Z" fill="#373B3D"/>
<path d="M954.233 619.669L950.502 619.633V623.4H954.233V619.669Z" fill="#373B3D"/>
<path d="M946.042 619.669L942.311 619.633V623.4H946.042V619.669Z" fill="#373B3D"/>
<path d="M937.849 619.669L934.118 619.633V623.4H937.849V619.669Z" fill="#373B3D"/>
<path d="M716.719 619.669L712.988 619.633V623.4H716.719V619.669Z" fill="#373B3D"/>
<path d="M708.528 619.669L704.797 619.633V623.4H708.528V619.669Z" fill="#373B3D"/>
<path d="M675.772 619.669L672.041 619.633V623.4H675.772V619.669Z" fill="#373B3D"/>
<path d="M667.58 619.669L663.849 619.633V623.4H667.58V619.669Z" fill="#373B3D"/>
<path d="M659.388 619.669L655.657 619.633V623.4H659.388V619.669Z" fill="#373B3D"/>
<path d="M651.196 619.669L647.465 619.633V623.4H651.196V619.669Z" fill="#373B3D"/>
<path d="M643.004 619.669L639.273 619.633V623.4H643.004V619.669Z" fill="#373B3D"/>
<path d="M634.813 619.669L631.082 619.633V623.4H634.813V619.669Z" fill="#373B3D"/>
<path d="M626.633 619.669L622.902 619.633V623.4H626.633V619.669Z" fill="#373B3D"/>
<path d="M618.441 619.669L614.71 619.633V623.4H618.441V619.669Z" fill="#373B3D"/>
<path d="M610.25 619.669L606.519 619.633V623.4H610.25V619.669Z" fill="#373B3D"/>
<path d="M405.49 619.669L401.759 619.633V623.4H405.49V619.669Z" fill="#373B3D"/>
<path d="M397.298 619.669L393.567 619.633V623.4H397.298V619.669Z" fill="#373B3D"/>
<path d="M389.118 619.669L385.387 619.633V623.4H389.118V619.669Z" fill="#373B3D"/>
<path d="M380.926 619.669L377.195 619.633V623.4H380.926V619.669Z" fill="#373B3D"/>
<path d="M372.734 619.669L369.003 619.633V623.4H372.734V619.669Z" fill="#373B3D"/>
<path d="M364.542 619.669L360.811 619.633V623.4H364.542V619.669Z" fill="#373B3D"/>
<path d="M331.776 619.669L328.045 619.633V623.4H331.776V619.669Z" fill="#373B3D"/>
<path d="M1109.84 611.512L1106.11 611.477V615.243H1109.84V611.512Z" fill="#373B3D"/>
<path d="M1044.32 611.512L1040.59 611.477V615.243H1044.32V611.512Z" fill="#373B3D"/>
<path d="M1036.14 611.512L1032.41 611.477V615.243H1036.14V611.512Z" fill="#373B3D"/>
<path d="M1027.95 611.512L1024.22 611.477V615.243H1027.95V611.512Z" fill="#373B3D"/>
<path d="M1019.76 611.512L1016.03 611.477V615.243H1019.76V611.512Z" fill="#373B3D"/>
<path d="M1011.56 611.512L1007.83 611.477V615.243H1011.56V611.512Z" fill="#373B3D"/>
<path d="M1003.37 611.512L999.642 611.477V615.243H1003.37V611.512Z" fill="#373B3D"/>
<path d="M995.181 611.512L991.45 611.477V615.243H995.181V611.512Z" fill="#373B3D"/>
<path d="M986.989 611.513L983.258 611.478V615.244H986.989V611.513Z" fill="#373B3D"/>
<path d="M978.809 611.512L975.078 611.477V615.243H978.809V611.512Z" fill="#373B3D"/>
<path d="M970.617 611.512L966.886 611.477V615.243H970.617V611.512Z" fill="#373B3D"/>
<path d="M962.425 611.512L958.694 611.477V615.243H962.425V611.512Z" fill="#373B3D"/>
<path d="M954.233 611.512L950.502 611.477V615.243H954.233V611.512Z" fill="#373B3D"/>
<path d="M946.042 611.512L942.311 611.477V615.243H946.042V611.512Z" fill="#373B3D"/>
<path d="M716.719 611.512L712.988 611.477V615.243H716.719V611.512Z" fill="#373B3D"/>
<path d="M708.528 611.513L704.797 611.478V615.244H708.528V611.513Z" fill="#373B3D"/>
<path d="M667.58 611.512L663.849 611.477V615.243H667.58V611.512Z" fill="#373B3D"/>
<path d="M659.388 611.512L655.657 611.477V615.243H659.388V611.512Z" fill="#373B3D"/>
<path d="M651.196 611.513L647.465 611.478V615.244H651.196V611.513Z" fill="#373B3D"/>
<path d="M643.004 611.512L639.273 611.477V615.243H643.004V611.512Z" fill="#373B3D"/>
<path d="M634.813 611.512L631.082 611.477V615.243H634.813V611.512Z" fill="#373B3D"/>
<path d="M626.633 611.512L622.902 611.477V615.243H626.633V611.512Z" fill="#373B3D"/>
<path d="M618.441 611.512L614.71 611.477V615.243H618.441V611.512Z" fill="#373B3D"/>
<path d="M610.25 611.512L606.519 611.477V615.243H610.25V611.512Z" fill="#373B3D"/>
<path d="M421.874 611.512L418.143 611.477V615.243H421.874V611.512Z" fill="#373B3D"/>
<path d="M413.682 611.512L409.951 611.477V615.243H413.682V611.512Z" fill="#373B3D"/>
<path d="M405.49 611.512L401.759 611.477V615.243H405.49V611.512Z" fill="#373B3D"/>
<path d="M397.298 611.512L393.567 611.477V615.243H397.298V611.512Z" fill="#373B3D"/>
<path d="M389.118 611.512L385.387 611.477V615.243H389.118V611.512Z" fill="#373B3D"/>
<path d="M380.926 611.512L377.195 611.477V615.243H380.926V611.512Z" fill="#373B3D"/>
<path d="M372.734 611.512L369.003 611.477V615.243H372.734V611.512Z" fill="#373B3D"/>
<path d="M364.542 611.512L360.811 611.477V615.243H364.542V611.512Z" fill="#373B3D"/>
<path d="M356.351 611.512L352.62 611.477V615.243H356.351V611.512Z" fill="#373B3D"/>
<path d="M348.159 611.512L344.428 611.477V615.243H348.159V611.512Z" fill="#373B3D"/>
<path d="M339.968 611.512L336.237 611.477V615.243H339.968V611.512Z" fill="#373B3D"/>
<path d="M331.776 611.512L328.045 611.477V615.243H331.776V611.512Z" fill="#373B3D"/>
<path d="M1036.14 603.356L1032.41 603.32V607.087H1036.14V603.356Z" fill="#373B3D"/>
<path d="M1027.95 603.356L1024.22 603.32V607.087H1027.95V603.356Z" fill="#373B3D"/>
<path d="M1019.76 603.356L1016.03 603.32V607.087H1019.76V603.356Z" fill="#373B3D"/>
<path d="M1011.56 603.356L1007.83 603.32V607.087H1011.56V603.356Z" fill="#373B3D"/>
<path d="M1003.37 603.356L999.642 603.32V607.087H1003.37V603.356Z" fill="#373B3D"/>
<path d="M995.181 603.356L991.45 603.32V607.087H995.181V603.356Z" fill="#373B3D"/>
<path d="M986.989 603.356L983.258 603.321V607.087H986.989V603.356Z" fill="#373B3D"/>
<path d="M978.809 603.356L975.078 603.32V607.087H978.809V603.356Z" fill="#373B3D"/>
<path d="M970.617 603.356L966.886 603.32V607.087H970.617V603.356Z" fill="#373B3D"/>
<path d="M962.425 603.356L958.694 603.32V607.087H962.425V603.356Z" fill="#373B3D"/>
<path d="M716.719 603.356L712.988 603.32V607.087H716.719V603.356Z" fill="#373B3D"/>
<path d="M708.527 603.356L704.796 603.32V607.087H708.527V603.356Z" fill="#373B3D"/>
<path d="M675.772 603.356L672.041 603.32V607.087H675.772V603.356Z" fill="#373B3D"/>
<path d="M667.58 603.356L663.849 603.32V607.087H667.58V603.356Z" fill="#373B3D"/>
<path d="M659.388 603.356L655.657 603.32V607.087H659.388V603.356Z" fill="#373B3D"/>
<path d="M651.196 603.356L647.465 603.32V607.087H651.196V603.356Z" fill="#373B3D"/>
<path d="M643.005 603.356L639.274 603.32V607.087H643.005V603.356Z" fill="#373B3D"/>
<path d="M634.813 603.356L631.082 603.32V607.087H634.813V603.356Z" fill="#373B3D"/>
<path d="M626.633 603.356L622.902 603.32V607.087H626.633V603.356Z" fill="#373B3D"/>
<path d="M618.441 603.356L614.71 603.32V607.087H618.441V603.356Z" fill="#373B3D"/>
<path d="M610.249 603.356L606.518 603.32V607.087H610.249V603.356Z" fill="#373B3D"/>
<path d="M421.873 603.356L418.142 603.32V607.087H421.873V603.356Z" fill="#373B3D"/>
<path d="M413.682 603.356L409.951 603.32V607.087H413.682V603.356Z" fill="#373B3D"/>
<path d="M405.49 603.356L401.759 603.32V607.087H405.49V603.356Z" fill="#373B3D"/>
<path d="M397.298 603.356L393.567 603.32V607.087H397.298V603.356Z" fill="#373B3D"/>
<path d="M389.118 603.356L385.387 603.32V607.087H389.118V603.356Z" fill="#373B3D"/>
<path d="M380.926 603.356L377.195 603.32V607.087H380.926V603.356Z" fill="#373B3D"/>
<path d="M372.734 603.356L369.003 603.32V607.087H372.734V603.356Z" fill="#373B3D"/>
<path d="M364.543 603.356L360.812 603.32V607.087H364.543V603.356Z" fill="#373B3D"/>
<path d="M356.351 603.356L352.62 603.32V607.087H356.351V603.356Z" fill="#373B3D"/>
<path d="M348.159 603.356L344.428 603.32V607.087H348.159V603.356Z" fill="#373B3D"/>
<path d="M339.968 603.356L336.237 603.32V607.087H339.968V603.356Z" fill="#373B3D"/>
<path d="M331.775 603.356L328.044 603.32V607.087H331.775V603.356Z" fill="#373B3D"/>
<path d="M1150.8 595.211L1147.07 595.176V598.942H1150.8V595.211Z" fill="#373B3D"/>
<path d="M1118.03 595.211L1114.3 595.176V598.942H1118.03V595.211Z" fill="#373B3D"/>
<path d="M1036.14 595.211L1032.41 595.176V598.942H1036.14V595.211Z" fill="#373B3D"/>
<path d="M1027.95 595.211L1024.22 595.176V598.942H1027.95V595.211Z" fill="#373B3D"/>
<path d="M1011.56 595.211L1007.83 595.176V598.942H1011.56V595.211Z" fill="#373B3D"/>
<path d="M1003.37 595.211L999.642 595.176V598.942H1003.37V595.211Z" fill="#373B3D"/>
<path d="M995.181 595.211L991.45 595.176V598.942H995.181V595.211Z" fill="#373B3D"/>
<path d="M986.989 595.211L983.258 595.176V598.942H986.989V595.211Z" fill="#373B3D"/>
<path d="M986.989 587.054L983.258 587.019V590.785H986.989V587.054Z" fill="#373B3D"/>
<path d="M978.809 595.211L975.078 595.176V598.942H978.809V595.211Z" fill="#373B3D"/>
<path d="M970.617 595.211L966.886 595.176V598.942H970.617V595.211Z" fill="#373B3D"/>
<path d="M716.719 595.211L712.988 595.176V598.942H716.719V595.211Z" fill="#373B3D"/>
<path d="M716.719 587.054L712.988 587.019V590.785H716.719V587.054Z" fill="#373B3D"/>
<path d="M708.527 595.211L704.796 595.176V598.942H708.527V595.211Z" fill="#373B3D"/>
<path d="M683.964 595.211L680.233 595.176V598.942H683.964V595.211Z" fill="#373B3D"/>
<path d="M675.772 595.211L672.041 595.176V598.942H675.772V595.211Z" fill="#373B3D"/>
<path d="M667.58 595.211L663.849 595.176V598.942H667.58V595.211Z" fill="#373B3D"/>
<path d="M659.388 595.211L655.657 595.176V598.942H659.388V595.211Z" fill="#373B3D"/>
<path d="M651.196 595.211L647.465 595.176V598.942H651.196V595.211Z" fill="#373B3D"/>
<path d="M643.005 595.211L639.274 595.176V598.942H643.005V595.211Z" fill="#373B3D"/>
<path d="M634.813 595.211L631.082 595.176V598.942H634.813V595.211Z" fill="#373B3D"/>
<path d="M626.633 595.211L622.902 595.176V598.942H626.633V595.211Z" fill="#373B3D"/>
<path d="M618.441 595.211L614.71 595.176V598.942H618.441V595.211Z" fill="#373B3D"/>
<path d="M610.249 595.211L606.518 595.176V598.942H610.249V595.211Z" fill="#373B3D"/>
<path d="M602.057 595.211L598.326 595.176V598.942H602.057V595.211Z" fill="#373B3D"/>
<path d="M602.057 603.356L598.326 603.32V607.087H602.057V603.356Z" fill="#373B3D"/>
<path d="M430.065 595.211L426.334 595.176V598.942H430.065V595.211Z" fill="#373B3D"/>
<path d="M421.873 595.211L418.142 595.176V598.942H421.873V595.211Z" fill="#373B3D"/>
<path d="M413.682 595.211L409.951 595.176V598.942H413.682V595.211Z" fill="#373B3D"/>
<path d="M405.49 595.211L401.759 595.176V598.942H405.49V595.211Z" fill="#373B3D"/>
<path d="M397.298 595.211L393.567 595.176V598.942H397.298V595.211Z" fill="#373B3D"/>
<path d="M389.118 595.211L385.387 595.176V598.942H389.118V595.211Z" fill="#373B3D"/>
<path d="M380.926 595.211L377.195 595.176V598.942H380.926V595.211Z" fill="#373B3D"/>
<path d="M372.734 595.211L369.003 595.176V598.942H372.734V595.211Z" fill="#373B3D"/>
<path d="M364.543 595.211L360.812 595.176V598.942H364.543V595.211Z" fill="#373B3D"/>
<path d="M356.351 595.211L352.62 595.176V598.942H356.351V595.211Z" fill="#373B3D"/>
<path d="M348.159 595.211L344.428 595.176V598.942H348.159V595.211Z" fill="#373B3D"/>
<path d="M339.968 595.211L336.237 595.176V598.942H339.968V595.211Z" fill="#373B3D"/>
<path d="M331.775 595.211L328.044 595.176V598.942H331.775V595.211Z" fill="#373B3D"/>
<path d="M1027.95 587.054L1024.22 587.019V590.785H1027.95V587.054Z" fill="#373B3D"/>
<path d="M1003.37 587.054L999.642 587.019V590.785H1003.37V587.054Z" fill="#373B3D"/>
<path d="M995.181 587.054L991.45 587.019V590.785H995.181V587.054Z" fill="#373B3D"/>
<path d="M692.143 587.054L688.412 587.019V590.785H692.143V587.054Z" fill="#373B3D"/>
<path d="M683.964 587.054L680.233 587.019V590.785H683.964V587.054Z" fill="#373B3D"/>
<path d="M675.772 587.054L672.041 587.019V590.785H675.772V587.054Z" fill="#373B3D"/>
<path d="M667.58 587.054L663.849 587.019V590.785H667.58V587.054Z" fill="#373B3D"/>
<path d="M659.388 587.054L655.657 587.019V590.785H659.388V587.054Z" fill="#373B3D"/>
<path d="M651.196 587.054L647.465 587.019V590.785H651.196V587.054Z" fill="#373B3D"/>
<path d="M643.005 587.054L639.274 587.019V590.785H643.005V587.054Z" fill="#373B3D"/>
<path d="M634.813 587.054L631.082 587.019V590.785H634.813V587.054Z" fill="#373B3D"/>
<path d="M626.633 587.054L622.902 587.019V590.785H626.633V587.054Z" fill="#373B3D"/>
<path d="M618.441 587.054L614.71 587.019V590.785H618.441V587.054Z" fill="#373B3D"/>
<path d="M610.249 587.054L606.518 587.019V590.785H610.249V587.054Z" fill="#373B3D"/>
<path d="M602.057 587.054L598.326 587.019V590.785H602.057V587.054Z" fill="#373B3D"/>
<path d="M430.065 587.054L426.334 587.019V590.785H430.065V587.054Z" fill="#373B3D"/>
<path d="M421.873 587.054L418.142 587.019V590.785H421.873V587.054Z" fill="#373B3D"/>
<path d="M413.682 587.054L409.951 587.019V590.785H413.682V587.054Z" fill="#373B3D"/>
<path d="M405.49 587.054L401.759 587.019V590.785H405.49V587.054Z" fill="#373B3D"/>
<path d="M397.298 587.054L393.567 587.019V590.785H397.298V587.054Z" fill="#373B3D"/>
<path d="M389.118 587.054L385.387 587.019V590.785H389.118V587.054Z" fill="#373B3D"/>
<path d="M380.926 587.054L377.195 587.019V590.785H380.926V587.054Z" fill="#373B3D"/>
<path d="M372.734 587.054L369.003 587.019V590.785H372.734V587.054Z" fill="#373B3D"/>
<path d="M364.543 587.054L360.812 587.019V590.785H364.543V587.054Z" fill="#373B3D"/>
<path d="M356.351 587.054L352.62 587.019V590.785H356.351V587.054Z" fill="#373B3D"/>
<path d="M348.159 587.054L344.428 587.019V590.785H348.159V587.054Z" fill="#373B3D"/>
<path d="M339.968 587.054L336.237 587.019V590.785H339.968V587.054Z" fill="#373B3D"/>
<path d="M331.775 587.054L328.044 587.019V590.785H331.775V587.054Z" fill="#373B3D"/>
<path d="M692.143 578.898L688.412 578.862V582.629H692.143V578.898Z" fill="#373B3D"/>
<path d="M683.964 578.898L680.233 578.862V582.629H683.964V578.898Z" fill="#373B3D"/>
<path d="M675.772 578.898L672.041 578.862V582.629H675.772V578.898Z" fill="#373B3D"/>
<path d="M667.58 578.898L663.849 578.862V582.629H667.58V578.898Z" fill="#373B3D"/>
<path d="M659.388 578.898L655.657 578.862V582.629H659.388V578.898Z" fill="#373B3D"/>
<path d="M651.196 578.897L647.465 578.862V582.628H651.196V578.897Z" fill="#373B3D"/>
<path d="M643.004 578.898L639.273 578.862V582.629H643.004V578.898Z" fill="#373B3D"/>
<path d="M634.813 578.898L631.082 578.862V582.629H634.813V578.898Z" fill="#373B3D"/>
<path d="M626.633 578.898L622.902 578.862V582.629H626.633V578.898Z" fill="#373B3D"/>
<path d="M618.441 578.898L614.71 578.862V582.629H618.441V578.898Z" fill="#373B3D"/>
<path d="M610.25 578.898L606.519 578.862V582.629H610.25V578.898Z" fill="#373B3D"/>
<path d="M430.065 578.898L426.334 578.862V582.629H430.065V578.898Z" fill="#373B3D"/>
<path d="M421.874 578.898L418.143 578.862V582.629H421.874V578.898Z" fill="#373B3D"/>
<path d="M413.682 578.898L409.951 578.862V582.629H413.682V578.898Z" fill="#373B3D"/>
<path d="M405.49 578.898L401.759 578.862V582.629H405.49V578.898Z" fill="#373B3D"/>
<path d="M397.298 578.898L393.567 578.862V582.629H397.298V578.898Z" fill="#373B3D"/>
<path d="M389.118 578.898L385.387 578.862V582.629H389.118V578.898Z" fill="#373B3D"/>
<path d="M380.926 578.898L377.195 578.862V582.629H380.926V578.898Z" fill="#373B3D"/>
<path d="M372.734 578.898L369.003 578.862V582.629H372.734V578.898Z" fill="#373B3D"/>
<path d="M364.542 578.898L360.811 578.862V582.629H364.542V578.898Z" fill="#373B3D"/>
<path d="M356.351 578.898L352.62 578.862V582.629H356.351V578.898Z" fill="#373B3D"/>
<path d="M348.159 578.898L344.428 578.862V582.629H348.159V578.898Z" fill="#373B3D"/>
<path d="M339.968 578.898L336.237 578.862V582.629H339.968V578.898Z" fill="#373B3D"/>
<path d="M331.776 578.898L328.045 578.862V582.629H331.776V578.898Z" fill="#373B3D"/>
<path d="M1093.47 570.753L1089.74 570.717V574.484H1093.47V570.753Z" fill="#373B3D"/>
<path d="M1052.51 570.753L1048.78 570.717V574.484H1052.51V570.753Z" fill="#373B3D"/>
<path d="M1044.32 570.753L1040.59 570.717V574.484H1044.32V570.753Z" fill="#373B3D"/>
<path d="M1027.95 570.753L1024.22 570.717V574.484H1027.95V570.753Z" fill="#373B3D"/>

<path d="M970.617 570.753L966.886 570.717V574.484H970.617V570.753Z" fill="#373B3D"/>
<path d="M954.233 570.754L950.502 570.718V574.485H954.233V570.754Z" fill="#373B3D"/>
<path d="M683.964 570.753L680.233 570.717V574.484H683.964V570.753Z" fill="#373B3D"/>
<path d="M675.772 570.753L672.041 570.717V574.484H675.772V570.753Z" fill="#373B3D"/>
<path d="M667.58 570.753L663.849 570.717V574.484H667.58V570.753Z" fill="#373B3D"/>
<path d="M659.388 570.753L655.657 570.717V574.484H659.388V570.753Z" fill="#373B3D"/>
<path d="M651.196 570.753L647.465 570.718V574.484H651.196V570.753Z" fill="#373B3D"/>
<path d="M643.004 570.753L639.273 570.717V574.484H643.004V570.753Z" fill="#373B3D"/>
<path d="M634.813 570.753L631.082 570.717V574.484H634.813V570.753Z" fill="#373B3D"/>
<path d="M626.633 570.753L622.902 570.717V574.484H626.633V570.753Z" fill="#373B3D"/>
<path d="M618.441 570.753L614.71 570.717V574.484H618.441V570.753Z" fill="#373B3D"/>
<path d="M610.25 570.753L606.519 570.717V574.484H610.25V570.753Z" fill="#373B3D"/>
<path d="M438.257 570.753L434.526 570.717V574.484H438.257V570.753Z" fill="#373B3D"/>
<path d="M430.065 570.753L426.334 570.717V574.484H430.065V570.753Z" fill="#373B3D"/>
<path d="M421.874 570.753L418.143 570.717V574.484H421.874V570.753Z" fill="#373B3D"/>
<path d="M413.682 570.753L409.951 570.717V574.484H413.682V570.753Z" fill="#373B3D"/>
<path d="M405.49 570.753L401.759 570.717V574.484H405.49V570.753Z" fill="#373B3D"/>
<path d="M397.298 570.753L393.567 570.717V574.484H397.298V570.753Z" fill="#373B3D"/>
<path d="M389.118 570.753L385.387 570.717V574.484H389.118V570.753Z" fill="#373B3D"/>
<path d="M380.926 570.753L377.195 570.717V574.484H380.926V570.753Z" fill="#373B3D"/>
<path d="M372.734 570.753L369.003 570.717V574.484H372.734V570.753Z" fill="#373B3D"/>
<path d="M364.542 570.753L360.811 570.717V574.484H364.542V570.753Z" fill="#373B3D"/>
<path d="M356.351 570.753L352.62 570.717V574.484H356.351V570.753Z" fill="#373B3D"/>
<path d="M348.159 570.753L344.428 570.717V574.484H348.159V570.753Z" fill="#373B3D"/>
<path d="M339.968 570.753L336.237 570.717V574.484H339.968V570.753Z" fill="#373B3D"/>
<path d="M331.776 570.753L328.045 570.717V574.484H331.776V570.753Z" fill="#373B3D"/>
<path d="M323.596 570.753L319.865 570.717V574.484H323.596V570.753Z" fill="#373B3D"/>
<path d="M1044.32 562.597L1040.59 562.562V566.328H1044.32V562.597Z" fill="#373B3D"/>
<path d="M1036.14 562.597L1032.41 562.562V566.328H1036.14V562.597Z" fill="#373B3D"/>
<path d="M1027.95 562.597L1024.22 562.562V566.328H1027.95V562.597Z" fill="#373B3D"/>
<path d="M1019.76 562.597L1016.03 562.562V566.328H1019.76V562.597Z" fill="#373B3D"/>
<path d="M929.659 562.597L925.928 562.562V566.328H929.659V562.597Z" fill="#373B3D"/>
<path d="M921.478 562.597L917.747 562.562V566.328H921.478V562.597Z" fill="#373B3D"/>
<path d="M913.286 562.597L909.555 562.562V566.328H913.286V562.597Z" fill="#373B3D"/>
<path d="M683.964 562.597L680.233 562.562V566.328H683.964V562.597Z" fill="#373B3D"/>
<path d="M675.772 562.597L672.041 562.562V566.328H675.772V562.597Z" fill="#373B3D"/>
<path d="M667.58 562.597L663.849 562.562V566.328H667.58V562.597Z" fill="#373B3D"/>
<path d="M659.388 562.597L655.657 562.562V566.328H659.388V562.597Z" fill="#373B3D"/>
<path d="M651.197 562.597L647.466 562.562V566.328H651.197V562.597Z" fill="#373B3D"/>
<path d="M643.004 562.597L639.273 562.562V566.328H643.004V562.597Z" fill="#373B3D"/>
<path d="M634.813 562.597L631.082 562.562V566.328H634.813V562.597Z" fill="#373B3D"/>
<path d="M626.633 562.597L622.902 562.562V566.328H626.633V562.597Z" fill="#373B3D"/>
<path d="M618.441 562.597L614.71 562.562V566.328H618.441V562.597Z" fill="#373B3D"/>
<path d="M610.249 562.597L606.518 562.562V566.328H610.249V562.597Z" fill="#373B3D"/>
<path d="M602.057 562.597L598.326 562.562V566.328H602.057V562.597Z" fill="#373B3D"/>
<path d="M438.257 562.597L434.526 562.562V566.328H438.257V562.597Z" fill="#373B3D"/>
<path d="M430.065 562.597L426.334 562.562V566.328H430.065V562.597Z" fill="#373B3D"/>
<path d="M421.873 562.597L418.142 562.562V566.328H421.873V562.597Z" fill="#373B3D"/>
<path d="M413.682 562.597L409.951 562.562V566.328H413.682V562.597Z" fill="#373B3D"/>
<path d="M405.49 562.597L401.759 562.562V566.328H405.49V562.597Z" fill="#373B3D"/>
<path d="M397.298 562.597L393.567 562.562V566.328H397.298V562.597Z" fill="#373B3D"/>
<path d="M389.118 562.597L385.387 562.562V566.328H389.118V562.597Z" fill="#373B3D"/>
<path d="M380.926 562.597L377.195 562.562V566.328H380.926V562.597Z" fill="#373B3D"/>
<path d="M372.735 562.597L369.004 562.562V566.328H372.735V562.597Z" fill="#373B3D"/>
<path d="M364.543 562.597L360.812 562.562V566.328H364.543V562.597Z" fill="#373B3D"/>
<path d="M356.351 562.597L352.62 562.562V566.328H356.351V562.597Z" fill="#373B3D"/>
<path d="M348.159 562.597L344.428 562.562V566.328H348.159V562.597Z" fill="#373B3D"/>
<path d="M339.968 562.597L336.237 562.562V566.328H339.968V562.597Z" fill="#373B3D"/>
<path d="M331.775 562.597L328.044 562.562V566.328H331.775V562.597Z" fill="#373B3D"/>
<path d="M323.596 562.597L319.865 562.562V566.328H323.596V562.597Z" fill="#373B3D"/>
<path d="M1060.7 554.44L1056.97 554.405V558.171H1060.7V554.44Z" fill="#373B3D"/>
<path d="M1036.14 554.44L1032.41 554.405V558.171H1036.14V554.44Z" fill="#373B3D"/>
<path d="M1027.95 554.44L1024.22 554.405V558.171H1027.95V554.44Z" fill="#373B3D"/>
<path d="M1019.76 554.44L1016.03 554.405V558.171H1019.76V554.44Z" fill="#373B3D"/>
<path d="M1011.56 554.44L1007.83 554.405V558.171H1011.56V554.44Z" fill="#373B3D"/>
<path d="M1011.56 546.283L1007.83 546.248V550.014H1011.56V546.283Z" fill="#373B3D"/>
<path d="M1003.37 554.44L999.642 554.405V558.171H1003.37V554.44Z" fill="#373B3D"/>
<path d="M978.809 554.44L975.078 554.405V558.171H978.809V554.44Z" fill="#373B3D"/>
<path d="M962.425 554.44L958.694 554.405V558.171H962.425V554.44Z" fill="#373B3D"/>


<path d="M683.964 554.44L680.233 554.405V558.171H683.964V554.44Z" fill="#373B3D"/>
<path d="M675.772 554.44L672.041 554.405V558.171H675.772V554.44Z" fill="#373B3D"/>
<path d="M667.58 554.44L663.849 554.405V558.171H667.58V554.44Z" fill="#373B3D"/>
<path d="M659.388 554.44L655.657 554.405V558.171H659.388V554.44Z" fill="#373B3D"/>
<path d="M651.196 554.44L647.465 554.405V558.171H651.196V554.44Z" fill="#373B3D"/>
<path d="M643.005 554.44L639.274 554.405V558.171H643.005V554.44Z" fill="#373B3D"/>
<path d="M634.813 554.44L631.082 554.405V558.171H634.813V554.44Z" fill="#373B3D"/>
<path d="M626.633 554.44L622.902 554.405V558.171H626.633V554.44Z" fill="#373B3D"/>
<path d="M618.441 554.44L614.71 554.405V558.171H618.441V554.44Z" fill="#373B3D"/>
<path d="M610.249 554.44L606.518 554.405V558.171H610.249V554.44Z" fill="#373B3D"/>
<path d="M602.057 554.44L598.326 554.405V558.171H602.057V554.44Z" fill="#373B3D"/>
<path d="M593.865 554.44L590.134 554.405V558.171H593.865V554.44Z" fill="#373B3D"/>
<path d="M430.065 554.44L426.334 554.405V558.171H430.065V554.44Z" fill="#373B3D"/>
<path d="M421.873 554.44L418.142 554.405V558.171H421.873V554.44Z" fill="#373B3D"/>
<path d="M413.682 554.44L409.951 554.405V558.171H413.682V554.44Z" fill="#373B3D"/>
<path d="M405.49 554.44L401.759 554.405V558.171H405.49V554.44Z" fill="#373B3D"/>
<path d="M397.298 554.44L393.567 554.405V558.171H397.298V554.44Z" fill="#373B3D"/>

<path d="M389.118 554.44L385.387 554.405V558.171H389.118V554.44Z" fill="#373B3D"/>
<path d="M380.926 554.44L377.195 554.405V558.171H380.926V554.44Z" fill="#373B3D"/>
<path d="M372.734 554.44L369.003 554.405V558.171H372.734V554.44Z" fill="#373B3D"/>
<path d="M364.543 554.44L360.812 554.405V558.171H364.543V554.44Z" fill="#373B3D"/>
<path d="M356.351 554.44L352.62 554.405V558.171H356.351V554.44Z" fill="#373B3D"/>
<path d="M348.159 554.44L344.428 554.405V558.171H348.159V554.44Z" fill="#373B3D"/>
<path d="M339.968 554.44L336.237 554.405V558.171H339.968V554.44Z" fill="#373B3D"/>
<path d="M331.775 554.44L328.044 554.405V558.171H331.775V554.44Z" fill="#373B3D"/>
<path d="M995.181 546.283L991.45 546.248V550.014H995.181V546.283Z" fill="#373B3D"/>
<path d="M962.425 546.283L958.694 546.248V550.014H962.425V546.283Z" fill="#373B3D"/>
<path d="M946.042 546.283L942.311 546.248V550.014H946.042V546.283Z" fill="#373B3D"/>
<path d="M937.85 546.283L934.119 546.248V550.014H937.85V546.283Z" fill="#373B3D"/>
<path d="M929.659 546.283L925.928 546.248V550.014H929.659V546.283Z" fill="#373B3D"/>
<path d="M921.478 546.283L917.747 546.248V550.014H921.478V546.283Z" fill="#373B3D"/>

<path d="M692.143 546.283L688.412 546.248V550.014H692.143V546.283Z" fill="#373B3D"/>
<path d="M683.964 546.283L680.233 546.248V550.014H683.964V546.283Z" fill="#373B3D"/>
<path d="M675.772 546.283L672.041 546.248V550.014H675.772V546.283Z" fill="#373B3D"/>
<path d="M667.58 546.283L663.849 546.248V550.014H667.58V546.283Z" fill="#373B3D"/>

<path d="M659.388 546.283L655.657 546.248V550.014H659.388V546.283Z" fill="#373B3D"/>
<path d="M651.196 546.283L647.465 546.248V550.014H651.196V546.283Z" fill="#373B3D"/>
<path d="M643.005 546.283L639.274 546.248V550.014H643.005V546.283Z" fill="#373B3D"/>
<path d="M634.813 546.283L631.082 546.248V550.014H634.813V546.283Z" fill="#373B3D"/>
<path d="M626.633 546.283L622.902 546.248V550.014H626.633V546.283Z" fill="#373B3D"/>
<path d="M618.441 546.283L614.71 546.248V550.014H618.441V546.283Z" fill="#373B3D"/>
<path d="M610.249 546.283L606.518 546.248V550.014H610.249V546.283Z" fill="#373B3D"/>
<path d="M602.057 546.283L598.326 546.248V550.014H602.057V546.283Z" fill="#373B3D"/>

<path d="M593.865 546.283L590.134 546.248V550.014H593.865V546.283Z" fill="#373B3D"/>
<path d="M405.49 546.283L401.759 546.248V550.014H405.49V546.283Z" fill="#373B3D"/>
<path d="M397.298 546.283L393.567 546.248V550.014H397.298V546.283Z" fill="#373B3D"/>
<path d="M389.118 546.283L385.387 546.248V550.014H389.118V546.283Z" fill="#373B3D"/>
<path d="M380.926 546.283L377.195 546.248V550.014H380.926V546.283Z" fill="#373B3D"/>
<path d="M372.734 546.283L369.003 546.248V550.014H372.734V546.283Z" fill="#373B3D"/>
<path d="M364.543 546.283L360.812 546.248V550.014H364.543V546.283Z" fill="#373B3D"/>
<path d="M356.351 546.283L352.62 546.248V550.014H356.351V546.283Z" fill="#373B3D"/>
<path d="M348.159 546.283L344.428 546.248V550.014H348.159V546.283Z" fill="#373B3D"/>
<path d="M339.968 546.283L336.237 546.248V550.014H339.968V546.283Z" fill="#373B3D"/>
<path d="M331.775 546.283L328.044 546.248V550.014H331.775V546.283Z" fill="#373B3D"/>
<path d="M323.596 546.283L319.865 546.248V550.014H323.596V546.283Z" fill="#373B3D"/>
<path d="M307.212 546.283L303.481 546.248V550.014H307.212V546.283Z" fill="#373B3D"/>
<path d="M299.02 546.283L295.289 546.248V550.014H299.02V546.283Z" fill="#373B3D"/>
<path d="M290.829 546.283L287.098 546.248V550.014H290.829V546.283Z" fill="#373B3D"/>
<path d="M258.073 546.283L254.342 546.248V550.014H258.073V546.283Z" fill="#373B3D"/>
<path d="M978.809 538.139L975.078 538.104V541.87H978.809V538.139Z" fill="#373B3D"/>
<path d="M962.425 538.139L958.694 538.104V541.87H962.425V538.139Z" fill="#373B3D"/>
<path d="M946.042 538.139L942.311 538.104V541.87H946.042V538.139Z" fill="#373B3D"/>
<path d="M937.85 538.139L934.119 538.104V541.87H937.85V538.139Z" fill="#373B3D"/>
<path d="M929.659 538.139L925.928 538.104V541.87H929.659V538.139Z" fill="#373B3D"/>
<path d="M929.659 529.982L925.928 529.947V533.713H929.659V529.982Z" fill="#373B3D"/>
<path d="M921.478 538.139L917.747 538.104V541.87H921.478V538.139Z" fill="#373B3D"/>
<path d="M896.903 538.139L893.172 538.104V541.87H896.903V538.139Z" fill="#373B3D"/>
<path d="M888.711 538.139L884.98 538.104V541.87H888.711V538.139Z" fill="#373B3D"/>
<path d="M700.335 538.139L696.604 538.104V541.87H700.335V538.139Z" fill="#373B3D"/>
<path d="M692.143 538.139L688.412 538.104V541.87H692.143V538.139Z" fill="#373B3D"/>
<path d="M683.964 538.139L680.233 538.104V541.87H683.964V538.139Z" fill="#373B3D"/>
<path d="M675.772 538.139L672.041 538.104V541.87H675.772V538.139Z" fill="#373B3D"/>
<path d="M667.58 538.139L663.849 538.104V541.87H667.58V538.139Z" fill="#373B3D"/>
<path d="M659.388 538.139L655.657 538.104V541.87H659.388V538.139Z" fill="#373B3D"/>
<path d="M651.196 538.139L647.465 538.104V541.87H651.196V538.139Z" fill="#373B3D"/>
<path d="M643.005 538.139L639.274 538.104V541.87H643.005V538.139Z" fill="#373B3D"/>
<path d="M634.813 538.139L631.082 538.104V541.87H634.813V538.139Z" fill="#373B3D"/>
<path d="M626.633 538.139L622.902 538.104V541.87H626.633V538.139Z" fill="#373B3D"/>
<path d="M618.441 538.139L614.71 538.104V541.87H618.441V538.139Z" fill="#373B3D"/>
<path d="M610.249 538.139L606.518 538.104V541.87H610.249V538.139Z" fill="#373B3D"/>
<path d="M602.057 538.139L598.326 538.104V541.87H602.057V538.139Z" fill="#373B3D"/>
<path d="M593.865 538.139L590.134 538.104V541.87H593.865V538.139Z" fill="#373B3D"/>
<path d="M389.118 538.139L385.387 538.104V541.87H389.118V538.139Z" fill="#373B3D"/>
<path d="M380.926 538.139L377.195 538.104V541.87H380.926V538.139Z" fill="#373B3D"/>
<path d="M372.734 538.139L369.003 538.104V541.87H372.734V538.139Z" fill="#373B3D"/>
<path d="M364.543 538.139L360.812 538.104V541.87H364.543V538.139Z" fill="#373B3D"/>
<path d="M356.351 538.139L352.62 538.104V541.87H356.351V538.139Z" fill="#373B3D"/>
<path d="M348.159 538.139L344.428 538.104V541.87H348.159V538.139Z" fill="#373B3D"/>
<path d="M331.775 538.139L328.044 538.104V541.87H331.775V538.139Z" fill="#373B3D"/>
<path d="M323.596 538.139L319.865 538.104V541.87H323.596V538.139Z" fill="#373B3D"/>
<path d="M315.404 538.139L311.673 538.104V541.87H315.404V538.139Z" fill="#373B3D"/>
<path d="M307.212 538.139L303.481 538.104V541.87H307.212V538.139Z" fill="#373B3D"/>
<path d="M299.02 538.139L295.289 538.104V541.87H299.02V538.139Z" fill="#373B3D"/>
<path d="M946.042 529.982L942.311 529.947V533.713H946.042V529.982Z" fill="#373B3D"/>
<path d="M937.85 529.982L934.119 529.947V533.713H937.85V529.982Z" fill="#373B3D"/>

<path d="M896.903 529.982L893.172 529.947V533.713H896.903V529.982Z" fill="#373B3D"/>
<path d="M888.711 529.982L884.98 529.947V533.713H888.711V529.982Z" fill="#373B3D"/>
<path d="M880.519 529.982L876.788 529.947V533.713H880.519V529.982Z" fill="#373B3D"/>
<path d="M708.527 529.982L704.796 529.947V533.713H708.527V529.982Z" fill="#373B3D"/>
<path d="M700.335 529.982L696.604 529.947V533.713H700.335V529.982Z" fill="#373B3D"/>
<path d="M692.143 529.982L688.412 529.947V533.713H692.143V529.982Z" fill="#373B3D"/>
<path d="M683.964 529.982L680.233 529.947V533.713H683.964V529.982Z" fill="#373B3D"/>
<path d="M675.772 529.982L672.041 529.947V533.713H675.772V529.982Z" fill="#373B3D"/>
<path d="M667.58 529.982L663.849 529.947V533.713H667.58V529.982Z" fill="#373B3D"/>
<path d="M659.388 529.982L655.657 529.947V533.713H659.388V529.982Z" fill="#373B3D"/>
<path d="M651.196 529.982L647.465 529.947V533.713H651.196V529.982Z" fill="#373B3D"/>
<path d="M643.005 529.982L639.274 529.947V533.713H643.005V529.982Z" fill="#373B3D"/>
<path d="M634.813 529.982L631.082 529.947V533.713H634.813V529.982Z" fill="#373B3D"/>
<path d="M626.633 529.982L622.902 529.947V533.713H626.633V529.982Z" fill="#373B3D"/>
<path d="M618.441 529.982L614.71 529.947V533.713H618.441V529.982Z" fill="#373B3D"/>
<path d="M610.249 529.982L606.518 529.947V533.713H610.249V529.982Z" fill="#373B3D"/>
<path d="M602.057 529.982L598.326 529.947V533.713H602.057V529.982Z" fill="#373B3D"/>
<path d="M593.865 529.982L590.134 529.947V533.713H593.865V529.982Z" fill="#373B3D"/>
<path d="M389.118 529.982L385.387 529.947V533.713H389.118V529.982Z" fill="#373B3D"/>
<path d="M380.926 529.982L377.195 529.947V533.713H380.926V529.982Z" fill="#373B3D"/>
<path d="M372.734 529.982L369.003 529.947V533.713H372.734V529.982Z" fill="#373B3D"/>
<path d="M364.543 529.982L360.812 529.947V533.713H364.543V529.982Z" fill="#373B3D"/>
<path d="M356.351 529.982L352.62 529.947V533.713H356.351V529.982Z" fill="#373B3D"/>
<path d="M331.775 529.982L328.044 529.947V533.713H331.775V529.982Z" fill="#373B3D"/>
<path d="M323.596 529.982L319.865 529.947V533.713H323.596V529.982Z" fill="#373B3D"/>
<path d="M315.404 529.982L311.673 529.947V533.713H315.404V529.982Z" fill="#373B3D"/>
<path d="M307.212 529.982L303.481 529.947V533.713H307.212V529.982Z" fill="#373B3D"/>
<path d="M946.042 521.826L942.311 521.791V525.557H946.042V521.826Z" fill="#373B3D"/>
<path d="M896.903 521.826L893.172 521.791V525.557H896.903V521.826Z" fill="#373B3D"/>
<path d="M716.719 521.826L712.988 521.791V525.557H716.719V521.826Z" fill="#373B3D"/>
<path d="M708.527 521.826L704.796 521.791V525.557H708.527V521.826Z" fill="#373B3D"/>
<path d="M700.335 521.826L696.604 521.791V525.557H700.335V521.826Z" fill="#373B3D"/>
<path d="M692.143 521.826L688.412 521.791V525.557H692.143V521.826Z" fill="#373B3D"/>
<path d="M683.964 521.826L680.233 521.791V525.557H683.964V521.826Z" fill="#373B3D"/>
<path d="M675.772 521.826L672.041 521.791V525.557H675.772V521.826Z" fill="#373B3D"/>
<path d="M667.58 521.826L663.849 521.791V525.557H667.58V521.826Z" fill="#373B3D"/>
<path d="M659.388 521.826L655.657 521.791V525.557H659.388V521.826Z" fill="#373B3D"/>
<path d="M651.196 521.826L647.465 521.791V525.557H651.196V521.826Z" fill="#373B3D"/>
<path d="M643.005 521.826L639.274 521.791V525.557H643.005V521.826Z" fill="#373B3D"/>
<path d="M634.813 521.826L631.082 521.791V525.557H634.813V521.826Z" fill="#373B3D"/>
<path d="M626.633 521.826L622.902 521.791V525.557H626.633V521.826Z" fill="#373B3D"/>
<path d="M618.441 521.826L614.71 521.791V525.557H618.441V521.826Z" fill="#373B3D"/>
<path d="M610.249 521.826L606.518 521.791V525.557H610.249V521.826Z" fill="#373B3D"/>
<path d="M602.057 521.826L598.326 521.791V525.557H602.057V521.826Z" fill="#373B3D"/>
<path d="M593.865 521.826L590.134 521.791V525.557H593.865V521.826Z" fill="#373B3D"/>
<path d="M585.674 521.826L581.943 521.791V525.557H585.674V521.826Z" fill="#373B3D"/>
<path d="M577.482 521.826L573.751 521.791V525.557H577.482V521.826Z" fill="#373B3D"/>
<path d="M544.727 521.826L540.996 521.791V525.557H544.727V521.826Z" fill="#373B3D"/>
<path d="M536.535 521.826L532.804 521.791V525.557H536.535V521.826Z" fill="#373B3D"/>
<path d="M528.343 521.826L524.612 521.791V525.557H528.343V521.826Z" fill="#373B3D"/>
<path d="M380.926 521.826L377.195 521.791V525.557H380.926V521.826Z" fill="#373B3D"/>
<path d="M372.734 521.826L369.003 521.791V525.557H372.734V521.826Z" fill="#373B3D"/>
<path d="M364.543 521.826L360.812 521.791V525.557H364.543V521.826Z" fill="#373B3D"/>
<path d="M315.404 521.826L311.673 521.791V525.557H315.404V521.826Z" fill="#373B3D"/>
<path d="M307.212 521.826L303.481 521.791V525.557H307.212V521.826Z" fill="#373B3D"/>
<path d="M970.617 513.681L966.886 513.646V517.412H970.617V513.681Z" fill="#373B3D"/>
<path d="M888.711 513.681L884.98 513.646V517.412H888.711V513.681Z" fill="#373B3D"/>
<path d="M823.189 521.826L819.458 521.791V525.557H823.189V521.826Z" fill="#373B3D"/>
<path d="M724.911 513.681L721.179 513.646V517.412H724.911V513.681Z" fill="#373B3D"/>
<path d="M716.719 513.681L712.988 513.646V517.412H716.719V513.681Z" fill="#373B3D"/>
<path d="M708.527 513.681L704.796 513.646V517.412H708.527V513.681Z" fill="#373B3D"/>
<path d="M700.335 513.681L696.604 513.646V517.412H700.335V513.681Z" fill="#373B3D"/>
<path d="M692.143 513.681L688.412 513.646V517.412H692.143V513.681Z" fill="#373B3D"/>
<path d="M683.964 513.681L680.233 513.646V517.412H683.964V513.681Z" fill="#373B3D"/>
<path d="M675.772 513.681L672.041 513.646V517.412H675.772V513.681Z" fill="#373B3D"/>
<path d="M667.58 513.681L663.849 513.646V517.412H667.58V513.681Z" fill="#373B3D"/>
<path d="M659.388 513.681L655.657 513.646V517.412H659.388V513.681Z" fill="#373B3D"/>
<path d="M651.196 513.681L647.465 513.646V517.412H651.196V513.681Z" fill="#373B3D"/>
<path d="M643.005 513.681L639.274 513.646V517.412H643.005V513.681Z" fill="#373B3D"/>
<path d="M634.813 513.681L631.082 513.646V517.412H634.813V513.681Z" fill="#373B3D"/>
<path d="M626.633 513.681L622.902 513.646V517.412H626.633V513.681Z" fill="#373B3D"/>
<path d="M618.441 513.681L614.71 513.646V517.412H618.441V513.681Z" fill="#373B3D"/>
<path d="M610.249 513.681L606.518 513.646V517.412H610.249V513.681Z" fill="#373B3D"/>
<path d="M602.057 513.681L598.326 513.646V517.412H602.057V513.681Z" fill="#373B3D"/>
<path d="M593.865 513.681L590.134 513.646V517.412H593.865V513.681Z" fill="#373B3D"/>
<path d="M585.674 513.681L581.943 513.646V517.412H585.674V513.681Z" fill="#373B3D"/>
<path d="M577.482 513.681L573.751 513.646V517.412H577.482V513.681Z" fill="#373B3D"/>
<path d="M569.29 513.681L565.559 513.646V517.412H569.29V513.681Z" fill="#373B3D"/>
<path d="M544.727 513.681L540.996 513.646V517.412H544.727V513.681Z" fill="#373B3D"/>
<path d="M536.535 513.681L532.804 513.646V517.412H536.535V513.681Z" fill="#373B3D"/>
<path d="M528.343 513.681L524.612 513.646V517.412H528.343V513.681Z" fill="#373B3D"/>
<path d="M520.151 513.681L516.42 513.646V517.412H520.151V513.681Z" fill="#373B3D"/>
<path d="M364.543 513.681L360.812 513.646V517.412H364.543V513.681Z" fill="#373B3D"/>
<path d="M315.404 513.681L311.673 513.646V517.412H315.404V513.681Z" fill="#373B3D"/>
<path d="M307.212 513.681L303.481 513.646V517.412H307.212V513.681Z" fill="#373B3D"/>
<path d="M299.02 513.681L295.289 513.646V517.412H299.02V513.681Z" fill="#373B3D"/>
<path d="M290.829 513.681L287.098 513.646V517.412H290.829V513.681Z" fill="#373B3D"/>
<path d="M282.637 513.681L278.906 513.646V517.412H282.637V513.681Z" fill="#373B3D"/>
<path d="M970.617 505.525L966.886 505.489V509.256H970.617V505.525Z" fill="#373B3D"/>
<path d="M962.425 505.525L958.694 505.489V509.256H962.425V505.525Z" fill="#373B3D"/>
<path d="M913.286 505.525L909.555 505.489V509.256H913.286V505.525Z" fill="#373B3D"/>
<path d="M905.095 505.525L901.364 505.489V509.256H905.095V505.525Z" fill="#373B3D"/>
<path d="M888.711 505.525L884.98 505.489V509.256H888.711V505.525Z" fill="#373B3D"/>
<path d="M724.911 505.525L721.179 505.489V509.256H724.911V505.525Z" fill="#373B3D"/>
<path d="M716.719 505.525L712.988 505.489V509.256H716.719V505.525Z" fill="#373B3D"/>
<path d="M708.527 505.525L704.796 505.489V509.256H708.527V505.525Z" fill="#373B3D"/>
<path d="M700.335 505.525L696.604 505.489V509.256H700.335V505.525Z" fill="#373B3D"/>
<path d="M692.143 505.525L688.412 505.489V509.256H692.143V505.525Z" fill="#373B3D"/>
<path d="M683.964 505.525L680.233 505.489V509.256H683.964V505.525Z" fill="#373B3D"/>
<path d="M675.772 505.525L672.041 505.489V509.256H675.772V505.525Z" fill="#373B3D"/>
<path d="M667.58 505.525L663.849 505.489V509.256H667.58V505.525Z" fill="#373B3D"/>
<path d="M659.388 505.525L655.657 505.489V509.256H659.388V505.525Z" fill="#373B3D"/>
<path d="M651.196 505.525L647.465 505.489V509.256H651.196V505.525Z" fill="#373B3D"/>
<path d="M643.005 505.525L639.274 505.489V509.256H643.005V505.525Z" fill="#373B3D"/>
<path d="M634.813 505.525L631.082 505.489V509.256H634.813V505.525Z" fill="#373B3D"/>
<path d="M626.633 505.525L622.902 505.489V509.256H626.633V505.525Z" fill="#373B3D"/>
<path d="M618.441 505.525L614.71 505.489V509.256H618.441V505.525Z" fill="#373B3D"/>
<path d="M610.249 505.525L606.518 505.489V509.256H610.249V505.525Z" fill="#373B3D"/>
<path d="M602.057 505.525L598.326 505.489V509.256H602.057V505.525Z" fill="#373B3D"/>
<path d="M593.865 505.525L590.134 505.489V509.256H593.865V505.525Z" fill="#373B3D"/>
<path d="M585.674 505.525L581.943 505.489V509.256H585.674V505.525Z" fill="#373B3D"/>
<path d="M577.482 505.525L573.751 505.489V509.256H577.482V505.525Z" fill="#373B3D"/>
<path d="M569.29 505.525L565.559 505.489V509.256H569.29V505.525Z" fill="#373B3D"/>
<path d="M544.727 505.525L540.996 505.489V509.256H544.727V505.525Z" fill="#373B3D"/>
<path d="M552.918 505.525L549.187 505.489V509.256H552.918V505.525Z" fill="#373B3D"/>
<path d="M536.535 505.525L532.804 505.489V509.256H536.535V505.525Z" fill="#373B3D"/>
<path d="M528.343 505.525L524.612 505.489V509.256H528.343V505.525Z" fill="#373B3D"/>
<path d="M520.151 505.525L516.42 505.489V509.256H520.151V505.525Z" fill="#373B3D"/>
<path d="M511.96 505.525L508.229 505.489V509.256H511.96V505.525Z" fill="#373B3D"/>
<path d="M315.404 505.525L311.673 505.489V509.256H315.404V505.525Z" fill="#373B3D"/>
<path d="M282.637 505.525L278.906 505.489V509.256H282.637V505.525Z" fill="#373B3D"/>
<path d="M274.445 505.525L270.714 505.489V509.256H274.445V505.525Z" fill="#373B3D"/>
<path d="M962.425 497.368L958.694 497.333V501.099H962.425V497.368Z" fill="#373B3D"/>
<path d="M913.286 497.368L909.555 497.333V501.099H913.286V497.368Z" fill="#373B3D"/>
<path d="M905.095 497.368L901.364 497.333V501.099H905.095V497.368Z" fill="#373B3D"/>
<path d="M896.903 497.368L893.172 497.333V501.099H896.903V497.368Z" fill="#373B3D"/>
<path d="M888.711 497.368L884.98 497.333V501.099H888.711V497.368Z" fill="#373B3D"/>
<path d="M692.143 497.368L688.412 497.333V501.099H692.143V497.368Z" fill="#373B3D"/>
<path d="M683.964 497.368L680.233 497.333V501.099H683.964V497.368Z" fill="#373B3D"/>
<path d="M675.772 497.368L672.041 497.333V501.099H675.772V497.368Z" fill="#373B3D"/>
<path d="M667.58 497.368L663.849 497.333V501.099H667.58V497.368Z" fill="#373B3D"/>
<path d="M659.388 497.368L655.657 497.333V501.099H659.388V497.368Z" fill="#373B3D"/>
<path d="M651.196 497.368L647.465 497.333V501.099H651.196V497.368Z" fill="#373B3D"/>
<path d="M643.005 497.368L639.274 497.333V501.099H643.005V497.368Z" fill="#373B3D"/>
<path d="M634.813 497.368L631.082 497.333V501.099H634.813V497.368Z" fill="#373B3D"/>
<path d="M626.633 497.368L622.902 497.333V501.099H626.633V497.368Z" fill="#373B3D"/>
<path d="M618.441 497.368L614.71 497.333V501.099H618.441V497.368Z" fill="#373B3D"/>
<path d="M610.249 497.368L606.518 497.333V501.099H610.249V497.368Z" fill="#373B3D"/>
<path d="M602.057 497.368L598.326 497.333V501.099H602.057V497.368Z" fill="#373B3D"/>
<path d="M593.865 497.368L590.134 497.333V501.099H593.865V497.368Z" fill="#373B3D"/>
<path d="M585.674 497.368L581.943 497.333V501.099H585.674V497.368Z" fill="#373B3D"/>
<path d="M577.482 497.368L573.751 497.333V501.099H577.482V497.368Z" fill="#373B3D"/>
<path d="M569.29 497.368L565.559 497.333V501.099H569.29V497.368Z" fill="#373B3D"/>
<path d="M561.111 497.368L557.38 497.333V501.099H561.111V497.368Z" fill="#373B3D"/>
<path d="M552.918 497.368L549.187 497.333V501.099H552.918V497.368Z" fill="#373B3D"/>
<path d="M544.727 497.368L540.996 497.333V501.099H544.727V497.368Z" fill="#373B3D"/>
<path d="M536.535 497.368L532.804 497.333V501.099H536.535V497.368Z" fill="#373B3D"/>
<path d="M528.343 497.368L524.612 497.333V501.099H528.343V497.368Z" fill="#373B3D"/>
<path d="M520.151 497.368L516.42 497.333V501.099H520.151V497.368Z" fill="#373B3D"/>
<path d="M511.96 497.368L508.229 497.333V501.099H511.96V497.368Z" fill="#373B3D"/>
<path d="M503.779 497.368L500.048 497.333V501.099H503.779V497.368Z" fill="#373B3D"/>

<path d="M282.637 497.368L278.906 497.333V501.099H282.637V497.368Z" fill="#373B3D"/>
<path d="M274.445 497.368L270.714 497.333V501.099H274.445V497.368Z" fill="#373B3D"/>
<path d="M266.265 497.368L262.534 497.333V501.099H266.265V497.368Z" fill="#373B3D"/>
<path d="M962.425 489.223L958.694 489.188V492.954H962.425V489.223Z" fill="#373B3D"/>
<path d="M913.286 489.223L909.555 489.188V492.954H913.286V489.223Z" fill="#373B3D"/>
<path d="M905.095 489.223L901.364 489.188V492.954H905.095V489.223Z" fill="#373B3D"/>
<path d="M896.903 489.223L893.172 489.188V492.954H896.903V489.223Z" fill="#373B3D"/>
<path d="M888.711 489.223L884.98 489.188V492.954H888.711V489.223Z" fill="#373B3D"/>
<path d="M880.519 489.223L876.788 489.188V492.954H880.519V489.223Z" fill="#373B3D"/>
<path d="M724.911 489.223L721.179 489.188V492.954H724.911V489.223Z" fill="#373B3D"/>
<path d="M716.719 489.223L712.988 489.188V492.954H716.719V489.223Z" fill="#373B3D"/>
<path d="M708.527 489.223L704.796 489.188V492.954H708.527V489.223Z" fill="#373B3D"/>
<path d="M700.335 489.223L696.604 489.188V492.954H700.335V489.223Z" fill="#373B3D"/>
<path d="M683.964 489.223L680.233 489.188V492.954H683.964V489.223Z" fill="#373B3D"/>
<path d="M675.772 489.223L672.041 489.188V492.954H675.772V489.223Z" fill="#373B3D"/>
<path d="M667.58 489.223L663.849 489.188V492.954H667.58V489.223Z" fill="#373B3D"/>
<path d="M659.388 489.223L655.657 489.188V492.954H659.388V489.223Z" fill="#373B3D"/>
<path d="M651.196 489.223L647.465 489.188V492.954H651.196V489.223Z" fill="#373B3D"/>
<path d="M643.005 489.223L639.274 489.188V492.954H643.005V489.223Z" fill="#373B3D"/>
<path d="M634.813 489.223L631.082 489.188V492.954H634.813V489.223Z" fill="#373B3D"/>
<path d="M626.633 489.223L622.902 489.188V492.954H626.633V489.223Z" fill="#373B3D"/>
<path d="M618.441 489.223L614.71 489.188V492.954H618.441V489.223Z" fill="#373B3D"/>
<path d="M610.249 489.223L606.518 489.188V492.954H610.249V489.223Z" fill="#373B3D"/>
<path d="M602.057 489.223L598.326 489.188V492.954H602.057V489.223Z" fill="#373B3D"/>
<path d="M593.865 489.223L590.134 489.188V492.954H593.865V489.223Z" fill="#373B3D"/>
<path d="M585.674 489.223L581.943 489.188V492.954H585.674V489.223Z" fill="#373B3D"/>
<path d="M577.482 489.223L573.751 489.188V492.954H577.482V489.223Z" fill="#373B3D"/>
<path d="M569.29 489.223L565.559 489.188V492.954H569.29V489.223Z" fill="#373B3D"/>
<path d="M561.111 489.223L557.38 489.188V492.954H561.111V489.223Z" fill="#373B3D"/>
<path d="M552.918 489.223L549.187 489.188V492.954H552.918V489.223Z" fill="#373B3D"/>
<path d="M544.727 489.223L540.996 489.188V492.954H544.727V489.223Z" fill="#373B3D"/>
<path d="M536.535 489.223L532.804 489.188V492.954H536.535V489.223Z" fill="#373B3D"/>
<path d="M528.343 489.223L524.612 489.188V492.954H528.343V489.223Z" fill="#373B3D"/>
<path d="M520.151 489.223L516.42 489.188V492.954H520.151V489.223Z" fill="#373B3D"/>
<path d="M511.96 489.223L508.229 489.188V492.954H511.96V489.223Z" fill="#373B3D"/>
<path d="M503.779 489.223L500.048 489.188V492.954H503.779V489.223Z" fill="#373B3D"/>
<path d="M274.445 489.223L270.714 489.188V492.954H274.445V489.223Z" fill="#373B3D"/>
<path d="M282.637 489.223L278.906 489.188V492.954H282.637V489.223Z" fill="#373B3D"/>
<path d="M266.265 489.223L262.534 489.188V492.954H266.265V489.223Z" fill="#373B3D"/>
<path d="M258.073 489.223L254.342 489.188V492.954H258.073V489.223Z" fill="#373B3D"/>
<path d="M962.425 481.067L958.694 481.032V484.798H962.425V481.067Z" fill="#373B3D"/>
<path d="M905.095 481.067L901.364 481.032V484.798H905.095V481.067Z" fill="#373B3D"/>
<path d="M896.903 481.067L893.172 481.032V484.798H896.903V481.067Z" fill="#373B3D"/>
<path d="M888.711 481.068L884.98 481.032V484.799H888.711V481.068Z" fill="#373B3D"/>
<path d="M880.519 481.067L876.788 481.032V484.798H880.519V481.067Z" fill="#373B3D"/>
<path d="M872.328 481.067L868.597 481.032V484.798H872.328V481.067Z" fill="#373B3D"/>
<path d="M741.294 481.067L737.563 481.032V484.798H741.294V481.067Z" fill="#373B3D"/>
<path d="M733.103 481.067L729.372 481.032V484.798H733.103V481.067Z" fill="#373B3D"/>
<path d="M724.911 481.067L721.179 481.032V484.798H724.911V481.067Z" fill="#373B3D"/>
<path d="M716.719 481.067L712.988 481.032V484.798H716.719V481.067Z" fill="#373B3D"/>
<path d="M708.527 481.067L704.796 481.032V484.798H708.527V481.067Z" fill="#373B3D"/>
<path d="M700.335 481.067L696.604 481.032V484.798H700.335V481.067Z" fill="#373B3D"/>
<path d="M683.964 481.067L680.233 481.032V484.798H683.964V481.067Z" fill="#373B3D"/>
<path d="M675.772 481.067L672.041 481.032V484.798H675.772V481.067Z" fill="#373B3D"/>
<path d="M667.58 481.067L663.849 481.032V484.798H667.58V481.067Z" fill="#373B3D"/>
<path d="M659.388 481.067L655.657 481.032V484.798H659.388V481.067Z" fill="#373B3D"/>
<path d="M651.196 481.067L647.465 481.032V484.798H651.196V481.067Z" fill="#373B3D"/>
<path d="M643.005 481.067L639.274 481.032V484.798H643.005V481.067Z" fill="#373B3D"/>
<path d="M634.813 481.067L631.082 481.032V484.798H634.813V481.067Z" fill="#373B3D"/>
<path d="M626.633 481.067L622.902 481.032V484.798H626.633V481.067Z" fill="#373B3D"/>
<path d="M618.441 481.067L614.71 481.032V484.798H618.441V481.067Z" fill="#373B3D"/>
<path d="M610.249 481.067L606.518 481.032V484.798H610.249V481.067Z" fill="#373B3D"/>
<path d="M602.057 481.067L598.326 481.032V484.798H602.057V481.067Z" fill="#373B3D"/>
<path d="M593.865 481.067L590.134 481.032V484.798H593.865V481.067Z" fill="#373B3D"/>
<path d="M585.674 481.067L581.943 481.032V484.798H585.674V481.067Z" fill="#373B3D"/>
<path d="M577.482 481.067L573.751 481.032V484.798H577.482V481.067Z" fill="#373B3D"/>
<path d="M569.29 481.067L565.559 481.032V484.798H569.29V481.067Z" fill="#373B3D"/>
<path d="M561.111 481.067L557.38 481.032V484.798H561.111V481.067Z" fill="#373B3D"/>
<path d="M552.918 481.068L549.187 481.032V484.799H552.918V481.068Z" fill="#373B3D"/>
<path d="M544.727 481.067L540.996 481.032V484.798H544.727V481.067Z" fill="#373B3D"/>
<path d="M536.535 481.067L532.804 481.032V484.798H536.535V481.067Z" fill="#373B3D"/>
<path d="M528.343 481.067L524.612 481.032V484.798H528.343V481.067Z" fill="#373B3D"/>
<path d="M520.151 481.067L516.42 481.032V484.798H520.151V481.067Z" fill="#373B3D"/>
<path d="M511.96 481.067L508.229 481.032V484.798H511.96V481.067Z" fill="#373B3D"/>
<path d="M503.779 481.067L500.048 481.032V484.798H503.779V481.067Z" fill="#373B3D"/>
<path d="M339.968 481.067L336.237 481.032V484.798H339.968V481.067Z" fill="#373B3D"/>
<path d="M323.596 481.067L319.865 481.032V484.798H323.596V481.067Z" fill="#373B3D"/>
<path d="M307.212 481.067L303.481 481.032V484.798H307.212V481.067Z" fill="#373B3D"/>
<path d="M921.478 472.911L917.747 472.875V476.642H921.478V472.911Z" fill="#373B3D"/>
<path d="M905.095 472.911L901.364 472.875V476.642H905.095V472.911Z" fill="#373B3D"/>
<path d="M896.903 472.911L893.172 472.875V476.642H896.903V472.911Z" fill="#373B3D"/>
<path d="M888.711 472.91L884.98 472.875V476.641H888.711V472.91Z" fill="#373B3D"/>
<path d="M880.519 472.911L876.788 472.875V476.642H880.519V472.911Z" fill="#373B3D"/>
<path d="M872.328 472.911L868.597 472.875V476.642H872.328V472.911Z" fill="#373B3D"/>
<path d="M864.147 472.911L860.416 472.875V476.642H864.147V472.911Z" fill="#373B3D"/>
<path d="M749.474 472.911L745.743 472.875V476.642H749.474V472.911Z" fill="#373B3D"/>
<path d="M741.294 472.911L737.563 472.875V476.642H741.294V472.911Z" fill="#373B3D"/>
<path d="M733.103 472.911L729.372 472.875V476.642H733.103V472.911Z" fill="#373B3D"/>
<path d="M724.911 472.911L721.179 472.875V476.642H724.911V472.911Z" fill="#373B3D"/>
<path d="M716.719 472.911L712.988 472.875V476.642H716.719V472.911Z" fill="#373B3D"/>
<path d="M708.527 472.911L704.796 472.875V476.642H708.527V472.911Z" fill="#373B3D"/>
<path d="M700.335 472.911L696.604 472.875V476.642H700.335V472.911Z" fill="#373B3D"/>
<path d="M692.143 472.911L688.412 472.875V476.642H692.143V472.911Z" fill="#373B3D"/>
<path d="M675.772 472.911L672.041 472.875V476.642H675.772V472.911Z" fill="#373B3D"/>
<path d="M667.58 472.911L663.849 472.875V476.642H667.58V472.911Z" fill="#373B3D"/>
<path d="M659.388 472.911L655.657 472.875V476.642H659.388V472.911Z" fill="#373B3D"/>
<path d="M651.196 472.911L647.465 472.875V476.642H651.196V472.911Z" fill="#373B3D"/>


<path d="M643.005 472.911L639.274 472.875V476.642H643.005V472.911Z" fill="#373B3D"/>
<path d="M634.813 472.911L631.082 472.875V476.642H634.813V472.911Z" fill="#373B3D"/>
<path d="M626.633 472.911L622.902 472.875V476.642H626.633V472.911Z" fill="#373B3D"/>
<path d="M618.441 472.911L614.71 472.875V476.642H618.441V472.911Z" fill="#373B3D"/>
<path d="M610.249 472.911L606.518 472.875V476.642H610.249V472.911Z" fill="#373B3D"/>
<path d="M602.057 472.911L598.326 472.875V476.642H602.057V472.911Z" fill="#373B3D"/>
<path d="M593.865 472.911L590.134 472.875V476.642H593.865V472.911Z" fill="#373B3D"/>
<path d="M585.674 472.911L581.943 472.875V476.642H585.674V472.911Z" fill="#373B3D"/>
<path d="M577.482 472.911L573.751 472.875V476.642H577.482V472.911Z" fill="#373B3D"/>
<path d="M569.29 472.911L565.559 472.875V476.642H569.29V472.911Z" fill="#373B3D"/>
<path d="M561.111 472.911L557.38 472.875V476.642H561.111V472.911Z" fill="#373B3D"/>
<path d="M552.918 472.91L549.187 472.875V476.641H552.918V472.91Z" fill="#373B3D"/>
<path d="M544.727 472.911L540.996 472.875V476.642H544.727V472.911Z" fill="#373B3D"/>
<path d="M536.535 472.911L532.804 472.875V476.642H536.535V472.911Z" fill="#373B3D"/>
<path d="M528.343 472.911L524.612 472.875V476.642H528.343V472.911Z" fill="#373B3D"/>
<path d="M520.151 472.911L516.42 472.875V476.642H520.151V472.911Z" fill="#373B3D"/>
<path d="M511.96 472.911L508.229 472.875V476.642H511.96V472.911Z" fill="#373B3D"/>
<path d="M503.779 472.911L500.048 472.875V476.642H503.779V472.911Z" fill="#373B3D"/>
<path d="M307.212 472.911L303.481 472.875V476.642H307.212V472.911Z" fill="#373B3D"/>
<path d="M962.425 464.754L958.694 464.719V468.485H962.425V464.754Z" fill="#373B3D"/>
<path d="M937.85 464.754L934.119 464.719V468.485H937.85V464.754Z" fill="#373B3D"/>
<path d="M929.659 464.754L925.928 464.719V468.485H929.659V464.754Z" fill="#373B3D"/>
<path d="M921.478 464.754L917.747 464.719V468.485H921.478V464.754Z" fill="#373B3D"/>
<path d="M913.286 464.754L909.555 464.719V468.485H913.286V464.754Z" fill="#373B3D"/>

<path d="M905.095 464.754L901.364 464.719V468.485H905.095V464.754Z" fill="#373B3D"/>
<path d="M896.903 464.754L893.172 464.719V468.485H896.903V464.754Z" fill="#373B3D"/>
<path d="M888.711 464.754L884.98 464.719V468.485H888.711V464.754Z" fill="#373B3D"/>
<path d="M880.519 464.754L876.788 464.719V468.485H880.519V464.754Z" fill="#373B3D"/>
<path d="M872.328 464.754L868.597 464.719V468.485H872.328V464.754Z" fill="#373B3D"/>


<path d='M864.147 505.525L860.416 505.49V509.256H864.147V505.525Z' fill="#373B3D"/>
<path d='M864.147 513.682L860.416 513.646V517.413H864.147V513.682Z' fill="#373B3D"/>

<path d="M749.474 464.754L745.743 464.719V468.485H749.474V464.754Z" fill="#373B3D"/>
<path d="M741.294 464.754L737.563 464.719V468.485H741.294V464.754Z" fill="#373B3D"/>
<path d="M733.103 464.754L729.372 464.719V468.485H733.103V464.754Z" fill="#373B3D"/>

<path d="M724.911 464.754L721.179 464.719V468.485H724.911V464.754Z" fill="#373B3D"/>
<path d="M716.719 464.754L712.988 464.719V468.485H716.719V464.754Z" fill="#373B3D"/>
<path d="M708.527 464.754L704.796 464.719V468.485H708.527V464.754Z" fill="#373B3D"/>
<path d="M700.335 464.754L696.604 464.719V468.485H700.335V464.754Z" fill="#373B3D"/>
<path d="M692.143 464.754L688.412 464.719V468.485H692.143V464.754Z" fill="#373B3D"/>
<path d="M675.772 464.754L672.041 464.719V468.485H675.772V464.754Z" fill="#373B3D"/>
<path d="M667.58 464.754L663.849 464.719V468.485H667.58V464.754Z" fill="#373B3D"/>
<path d="M659.388 464.754L655.657 464.719V468.485H659.388V464.754Z" fill="#373B3D"/>
<path d="M651.196 464.754L647.465 464.719V468.485H651.196V464.754Z" fill="#373B3D"/>
<path d="M643.005 464.754L639.274 464.719V468.485H643.005V464.754Z" fill="#373B3D"/>
<path d="M634.813 464.754L631.082 464.719V468.485H634.813V464.754Z" fill="#373B3D"/>
<path d="M626.633 464.754L622.902 464.719V468.485H626.633V464.754Z" fill="#373B3D"/>
<path d="M618.441 464.754L614.71 464.719V468.485H618.441V464.754Z" fill="#373B3D"/>
<path d="M610.249 464.754L606.518 464.719V468.485H610.249V464.754Z" fill="#373B3D"/>
<path d="M602.057 464.754L598.326 464.719V468.485H602.057V464.754Z" fill="#373B3D"/>
<path d="M593.865 464.754L590.134 464.719V468.485H593.865V464.754Z" fill="#373B3D"/>
<path d="M585.674 464.754L581.943 464.719V468.485H585.674V464.754Z" fill="#373B3D"/>
<path d="M577.482 464.754L573.751 464.719V468.485H577.482V464.754Z" fill="#373B3D"/>
<path d="M569.29 464.754L565.559 464.719V468.485H569.29V464.754Z" fill="#373B3D"/>
<path d="M561.111 464.754L557.38 464.719V468.485H561.111V464.754Z" fill="#373B3D"/>
<path d="M552.918 464.754L549.187 464.719V468.485H552.918V464.754Z" fill="#373B3D"/>

<path d="M544.727 464.754L540.996 464.719V468.485H544.727V464.754Z" fill="#373B3D"/>
<path d="M536.535 464.754L532.804 464.719V468.485H536.535V464.754Z" fill="#373B3D"/>
<path d="M528.343 464.754L524.612 464.719V468.485H528.343V464.754Z" fill="#373B3D"/>
<path d="M520.151 464.754L516.42 464.719V468.485H520.151V464.754Z" fill="#373B3D"/>
<path d="M511.96 464.754L508.229 464.719V468.485H511.96V464.754Z" fill="#373B3D"/>
<path d="M503.779 464.754L500.048 464.719V468.485H503.779V464.754Z" fill="#373B3D"/>
<path d="M299.02 472.911L295.289 472.875V476.642H299.02V472.911Z" fill="#373B3D"/>
<path d="M299.02 456.598L295.289 456.562V460.329H299.02V456.598Z" fill="#373B3D"/>
<path d="M290.829 464.754L287.098 464.719V468.485H290.829V464.754Z" fill="#373B3D"/>
<path d="M282.637 464.754L278.906 464.719V468.485H282.637V464.754Z" fill="#373B3D"/>
<path d="M962.425 456.609L958.694 456.574V460.34H962.425V456.609Z" fill="#373B3D"/>
<path d="M946.042 456.609L942.311 456.574V460.34H946.042V456.609Z" fill="#373B3D"/>
<path d="M937.85 456.609L934.119 456.574V460.34H937.85V456.609Z" fill="#373B3D"/>
<path d="M929.659 456.609L925.928 456.574V460.34H929.659V456.609Z" fill="#373B3D"/>
<path d="M921.478 456.609L917.747 456.574V460.34H921.478V456.609Z" fill="#373B3D"/>
<path d="M913.286 456.609L909.555 456.574V460.34H913.286V456.609Z" fill="#373B3D"/>
<path d="M905.095 456.609L901.364 456.574V460.34H905.095V456.609Z" fill="#373B3D"/>
<path d="M896.903 456.609L893.172 456.574V460.34H896.903V456.609Z" fill="#373B3D"/>
<path d="M888.711 456.61L884.98 456.574V460.341H888.711V456.61Z" fill="#373B3D"/>
<path d="M880.519 456.609L876.788 456.574V460.34H880.519V456.609Z" fill="#373B3D"/>
<path d="M872.328 456.609L868.597 456.574V460.34H872.328V456.609Z" fill="#373B3D"/>
<path d="M782.242 456.609L778.51 456.574V460.34H782.242V456.609Z" fill="#373B3D"/>
<path d="M741.294 456.609L737.563 456.574V460.34H741.294V456.609Z" fill="#373B3D"/>
<path d="M724.911 456.609L721.179 456.574V460.34H724.911V456.609Z" fill="#373B3D"/>
<path d="M716.719 456.609L712.988 456.574V460.34H716.719V456.609Z" fill="#373B3D"/>
<path d="M708.527 456.609L704.796 456.574V460.34H708.527V456.609Z" fill="#373B3D"/>
<path d="M700.335 456.609L696.604 456.574V460.34H700.335V456.609Z" fill="#373B3D"/>
<path d="M692.143 456.609L688.412 456.574V460.34H692.143V456.609Z" fill="#373B3D"/>
<path d="M683.964 456.609L680.233 456.574V460.34H683.964V456.609Z" fill="#373B3D"/>

<path d="M667.58 456.609L663.849 456.574V460.34H667.58V456.609Z" fill="#373B3D"/>
<path d="M659.388 456.609L655.657 456.574V460.34H659.388V456.609Z" fill="#373B3D"/>
<path d="M651.196 456.609L647.465 456.574V460.34H651.196V456.609Z" fill="#373B3D"/>
<path d="M643.005 456.609L639.274 456.574V460.34H643.005V456.609Z" fill="#373B3D"/>
<path d="M634.813 456.609L631.082 456.574V460.34H634.813V456.609Z" fill="#373B3D"/>
<path d="M626.633 456.609L622.902 456.574V460.34H626.633V456.609Z" fill="#373B3D"/>
<path d="M618.441 456.609L614.71 456.574V460.34H618.441V456.609Z" fill="#373B3D"/>
<path d="M610.249 456.609L606.518 456.574V460.34H610.249V456.609Z" fill="#373B3D"/>
<path d="M602.057 456.609L598.326 456.574V460.34H602.057V456.609Z" fill="#373B3D"/>
<path d="M593.865 456.609L590.134 456.574V460.34H593.865V456.609Z" fill="#373B3D"/>
<path d="M585.674 456.609L581.943 456.574V460.34H585.674V456.609Z" fill="#373B3D"/>
<path d="M577.482 456.609L573.751 456.574V460.34H577.482V456.609Z" fill="#373B3D"/>
<path d="M569.29 456.609L565.559 456.574V460.34H569.29V456.609Z" fill="#373B3D"/>
<path d="M561.111 456.609L557.38 456.574V460.34H561.111V456.609Z" fill="#373B3D"/>
<path d="M552.918 456.61L549.187 456.574V460.341H552.918V456.61Z" fill="#373B3D"/>
<path d="M544.727 456.609L540.996 456.574V460.34H544.727V456.609Z" fill="#373B3D"/>
<path d="M536.535 456.609L532.804 456.574V460.34H536.535V456.609Z" fill="#373B3D"/>
<path d="M528.343 456.609L524.612 456.574V460.34H528.343V456.609Z" fill="#373B3D"/>
<path d="M520.151 456.609L516.42 456.574V460.34H520.151V456.609Z" fill="#373B3D"/>
<path d="M511.96 456.609L508.229 456.574V460.34H511.96V456.609Z" fill="#373B3D"/>
<path d="M954.233 448.453L950.502 448.418V452.184H954.233V448.453Z" fill="#373B3D"/>
<path d="M946.042 448.453L942.311 448.418V452.184H946.042V448.453Z" fill="#373B3D"/>
<path d="M937.85 448.453L934.119 448.418V452.184H937.85V448.453Z" fill="#373B3D"/>
<path d="M929.659 448.453L925.928 448.418V452.184H929.659V448.453Z" fill="#373B3D"/>
<path d="M921.478 448.453L917.747 448.418V452.184H921.478V448.453Z" fill="#373B3D"/>
<path d="M913.286 448.453L909.555 448.418V452.184H913.286V448.453Z" fill="#373B3D"/>
<path d="M905.095 448.453L901.364 448.418V452.184H905.095V448.453Z" fill="#373B3D"/>
<path d="M896.903 448.453L893.172 448.418V452.184H896.903V448.453Z" fill="#373B3D"/>
<path d="M888.711 448.453L884.98 448.417V452.184H888.711V448.453Z" fill="#373B3D"/>
<path d="M880.519 448.453L876.788 448.418V452.184H880.519V448.453Z" fill="#373B3D"/>
<path d="M790.433 448.453L786.702 448.418V452.184H790.433V448.453Z" fill="#373B3D"/>
<path d="M782.242 448.453L778.51 448.418V452.184H782.242V448.453Z" fill="#373B3D"/>
<path d="M774.05 448.453L770.319 448.418V452.184H774.05V448.453Z" fill="#373B3D"/>
<path d="M765.858 448.453L762.127 448.418V452.184H765.858V448.453Z" fill="#373B3D"/>
<path d="M757.666 448.453L753.935 448.418V452.184H757.666V448.453Z" fill="#373B3D"/>
<path d="M749.474 448.453L745.743 448.418V452.184H749.474V448.453Z" fill="#373B3D"/>
<path d="M741.294 448.453L737.563 448.418V452.184H741.294V448.453Z" fill="#373B3D"/>
<path d="M716.719 448.453L712.988 448.418V452.184H716.719V448.453Z" fill="#373B3D"/>
<path d="M724.911 448.453L721.179 448.418V452.184H724.911V448.453Z" fill="#373B3D"/>
<path d="M708.527 448.453L704.796 448.418V452.184H708.527V448.453Z" fill="#373B3D"/>
<path d="M700.335 448.453L696.604 448.418V452.184H700.335V448.453Z" fill="#373B3D"/>

<path d="M692.143 448.453L688.412 448.418V452.184H692.143V448.453Z" fill="#373B3D"/>
<path d="M683.964 448.453L680.233 448.418V452.184H683.964V448.453Z" fill="#373B3D"/>
<path d="M667.58 448.453L663.849 448.418V452.184H667.58V448.453Z" fill="#373B3D"/>
<path d="M659.388 448.453L655.657 448.418V452.184H659.388V448.453Z" fill="#373B3D"/>
<path d="M651.196 448.453L647.465 448.418V452.184H651.196V448.453Z" fill="#373B3D"/>
<path d="M643.005 448.453L639.274 448.418V452.184H643.005V448.453Z" fill="#373B3D"/>
<path d="M634.813 448.453L631.082 448.418V452.184H634.813V448.453Z" fill="#373B3D"/>
<path d="M626.633 448.453L622.902 448.418V452.184H626.633V448.453Z" fill="#373B3D"/>
<path d="M618.441 448.453L614.71 448.418V452.184H618.441V448.453Z" fill="#373B3D"/>
<path d="M610.249 448.453L606.518 448.418V452.184H610.249V448.453Z" fill="#373B3D"/>
<path d="M602.057 448.453L598.326 448.418V452.184H602.057V448.453Z" fill="#373B3D"/>
<path d="M593.865 448.453L590.134 448.418V452.184H593.865V448.453Z" fill="#373B3D"/>
<path d="M585.674 448.453L581.943 448.418V452.184H585.674V448.453Z" fill="#373B3D"/>
<path d="M577.482 448.453L573.751 448.418V452.184H577.482V448.453Z" fill="#373B3D"/>
<path d="M569.29 448.453L565.559 448.418V452.184H569.29V448.453Z" fill="#373B3D"/>
<path d="M561.111 448.453L557.38 448.418V452.184H561.111V448.453Z" fill="#373B3D"/>
<path d="M552.918 448.453L549.187 448.417V452.184H552.918V448.453Z" fill="#373B3D"/>
<path d="M544.727 448.453L540.996 448.418V452.184H544.727V448.453Z" fill="#373B3D"/>
<path d="M536.535 448.453L532.804 448.418V452.184H536.535V448.453Z" fill="#373B3D"/>
<path d="M528.343 448.453L524.612 448.418V452.184H528.343V448.453Z" fill="#373B3D"/>
<path d="M520.151 448.453L516.42 448.418V452.184H520.151V448.453Z" fill="#373B3D"/>
<path d="M954.233 440.297L950.502 440.261V444.028H954.233V440.297Z" fill="#373B3D"/>
<path d="M946.042 440.297L942.311 440.261V444.028H946.042V440.297Z" fill="#373B3D"/>
<path d="M937.85 440.297L934.119 440.261V444.028H937.85V440.297Z" fill="#373B3D"/>
<path d="M929.659 440.297L925.928 440.261V444.028H929.659V440.297Z" fill="#373B3D"/>
<path d="M921.478 440.297L917.747 440.261V444.028H921.478V440.297Z" fill="#373B3D"/>
<path d="M913.286 440.297L909.555 440.261V444.028H913.286V440.297Z" fill="#373B3D"/>
<path d="M905.095 440.297L901.364 440.261V444.028H905.095V440.297Z" fill="#373B3D"/>
<path d="M896.903 440.297L893.172 440.261V444.028H896.903V440.297Z" fill="#373B3D"/>
<path d="M888.711 440.297L884.98 440.261V444.028H888.711V440.297Z" fill="#373B3D"/>
<path d="M880.519 440.297L876.788 440.261V444.028H880.519V440.297Z" fill="#373B3D"/>
<path d="M872.328 440.297L868.597 440.261V444.028H872.328V440.297Z" fill="#373B3D"/>
<path d="M864.147 440.297L860.416 440.261V444.028H864.147V440.297Z" fill="#373B3D"/>
<path d="M855.956 440.297L852.225 440.261V444.028H855.956V440.297Z" fill="#373B3D"/>
<path d="M847.764 440.297L844.033 440.261V444.028H847.764V440.297Z" fill="#373B3D"/>
<path d="M839.572 440.297L835.841 440.261V444.028H839.572V440.297Z" fill="#373B3D"/>
<path d="M831.381 440.297L827.65 440.261V444.028H831.381V440.297Z" fill="#373B3D"/>
<path d="M798.625 440.297L794.894 440.261V444.028H798.625V440.297Z" fill="#373B3D"/>
<path d="M790.433 440.297L786.702 440.261V444.028H790.433V440.297Z" fill="#373B3D"/>
<path d="M782.242 440.297L778.51 440.261V444.028H782.242V440.297Z" fill="#373B3D"/>
<path d="M774.05 440.297L770.319 440.261V444.028H774.05V440.297Z" fill="#373B3D"/>
<path d="M765.858 440.297L762.127 440.261V444.028H765.858V440.297Z" fill="#373B3D"/>
<path d="M757.666 440.297L753.935 440.261V444.028H757.666V440.297Z" fill="#373B3D"/>
<path d="M749.474 440.297L745.743 440.261V444.028H749.474V440.297Z" fill="#373B3D"/>
<path d="M741.294 440.297L737.563 440.261V444.028H741.294V440.297Z" fill="#373B3D"/>
<path d="M733.103 440.297L729.372 440.261V444.028H733.103V440.297Z" fill="#373B3D"/>
<path d="M716.719 440.297L712.988 440.261V444.028H716.719V440.297Z" fill="#373B3D"/>
<path d="M708.527 440.297L704.796 440.261V444.028H708.527V440.297Z" fill="#373B3D"/>
<path d="M700.335 440.297L696.604 440.261V444.028H700.335V440.297Z" fill="#373B3D"/>
<path d="M692.143 440.297L688.412 440.261V444.028H692.143V440.297Z" fill="#373B3D"/>
<path d="M683.964 440.297L680.233 440.261V444.028H683.964V440.297Z" fill="#373B3D"/>
<path d="M675.772 440.297L672.041 440.261V444.028H675.772V440.297Z" fill="#373B3D"/>
<path d="M667.58 440.297L663.849 440.261V444.028H667.58V440.297Z" fill="#373B3D"/>
<path d="M667.58 432.152L663.849 432.117V435.883H667.58V432.152Z" fill="#373B3D"/>
<path d="M659.388 440.297L655.657 440.261V444.028H659.388V440.297Z" fill="#373B3D"/>
<path d="M659.388 432.152L655.657 432.117V435.883H659.388V432.152Z" fill="#373B3D"/>
<path d="M651.196 440.297L647.465 440.261V444.028H651.196V440.297Z" fill="#373B3D"/>
<path d="M651.196 432.152L647.465 432.117V435.883H651.196V432.152Z" fill="#373B3D"/>
<path d="M643.005 440.297L639.274 440.261V444.028H643.005V440.297Z" fill="#373B3D"/>
<path d="M643.005 432.152L639.274 432.117V435.883H643.005V432.152Z" fill="#373B3D"/>
<path d="M634.813 440.297L631.082 440.261V444.028H634.813V440.297Z" fill="#373B3D"/>
<path d="M626.633 440.297L622.902 440.261V444.028H626.633V440.297Z" fill="#373B3D"/>
<path d="M618.441 440.297L614.71 440.261V444.028H618.441V440.297Z" fill="#373B3D"/>
<path d="M610.249 440.297L606.518 440.261V444.028H610.249V440.297Z" fill="#373B3D"/>
<path d="M610.249 432.152L606.518 432.117V435.883H610.249V432.152Z" fill="#373B3D"/>
<path d="M602.057 440.297L598.326 440.261V444.028H602.057V440.297Z" fill="#373B3D"/>
<path d="M593.865 440.297L590.134 440.261V444.028H593.865V440.297Z" fill="#373B3D"/>
<path d="M585.674 440.297L581.943 440.261V444.028H585.674V440.297Z" fill="#373B3D"/>
<path d="M577.482 440.297L573.751 440.261V444.028H577.482V440.297Z" fill="#373B3D"/>
<path d="M569.29 440.297L565.559 440.261V444.028H569.29V440.297Z" fill="#373B3D"/>
<path d="M561.111 440.297L557.38 440.261V444.028H561.111V440.297Z" fill="#373B3D"/>
<path d="M552.918 440.297L549.187 440.261V444.028H552.918V440.297Z" fill="#373B3D"/>
<path d="M544.727 440.297L540.996 440.261V444.028H544.727V440.297Z" fill="#373B3D"/>
<path d="M536.535 440.297L532.804 440.261V444.028H536.535V440.297Z" fill="#373B3D"/>
<path d="M528.343 440.297L524.612 440.261V444.028H528.343V440.297Z" fill="#373B3D"/>
<path d="M986.989 432.152L983.258 432.116V435.883H986.989V432.152Z" fill="#373B3D"/>
<path d="M954.233 432.152L950.502 432.117V435.883H954.233V432.152Z" fill="#373B3D"/>
<path d="M946.042 432.152L942.311 432.117V435.883H946.042V432.152Z" fill="#373B3D"/>
<path d="M937.85 432.152L934.119 432.117V435.883H937.85V432.152Z" fill="#373B3D"/>
<path d="M929.659 432.152L925.928 432.117V435.883H929.659V432.152Z" fill="#373B3D"/>
<path d="M921.478 432.152L917.747 432.117V435.883H921.478V432.152Z" fill="#373B3D"/>
<path d="M913.286 432.152L909.555 432.117V435.883H913.286V432.152Z" fill="#373B3D"/>
<path d="M905.095 432.152L901.364 432.117V435.883H905.095V432.152Z" fill="#373B3D"/>
<path d="M896.903 432.152L893.172 432.117V435.883H896.903V432.152Z" fill="#373B3D"/>
<path d="M888.711 432.152L884.98 432.116V435.883H888.711V432.152Z" fill="#373B3D"/>
<path d="M880.519 432.152L876.788 432.117V435.883H880.519V432.152Z" fill="#373B3D"/>
<path d="M872.328 432.152L868.597 432.117V435.883H872.328V432.152Z" fill="#373B3D"/>
<path d="M864.147 432.152L860.416 432.117V435.883H864.147V432.152Z" fill="#373B3D"/>
<path d="M855.956 432.152L852.225 432.117V435.883H855.956V432.152Z" fill="#373B3D"/>
<path d="M847.764 432.152L844.033 432.117V435.883H847.764V432.152Z" fill="#373B3D"/>
<path d="M839.572 432.152L835.841 432.117V435.883H839.572V432.152Z" fill="#373B3D"/>
<path d="M831.381 432.152L827.65 432.117V435.883H831.381V432.152Z" fill="#373B3D"/>
<path d="M823.189 432.152L819.458 432.117V435.884H823.189V432.152Z" fill="#373B3D"/>
<path d="M798.625 432.152L794.894 432.117V435.883H798.625V432.152Z" fill="#373B3D"/>
<path d="M790.433 432.152L786.702 432.117V435.883H790.433V432.152Z" fill="#373B3D"/>
<path d="M782.242 432.152L778.51 432.117V435.883H782.242V432.152Z" fill="#373B3D"/>
<path d="M774.05 432.152L770.319 432.117V435.883H774.05V432.152Z" fill="#373B3D"/>
<path d="M765.858 432.152L762.127 432.117V435.883H765.858V432.152Z" fill="#373B3D"/>
<path d="M757.666 432.152L753.935 432.117V435.883H757.666V432.152Z" fill="#373B3D"/>
<path d="M749.474 432.152L745.743 432.117V435.883H749.474V432.152Z" fill="#373B3D"/>
<path d="M741.294 432.152L737.563 432.117V435.883H741.294V432.152Z" fill="#373B3D"/>
<path d="M733.103 432.152L729.372 432.117V435.883H733.103V432.152Z" fill="#373B3D"/>
<path d="M724.911 432.152L721.179 432.117V435.883H724.911V432.152Z" fill="#373B3D"/>
<path d="M716.719 432.152L712.988 432.117V435.883H716.719V432.152Z" fill="#373B3D"/>
<path d="M708.527 432.152L704.796 432.117V435.883H708.527V432.152Z" fill="#373B3D"/>
<path d="M700.335 432.152L696.604 432.117V435.883H700.335V432.152Z" fill="#373B3D"/>
<path d="M692.143 432.152L688.412 432.117V435.883H692.143V432.152Z" fill="#373B3D"/>
<path d="M683.964 432.152L680.233 432.117V435.883H683.964V432.152Z" fill="#373B3D"/>
<path d="M675.772 432.152L672.041 432.117V435.883H675.772V432.152Z" fill="#373B3D"/>
<path d="M634.813 432.152L631.082 432.117V435.883H634.813V432.152Z" fill="#373B3D"/>
<path d="M626.633 432.152L622.902 432.117V435.883H626.633V432.152Z" fill="#373B3D"/>
<path d="M602.057 432.152L598.326 432.117V435.883H602.057V432.152Z" fill="#373B3D"/>
<path d="M593.865 432.152L590.134 432.117V435.883H593.865V432.152Z" fill="#373B3D"/>
<path d="M593.866 423.984L590.135 423.949V427.715H593.866V423.984Z" fill="#373B3D"/>
<path d="M585.674 432.152L581.943 432.117V435.883H585.674V432.152Z" fill="#373B3D"/>
<path d="M577.482 432.152L573.751 432.117V435.883H577.482V432.152Z" fill="#373B3D"/>
<path d="M569.29 432.152L565.559 432.117V435.883H569.29V432.152Z" fill="#373B3D"/>
<path d="M561.111 432.152L557.38 432.117V435.883H561.111V432.152Z" fill="#373B3D"/>
<path d="M552.918 432.152L549.187 432.116V435.883H552.918V432.152Z" fill="#373B3D"/>
<path d="M544.727 432.152L540.996 432.117V435.883H544.727V432.152Z" fill="#373B3D"/>
<path d="M536.535 432.152L532.804 432.117V435.883H536.535V432.152Z" fill="#373B3D"/>
<path d="M528.343 432.152L524.612 432.117V435.883H528.343V432.152Z" fill="#373B3D"/>
<path d="M1003.37 423.995L999.642 423.96V427.726H1003.37V423.995Z" fill="#373B3D"/>
<path d="M995.181 423.995L991.45 423.96V427.726H995.181V423.995Z" fill="#373B3D"/>
<path d="M954.233 423.995L950.502 423.96V427.726H954.233V423.995Z" fill="#373B3D"/>
<path d="M946.042 423.995L942.311 423.96V427.726H946.042V423.995Z" fill="#373B3D"/>
<path d="M937.85 423.995L934.119 423.96V427.726H937.85V423.995Z" fill="#373B3D"/>
<path d="M929.659 423.995L925.928 423.96V427.726H929.659V423.995Z" fill="#373B3D"/>
<path d="M921.478 423.995L917.747 423.96V427.726H921.478V423.995Z" fill="#373B3D"/>
<path d="M913.286 423.995L909.555 423.96V427.726H913.286V423.995Z" fill="#373B3D"/>
<path d="M905.095 423.995L901.364 423.96V427.726H905.095V423.995Z" fill="#373B3D"/>
<path d="M896.903 423.995L893.172 423.96V427.726H896.903V423.995Z" fill="#373B3D"/>
<path d="M888.711 423.995L884.98 423.96V427.726H888.711V423.995Z" fill="#373B3D"/>
<path d="M880.519 423.995L876.788 423.96V427.726H880.519V423.995Z" fill="#373B3D"/>
<path d="M872.328 423.995L868.597 423.96V427.726H872.328V423.995Z" fill="#373B3D"/>
<path d="M864.147 423.995L860.416 423.96V427.726H864.147V423.995Z" fill="#373B3D"/>
<path d="M855.956 423.995L852.225 423.96V427.726H855.956V423.995Z" fill="#373B3D"/>
<path d="M847.764 423.995L844.033 423.96V427.726H847.764V423.995Z" fill="#373B3D"/>
<path d="M839.572 423.995L835.841 423.96V427.726H839.572V423.995Z" fill="#373B3D"/>
<path d="M831.381 423.995L827.65 423.96V427.726H831.381V423.995Z" fill="#373B3D"/>
<path d="M823.189 423.996L819.458 423.96V427.727H823.189V423.996Z" fill="#373B3D"/>
<path d="M798.625 423.995L794.894 423.96V427.726H798.625V423.995Z" fill="#373B3D"/>
<path d="M790.433 423.995L786.702 423.96V427.726H790.433V423.995Z" fill="#373B3D"/>
<path d="M782.242 423.995L778.51 423.96V427.726H782.242V423.995Z" fill="#373B3D"/>
<path d="M774.05 423.995L770.319 423.96V427.726H774.05V423.995Z" fill="#373B3D"/>
<path d="M765.858 423.995L762.127 423.96V427.726H765.858V423.995Z" fill="#373B3D"/>
<path d="M757.666 423.995L753.935 423.96V427.726H757.666V423.995Z" fill="#373B3D"/>
<path d="M749.474 423.995L745.743 423.96V427.726H749.474V423.995Z" fill="#373B3D"/>
<path d="M741.294 423.995L737.563 423.96V427.726H741.294V423.995Z" fill="#373B3D"/>
<path d="M733.103 423.995L729.372 423.96V427.726H733.103V423.995Z" fill="#373B3D"/>
<path d="M724.911 423.995L721.179 423.96V427.726H724.911V423.995Z" fill="#373B3D"/>
<path d="M716.719 423.995L712.988 423.96V427.726H716.719V423.995Z" fill="#373B3D"/>
<path d="M708.527 423.995L704.796 423.96V427.726H708.527V423.995Z" fill="#373B3D"/>
<path d="M700.335 423.995L696.604 423.96V427.726H700.335V423.995Z" fill="#373B3D"/>
<path d="M692.143 423.995L688.412 423.96V427.726H692.143V423.995Z" fill="#373B3D"/>
<path d="M683.964 423.995L680.233 423.96V427.726H683.964V423.995Z" fill="#373B3D"/>
<path d="M675.772 423.995L672.041 423.96V427.726H675.772V423.995Z" fill="#373B3D"/>
<path d="M585.674 423.995L581.943 423.96V427.726H585.674V423.995Z" fill="#373B3D"/>
<path d="M577.482 423.995L573.751 423.96V427.726H577.482V423.995Z" fill="#373B3D"/>
<path d="M569.29 423.995L565.559 423.96V427.726H569.29V423.995Z" fill="#373B3D"/>
<path d="M561.111 423.995L557.38 423.96V427.726H561.111V423.995Z" fill="#373B3D"/>
<path d="M552.918 423.995L549.187 423.96V427.726H552.918V423.995Z" fill="#373B3D"/>
<path d="M544.727 423.995L540.996 423.96V427.726H544.727V423.995Z" fill="#373B3D"/>
<path d="M544.727 415.839L540.996 415.803V419.57H544.727V415.839Z" fill="#373B3D"/>
<path d="M536.535 423.995L532.804 423.96V427.726H536.535V423.995Z" fill="#373B3D"/>
<path d="M1019.76 415.839L1016.03 415.803V419.57H1019.76V415.839Z" fill="#373B3D"/>
<path d="M1011.56 415.839L1007.83 415.803V419.57H1011.56V415.839Z" fill="#373B3D"/>
<path d="M1003.37 415.839L999.642 415.803V419.57H1003.37V415.839Z" fill="#373B3D"/>
<path d="M978.809 415.839L975.078 415.803V419.57H978.809V415.839Z" fill="#373B3D"/>
<path d="M954.233 415.839L950.502 415.803V419.57H954.233V415.839Z" fill="#373B3D"/>
<path d="M946.042 415.839L942.311 415.803V419.57H946.042V415.839Z" fill="#373B3D"/>
<path d="M937.85 415.839L934.119 415.803V419.57H937.85V415.839Z" fill="#373B3D"/>
<path d="M929.659 415.839L925.928 415.803V419.57H929.659V415.839Z" fill="#373B3D"/>
<path d="M921.478 415.839L917.747 415.803V419.57H921.478V415.839Z" fill="#373B3D"/>
<path d="M913.286 415.839L909.555 415.803V419.57H913.286V415.839Z" fill="#373B3D"/>
<path d="M905.095 415.839L901.364 415.803V419.57H905.095V415.839Z" fill="#373B3D"/>
<path d="M896.903 415.839L893.172 415.803V419.57H896.903V415.839Z" fill="#373B3D"/>
<path d="M888.711 415.839L884.98 415.803V419.57H888.711V415.839Z" fill="#373B3D"/>
<path d="M880.519 415.839L876.788 415.803V419.57H880.519V415.839Z" fill="#373B3D"/>
<path d="M872.328 415.839L868.597 415.803V419.57H872.328V415.839Z" fill="#373B3D"/>
<path d="M864.147 415.839L860.416 415.803V419.57H864.147V415.839Z" fill="#373B3D"/>
<path d="M855.956 415.839L852.225 415.803V419.57H855.956V415.839Z" fill="#373B3D"/>
<path d="M847.764 415.839L844.033 415.803V419.57H847.764V415.839Z" fill="#373B3D"/>
<path d="M839.572 415.839L835.841 415.803V419.57H839.572V415.839Z" fill="#373B3D"/>
<path d="M831.381 415.839L827.65 415.803V419.57H831.381V415.839Z" fill="#373B3D"/>
<path d="M823.189 415.839L819.458 415.804V419.57H823.189V415.839Z" fill="#373B3D"/>
<path d="M814.997 415.839L811.266 415.804V419.57H814.997V415.839Z" fill="#373B3D"/>
<path d="M806.805 415.839L803.074 415.803V419.57H806.805V415.839Z" fill="#373B3D"/>
<path d="M798.625 415.839L794.894 415.803V419.57H798.625V415.839Z" fill="#373B3D"/>
<path d="M790.433 415.839L786.702 415.803V419.57H790.433V415.839Z" fill="#373B3D"/>
<path d="M782.242 415.839L778.51 415.803V419.57H782.242V415.839Z" fill="#373B3D"/>
<path d="M774.05 415.839L770.319 415.803V419.57H774.05V415.839Z" fill="#373B3D"/>
<path d="M765.858 415.839L762.127 415.803V419.57H765.858V415.839Z" fill="#373B3D"/>
<path d="M757.666 415.839L753.935 415.803V419.57H757.666V415.839Z" fill="#373B3D"/>
<path d="M749.474 415.839L745.743 415.803V419.57H749.474V415.839Z" fill="#373B3D"/>
<path d="M741.294 415.839L737.563 415.803V419.57H741.294V415.839Z" fill="#373B3D"/>
<path d="M733.103 415.839L729.372 415.803V419.57H733.103V415.839Z" fill="#373B3D"/>
<path d="M724.911 415.839L721.179 415.803V419.57H724.911V415.839Z" fill="#373B3D"/>
<path d="M716.719 415.839L712.988 415.803V419.57H716.719V415.839Z" fill="#373B3D"/>
<path d="M708.527 415.839L704.796 415.803V419.57H708.527V415.839Z" fill="#373B3D"/>
<path d="M700.335 415.839L696.604 415.803V419.57H700.335V415.839Z" fill="#373B3D"/>
<path d="M692.143 415.839L688.412 415.803V419.57H692.143V415.839Z" fill="#373B3D"/>
<path d="M683.964 415.839L680.233 415.803V419.57H683.964V415.839Z" fill="#373B3D"/>
<path d="M675.772 415.839L672.041 415.803V419.57H675.772V415.839Z" fill="#373B3D"/>
<path d="M593.865 415.839L590.134 415.803V419.57H593.865V415.839Z" fill="#373B3D"/>
<path d="M585.674 415.839L581.943 415.803V419.57H585.674V415.839Z" fill="#373B3D"/>
<path d="M577.482 415.839L573.751 415.803V419.57H577.482V415.839Z" fill="#373B3D"/>
<path d="M569.29 415.839L565.559 415.803V419.57H569.29V415.839Z" fill="#373B3D"/>
<path d="M561.111 415.839L557.38 415.803V419.57H561.111V415.839Z" fill="#373B3D"/>
<path d="M1019.76 407.682L1016.03 407.647V411.413H1019.76V407.682Z" fill="#373B3D"/>
<path d="M978.809 407.682L975.078 407.647V411.413H978.809V407.682Z" fill="#373B3D"/>
<path d="M954.233 407.682L950.502 407.647V411.413H954.233V407.682Z" fill="#373B3D"/>
<path d="M946.042 407.682L942.311 407.647V411.413H946.042V407.682Z" fill="#373B3D"/>
<path d="M937.85 407.682L934.119 407.647V411.413H937.85V407.682Z" fill="#373B3D"/>
<path d="M929.659 407.682L925.928 407.647V411.413H929.659V407.682Z" fill="#373B3D"/>
<path d="M921.478 407.682L917.747 407.647V411.413H921.478V407.682Z" fill="#373B3D"/>
<path d="M913.286 407.682L909.555 407.647V411.413H913.286V407.682Z" fill="#373B3D"/>
<path d="M905.095 407.682L901.364 407.647V411.413H905.095V407.682Z" fill="#373B3D"/>
<path d="M896.903 407.682L893.172 407.647V411.413H896.903V407.682Z" fill="#373B3D"/>
<path d="M888.711 407.683L884.98 407.647V411.414H888.711V407.683Z" fill="#373B3D"/>
<path d="M880.519 407.682L876.788 407.647V411.413H880.519V407.682Z" fill="#373B3D"/>
<path d="M872.328 407.682L868.597 407.647V411.413H872.328V407.682Z" fill="#373B3D"/>
<path d="M864.147 407.682L860.416 407.647V411.413H864.147V407.682Z" fill="#373B3D"/>
<path d="M855.956 407.682L852.225 407.647V411.413H855.956V407.682Z" fill="#373B3D"/>
<path d="M847.764 407.682L844.033 407.647V411.413H847.764V407.682Z" fill="#373B3D"/>
<path d="M839.572 407.682L835.841 407.647V411.413H839.572V407.682Z" fill="#373B3D"/>
<path d="M831.381 407.682L827.65 407.647V411.413H831.381V407.682Z" fill="#373B3D"/>
<path d="M823.189 407.683L819.458 407.647V411.414H823.189V407.683Z" fill="#373B3D"/>
<path d="M814.997 407.683L811.266 407.647V411.414H814.997V407.683Z" fill="#373B3D"/>
<path d="M806.805 407.683L803.074 407.647V411.414H806.805V407.683Z" fill="#373B3D"/>
<path d="M798.625 407.682L794.894 407.647V411.413H798.625V407.682Z" fill="#373B3D"/>
<path d="M790.433 407.682L786.702 407.647V411.413H790.433V407.682Z" fill="#373B3D"/>
<path d="M782.242 407.682L778.51 407.647V411.413H782.242V407.682Z" fill="#373B3D"/>
<path d="M774.05 407.682L770.319 407.647V411.413H774.05V407.682Z" fill="#373B3D"/>
<path d="M765.858 407.682L762.127 407.647V411.413H765.858V407.682Z" fill="#373B3D"/>
<path d="M757.666 407.682L753.935 407.647V411.413H757.666V407.682Z" fill="#373B3D"/>
<path d="M749.474 407.682L745.743 407.647V411.413H749.474V407.682Z" fill="#373B3D"/>
<path d="M741.294 407.682L737.563 407.647V411.413H741.294V407.682Z" fill="#373B3D"/>
<path d="M716.719 407.682L712.988 407.647V411.413H716.719V407.682Z" fill="#373B3D"/>
<path d="M708.527 407.682L704.796 407.647V411.413H708.527V407.682Z" fill="#373B3D"/>
<path d="M700.335 407.682L696.604 407.647V411.413H700.335V407.682Z" fill="#373B3D"/>
<path d="M692.143 407.682L688.412 407.647V411.413H692.143V407.682Z" fill="#373B3D"/>
<path d="M683.964 407.682L680.233 407.647V411.413H683.964V407.682Z" fill="#373B3D"/>
<path d="M675.772 407.682L672.041 407.647V411.413H675.772V407.682Z" fill="#373B3D"/>
<path d="M667.58 407.682L663.849 407.647V411.413H667.58V407.682Z" fill="#373B3D"/>
<path d="M659.388 407.682L655.657 407.647V411.413H659.388V407.682Z" fill="#373B3D"/>
<path d="M651.196 407.682L647.465 407.647V411.413H651.196V407.682Z" fill="#373B3D"/>
<path d="M634.813 407.682L631.082 407.647V411.413H634.813V407.682Z" fill="#373B3D"/>
<path d="M1027.95 399.538L1024.22 399.502V403.269H1027.95V399.538Z" fill="#373B3D"/>
<path d="M1019.76 399.538L1016.03 399.502V403.269H1019.76V399.538Z" fill="#373B3D"/>
<path d="M978.809 399.538L975.078 399.502V403.269H978.809V399.538Z" fill="#373B3D"/>
<path d="M970.617 399.538L966.886 399.502V403.269H970.617V399.538Z" fill="#373B3D"/>
<path d="M962.425 399.538L958.694 399.502V403.269H962.425V399.538Z" fill="#373B3D"/>
<path d="M946.042 399.538L942.311 399.502V403.269H946.042V399.538Z" fill="#373B3D"/>
<path d="M937.85 399.538L934.119 399.502V403.269H937.85V399.538Z" fill="#373B3D"/>
<path d="M929.659 399.538L925.928 399.502V403.269H929.659V399.538Z" fill="#373B3D"/>
<path d="M921.478 399.538L917.747 399.502V403.269H921.478V399.538Z" fill="#373B3D"/>
<path d="M913.286 399.538L909.555 399.502V403.269H913.286V399.538Z" fill="#373B3D"/>
<path d="M905.095 399.538L901.364 399.502V403.269H905.095V399.538Z" fill="#373B3D"/>
<path d="M896.903 399.538L893.172 399.502V403.269H896.903V399.538Z" fill="#373B3D"/>
<path d="M888.711 399.538L884.98 399.502V403.269H888.711V399.538Z" fill="#373B3D"/>
<path d="M880.519 399.538L876.788 399.502V403.269H880.519V399.538Z" fill="#373B3D"/>
<path d="M872.328 399.538L868.597 399.502V403.269H872.328V399.538Z" fill="#373B3D"/>
<path d="M864.147 399.538L860.416 399.502V403.269H864.147V399.538Z" fill="#373B3D"/>
<path d="M855.956 399.538L852.225 399.502V403.269H855.956V399.538Z" fill="#373B3D"/>
<path d="M847.764 399.538L844.033 399.502V403.269H847.764V399.538Z" fill="#373B3D"/>
<path d="M839.572 399.538L835.841 399.502V403.269H839.572V399.538Z" fill="#373B3D"/>
<path d="M831.381 399.538L827.65 399.502V403.269H831.381V399.538Z" fill="#373B3D"/>
<path d="M823.189 399.538L819.458 399.503V403.269H823.189V399.538Z" fill="#373B3D"/>
<path d="M814.997 399.538L811.266 399.503V403.269H814.997V399.538Z" fill="#373B3D"/>
<path d="M806.805 399.538L803.074 399.502V403.269H806.805V399.538Z" fill="#373B3D"/>
<path d="M798.625 399.538L794.894 399.502V403.269H798.625V399.538Z" fill="#373B3D"/>
<path d="M790.433 399.538L786.702 399.502V403.269H790.433V399.538Z" fill="#373B3D"/>
<path d="M782.242 399.538L778.51 399.502V403.269H782.242V399.538Z" fill="#373B3D"/>
<path d="M774.05 399.538L770.319 399.502V403.269H774.05V399.538Z" fill="#373B3D"/>
<path d="M765.858 399.538L762.127 399.502V403.269H765.858V399.538Z" fill="#373B3D"/>
<path d="M757.666 399.538L753.935 399.502V403.269H757.666V399.538Z" fill="#373B3D"/>
<path d="M749.474 399.538L745.743 399.502V403.269H749.474V399.538Z" fill="#373B3D"/>
<path d="M741.294 399.538L737.563 399.502V403.269H741.294V399.538Z" fill="#373B3D"/>
<path d="M716.719 399.538L712.988 399.502V403.269H716.719V399.538Z" fill="#373B3D"/>
<path d="M708.527 399.538L704.796 399.502V403.269H708.527V399.538Z" fill="#373B3D"/>
<path d="M700.335 399.538L696.604 399.502V403.269H700.335V399.538Z" fill="#373B3D"/>
<path d="M692.143 399.538L688.412 399.502V403.269H692.143V399.538Z" fill="#373B3D"/>
<path d="M683.964 399.538L680.233 399.502V403.269H683.964V399.538Z" fill="#373B3D"/>
<path d="M683.964 391.381L680.233 391.346V395.112H683.964V391.381Z" fill="#373B3D"/>
<path d="M675.772 399.538L672.041 399.502V403.269H675.772V399.538Z" fill="#373B3D"/>
<path d="M667.58 399.538L663.849 399.502V403.269H667.58V399.538Z" fill="#373B3D"/>
<path d="M659.388 399.538L655.657 399.502V403.269H659.388V399.538Z" fill="#373B3D"/>
<path d="M651.196 399.538L647.465 399.502V403.269H651.196V399.538Z" fill="#373B3D"/>
<path d="M634.813 399.538L631.082 399.502V403.269H634.813V399.538Z" fill="#373B3D"/>
<path d="M986.989 391.381L983.258 391.346V395.112H986.989V391.381Z" fill="#373B3D"/>
<path d="M1019.76 391.381L1016.03 391.346V395.112H1019.76V391.381Z" fill="#373B3D"/>
<path d="M978.809 391.381L975.078 391.346V395.112H978.809V391.381Z" fill="#373B3D"/>
<path d="M970.617 391.381L966.886 391.346V395.112H970.617V391.381Z" fill="#373B3D"/>
<path d="M962.425 391.381L958.694 391.346V395.112H962.425V391.381Z" fill="#373B3D"/>
<path d="M954.233 391.381L950.502 391.346V395.112H954.233V391.381Z" fill="#373B3D"/>
<path d="M946.042 391.381L942.311 391.346V395.112H946.042V391.381Z" fill="#373B3D"/>
<path d="M937.85 391.381L934.119 391.346V395.112H937.85V391.381Z" fill="#373B3D"/>
<path d="M929.659 391.381L925.928 391.346V395.112H929.659V391.381Z" fill="#373B3D"/>
<path d="M921.478 391.381L917.747 391.346V395.112H921.478V391.381Z" fill="#373B3D"/>
<path d="M913.286 391.381L909.555 391.346V395.112H913.286V391.381Z" fill="#373B3D"/>
<path d="M905.095 391.381L901.364 391.346V395.112H905.095V391.381Z" fill="#373B3D"/>
<path d="M896.903 391.381L893.172 391.346V395.112H896.903V391.381Z" fill="#373B3D"/>
<path d="M888.711 391.381L884.98 391.346V395.112H888.711V391.381Z" fill="#373B3D"/>
<path d="M880.519 391.381L876.788 391.346V395.112H880.519V391.381Z" fill="#373B3D"/>
<path d="M872.328 391.381L868.597 391.346V395.112H872.328V391.381Z" fill="#373B3D"/>
<path d="M864.147 391.381L860.416 391.346V395.112H864.147V391.381Z" fill="#373B3D"/>
<path d="M855.956 391.381L852.225 391.346V395.112H855.956V391.381Z" fill="#373B3D"/>
<path d="M847.764 391.381L844.033 391.346V395.112H847.764V391.381Z" fill="#373B3D"/>
<path d="M839.572 391.381L835.841 391.346V395.112H839.572V391.381Z" fill="#373B3D"/>
<path d="M831.381 391.381L827.65 391.346V395.112H831.381V391.381Z" fill="#373B3D"/>
<path d="M823.189 391.381L819.458 391.346V395.113H823.189V391.381Z" fill="#373B3D"/>
<path d="M814.997 391.381L811.266 391.346V395.113H814.997V391.381Z" fill="#373B3D"/>
<path d="M806.805 391.381L803.074 391.346V395.112H806.805V391.381Z" fill="#373B3D"/>
<path d="M798.625 391.381L794.894 391.346V395.112H798.625V391.381Z" fill="#373B3D"/>
<path d="M790.433 391.381L786.702 391.346V395.112H790.433V391.381Z" fill="#373B3D"/>
<path d="M782.242 391.381L778.51 391.346V395.112H782.242V391.381Z" fill="#373B3D"/>
<path d="M774.05 391.381L770.319 391.346V395.112H774.05V391.381Z" fill="#373B3D"/>
<path d="M765.858 391.381L762.127 391.346V395.112H765.858V391.381Z" fill="#373B3D"/>
<path d="M757.666 391.381L753.935 391.346V395.112H757.666V391.381Z" fill="#373B3D"/>
<path d="M749.474 391.381L745.743 391.346V395.112H749.474V391.381Z" fill="#373B3D"/>
<path d="M741.294 391.381L737.563 391.346V395.112H741.294V391.381Z" fill="#373B3D"/>
<path d="M716.719 391.381L712.988 391.346V395.112H716.719V391.381Z" fill="#373B3D"/>
<path d="M708.527 391.381L704.796 391.346V395.112H708.527V391.381Z" fill="#373B3D"/>
<path d="M700.335 391.381L696.604 391.346V395.112H700.335V391.381Z" fill="#373B3D"/>
<path d="M692.143 391.381L688.412 391.346V395.112H692.143V391.381Z" fill="#373B3D"/>
<path d="M675.772 391.381L672.041 391.346V395.112H675.772V391.381Z" fill="#373B3D"/>
<path d="M667.58 391.381L663.849 391.346V395.112H667.58V391.381Z" fill="#373B3D"/>
<path d="M659.388 391.381L655.657 391.346V395.112H659.388V391.381Z" fill="#373B3D"/>
<path d="M651.196 391.381L647.465 391.346V395.112H651.196V391.381Z" fill="#373B3D"/>
<path d="M643.005 391.381L639.274 391.346V395.112H643.005V391.381Z" fill="#373B3D"/>
<path d="M634.813 391.381L631.082 391.346V395.112H634.813V391.381Z" fill="#373B3D"/>
<path d="M626.633 391.381L622.902 391.346V395.112H626.633V391.381Z" fill="#373B3D"/>
<path d="M626.633 399.538L622.902 399.502V403.269H626.633V399.538Z" fill="#373B3D"/>
<path d="M1036.14 383.225L1032.41 383.189V386.956H1036.14V383.225Z" fill="#373B3D"/>
<path d="M1027.95 383.225L1024.22 383.189V386.956H1027.95V383.225Z" fill="#373B3D"/>
<path d="M1027.95 375.08L1024.22 375.044V378.811H1027.95V375.08Z" fill="#373B3D"/>
<path d="M1003.37 383.225L999.642 383.189V386.956H1003.37V383.225Z" fill="#373B3D"/>
<path d="M995.181 383.225L991.45 383.189V386.956H995.181V383.225Z" fill="#373B3D"/>
<path d="M986.989 383.224L983.258 383.189V386.955H986.989V383.224Z" fill="#373B3D"/>
<path d="M978.809 383.225L975.078 383.189V386.956H978.809V383.225Z" fill="#373B3D"/>
<path d="M970.617 383.225L966.886 383.189V386.956H970.617V383.225Z" fill="#373B3D"/>
<path d="M962.425 383.225L958.694 383.189V386.956H962.425V383.225Z" fill="#373B3D"/>
<path d="M954.233 383.225L950.502 383.189V386.956H954.233V383.225Z" fill="#373B3D"/>
<path d="M946.042 383.225L942.311 383.189V386.956H946.042V383.225Z" fill="#373B3D"/>
<path d="M937.85 383.225L934.119 383.189V386.956H937.85V383.225Z" fill="#373B3D"/>
<path d="M929.659 383.225L925.928 383.189V386.956H929.659V383.225Z" fill="#373B3D"/>
<path d="M921.478 383.225L917.747 383.189V386.956H921.478V383.225Z" fill="#373B3D"/>
<path d="M913.286 383.225L909.555 383.189V386.956H913.286V383.225Z" fill="#373B3D"/>
<path d="M905.095 383.225L901.364 383.189V386.956H905.095V383.225Z" fill="#373B3D"/>
<path d="M896.903 383.225L893.172 383.189V386.956H896.903V383.225Z" fill="#373B3D"/>
<path d="M888.711 383.224L884.98 383.189V386.955H888.711V383.224Z" fill="#373B3D"/>
<path d="M880.519 383.225L876.788 383.189V386.956H880.519V383.225Z" fill="#373B3D"/>
<path d="M872.328 383.225L868.597 383.189V386.956H872.328V383.225Z" fill="#373B3D"/>
<path d="M864.147 383.225L860.416 383.189V386.956H864.147V383.225Z" fill="#373B3D"/>
<path d="M855.956 383.225L852.225 383.189V386.956H855.956V383.225Z" fill="#373B3D"/>
<path d="M847.764 383.225L844.033 383.189V386.956H847.764V383.225Z" fill="#373B3D"/>
<path d="M839.572 383.225L835.841 383.189V386.956H839.572V383.225Z" fill="#373B3D"/>
<path d="M831.381 383.225L827.65 383.189V386.956H831.381V383.225Z" fill="#373B3D"/>
<path d="M823.189 383.225L819.458 383.19V386.956H823.189V383.225Z" fill="#373B3D"/>
<path d="M814.997 383.225L811.266 383.19V386.956H814.997V383.225Z" fill="#373B3D"/>
<path d="M806.805 383.224L803.074 383.189V386.955H806.805V383.224Z" fill="#373B3D"/>
<path d="M798.625 383.225L794.894 383.189V386.956H798.625V383.225Z" fill="#373B3D"/>
<path d="M790.433 383.225L786.702 383.189V386.956H790.433V383.225Z" fill="#373B3D"/>
<path d="M782.242 383.225L778.51 383.189V386.956H782.242V383.225Z" fill="#373B3D"/>
<path d="M774.05 383.225L770.319 383.189V386.956H774.05V383.225Z" fill="#373B3D"/>
<path d="M765.858 383.225L762.127 383.189V386.956H765.858V383.225Z" fill="#373B3D"/>
<path d="M757.666 383.225L753.935 383.189V386.956H757.666V383.225Z" fill="#373B3D"/>
<path d="M749.474 383.225L745.743 383.189V386.956H749.474V383.225Z" fill="#373B3D"/>
<path d="M741.294 383.225L737.563 383.189V386.956H741.294V383.225Z" fill="#373B3D"/>
<path d="M733.103 383.225L729.372 383.189V386.956H733.103V383.225Z" fill="#373B3D"/>
<path d="M708.527 383.225L704.796 383.189V386.956H708.527V383.225Z" fill="#373B3D"/>
<path d="M700.335 383.225L696.604 383.189V386.956H700.335V383.225Z" fill="#373B3D"/>
<path d="M692.143 383.225L688.412 383.189V386.956H692.143V383.225Z" fill="#373B3D"/>
<path d="M651.196 383.225L647.465 383.189V386.956H651.196V383.225Z" fill="#373B3D"/>
<path d="M643.005 383.225L639.274 383.189V386.956H643.005V383.225Z" fill="#373B3D"/>
<path d="M634.813 383.225L631.082 383.189V386.956H634.813V383.225Z" fill="#373B3D"/>
<path d="M626.633 383.225L622.902 383.189V386.956H626.633V383.225Z" fill="#373B3D"/>
<path d="M618.441 383.225L614.71 383.189V386.956H618.441V383.225Z" fill="#373B3D"/>
<path d="M1011.56 375.08L1007.83 375.044V378.811H1011.56V375.08Z" fill="#373B3D"/>
<path d="M1003.37 375.08L999.642 375.044V378.811H1003.37V375.08Z" fill="#373B3D"/>
<path d="M995.181 375.08L991.45 375.044V378.811H995.181V375.08Z" fill="#373B3D"/>
<path d="M986.989 375.08L983.258 375.044V378.811H986.989V375.08Z" fill="#373B3D"/>
<path d="M978.809 375.08L975.078 375.044V378.811H978.809V375.08Z" fill="#373B3D"/>
<path d="M970.617 375.08L966.886 375.044V378.811H970.617V375.08Z" fill="#373B3D"/>
<path d="M962.425 375.08L958.694 375.044V378.811H962.425V375.08Z" fill="#373B3D"/>
<path d="M954.233 375.08L950.502 375.044V378.811H954.233V375.08Z" fill="#373B3D"/>
<path d="M946.042 375.08L942.311 375.044V378.811H946.042V375.08Z" fill="#373B3D"/>
<path d="M937.85 375.08L934.119 375.044V378.811H937.85V375.08Z" fill="#373B3D"/>
<path d="M929.659 375.08L925.928 375.044V378.811H929.659V375.08Z" fill="#373B3D"/>
<path d="M921.478 375.08L917.747 375.044V378.811H921.478V375.08Z" fill="#373B3D"/>
<path d="M913.286 375.08L909.555 375.044V378.811H913.286V375.08Z" fill="#373B3D"/>
<path d="M905.095 375.08L901.364 375.044V378.811H905.095V375.08Z" fill="#373B3D"/>
<path d="M896.903 375.08L893.172 375.044V378.811H896.903V375.08Z" fill="#373B3D"/>
<path d="M888.711 375.08L884.98 375.044V378.811H888.711V375.08Z" fill="#373B3D"/>
<path d="M880.519 375.08L876.788 375.044V378.811H880.519V375.08Z" fill="#373B3D"/>
<path d="M872.328 375.08L868.597 375.044V378.811H872.328V375.08Z" fill="#373B3D"/>
<path d="M864.147 375.08L860.416 375.044V378.811H864.147V375.08Z" fill="#373B3D"/>
<path d="M855.956 375.08L852.225 375.044V378.811H855.956V375.08Z" fill="#373B3D"/>
<path d="M847.764 375.08L844.033 375.044V378.811H847.764V375.08Z" fill="#373B3D"/>
<path d="M839.572 375.08L835.841 375.044V378.811H839.572V375.08Z" fill="#373B3D"/>
<path d="M831.381 375.08L827.65 375.044V378.811H831.381V375.08Z" fill="#373B3D"/>
<path d="M823.189 375.08L819.458 375.045V378.811H823.189V375.08Z" fill="#373B3D"/>
<path d="M814.997 375.08L811.266 375.045V378.811H814.997V375.08Z" fill="#373B3D"/>
<path d="M806.805 375.08L803.074 375.044V378.811H806.805V375.08Z" fill="#373B3D"/>
<path d="M798.625 375.08L794.894 375.044V378.811H798.625V375.08Z" fill="#373B3D"/>
<path d="M790.433 375.08L786.702 375.044V378.811H790.433V375.08Z" fill="#373B3D"/>
<path d="M782.242 375.08L778.51 375.044V378.811H782.242V375.08Z" fill="#373B3D"/>
<path d="M774.05 375.08L770.319 375.044V378.811H774.05V375.08Z" fill="#373B3D"/>
<path d="M765.858 375.08L762.127 375.044V378.811H765.858V375.08Z" fill="#373B3D"/>
<path d="M757.666 375.08L753.935 375.044V378.811H757.666V375.08Z" fill="#373B3D"/>
<path d="M749.474 375.08L745.743 375.044V378.811H749.474V375.08Z" fill="#373B3D"/>
<path d="M741.294 375.08L737.563 375.044V378.811H741.294V375.08Z" fill="#373B3D"/>
<path d="M733.103 375.08L729.372 375.044V378.811H733.103V375.08Z" fill="#373B3D"/>
<path d="M708.527 375.08L704.796 375.044V378.811H708.527V375.08Z" fill="#373B3D"/>
<path d="M700.335 375.08L696.604 375.044V378.811H700.335V375.08Z" fill="#373B3D"/>
<path d="M692.143 375.08L688.412 375.044V378.811H692.143V375.08Z" fill="#373B3D"/>
<path d="M683.964 375.08L680.233 375.044V378.811H683.964V375.08Z" fill="#373B3D"/>
<path d="M675.772 375.08L672.041 375.044V378.811H675.772V375.08Z" fill="#373B3D"/>
<path d="M626.633 375.08L622.902 375.044V378.811H626.633V375.08Z" fill="#373B3D"/>
<path d="M1011.56 366.923L1007.83 366.888V370.655H1011.56V366.923Z" fill="#373B3D"/>
<path d="M1003.37 366.923L999.642 366.888V370.655H1003.37V366.923Z" fill="#373B3D"/>
<path d="M995.181 366.923L991.45 366.888V370.655H995.181V366.923Z" fill="#373B3D"/>
<path d="M986.989 366.924L983.258 366.889V370.655H986.989V366.924Z" fill="#373B3D"/>
<path d="M978.809 366.923L975.078 366.888V370.655H978.809V366.923Z" fill="#373B3D"/>
<path d="M970.617 366.923L966.886 366.888V370.655H970.617V366.923Z" fill="#373B3D"/>
<path d="M962.425 366.923L958.694 366.888V370.655H962.425V366.923Z" fill="#373B3D"/>
<path d="M954.233 366.923L950.502 366.888V370.655H954.233V366.923Z" fill="#373B3D"/>
<path d="M946.042 366.923L942.311 366.888V370.655H946.042V366.923Z" fill="#373B3D"/>
<path d="M937.85 366.923L934.119 366.888V370.655H937.85V366.923Z" fill="#373B3D"/>
<path d="M929.659 366.923L925.928 366.888V370.655H929.659V366.923Z" fill="#373B3D"/>
<path d="M921.478 366.923L917.747 366.888V370.655H921.478V366.923Z" fill="#373B3D"/>
<path d="M913.286 366.923L909.555 366.888V370.655H913.286V366.923Z" fill="#373B3D"/>
<path d="M905.095 366.923L901.364 366.888V370.655H905.095V366.923Z" fill="#373B3D"/>
<path d="M896.903 366.923L893.172 366.888V370.655H896.903V366.923Z" fill="#373B3D"/>
<path d="M888.711 366.924L884.98 366.889V370.655H888.711V366.924Z" fill="#373B3D"/>
<path d="M880.519 366.923L876.788 366.888V370.655H880.519V366.923Z" fill="#373B3D"/>
<path d="M872.328 366.923L868.597 366.888V370.655H872.328V366.923Z" fill="#373B3D"/>
<path d="M864.147 366.923L860.416 366.888V370.655H864.147V366.923Z" fill="#373B3D"/>
<path d="M855.956 366.923L852.225 366.888V370.655H855.956V366.923Z" fill="#373B3D"/>
<path d="M847.764 366.923L844.033 366.888V370.655H847.764V366.923Z" fill="#373B3D"/>
<path d="M839.572 366.923L835.841 366.888V370.655H839.572V366.923Z" fill="#373B3D"/>
<path d="M831.381 366.923L827.65 366.888V370.655H831.381V366.923Z" fill="#373B3D"/>
<path d="M823.189 366.924L819.458 366.889V370.655H823.189V366.924Z" fill="#373B3D"/>
<path d="M814.997 366.924L811.266 366.889V370.655H814.997V366.924Z" fill="#373B3D"/>
<path d="M806.805 366.924L803.074 366.889V370.655H806.805V366.924Z" fill="#373B3D"/>
<path d="M798.625 366.923L794.894 366.888V370.655H798.625V366.923Z" fill="#373B3D"/>
<path d="M790.433 366.923L786.702 366.888V370.655H790.433V366.923Z" fill="#373B3D"/>
<path d="M782.242 366.923L778.51 366.888V370.655H782.242V366.923Z" fill="#373B3D"/>
<path d="M774.05 366.923L770.319 366.888V370.655H774.05V366.923Z" fill="#373B3D"/>
<path d="M765.858 366.923L762.127 366.888V370.655H765.858V366.923Z" fill="#373B3D"/>
<path d="M757.666 366.923L753.935 366.888V370.655H757.666V366.923Z" fill="#373B3D"/>
<path d="M749.474 366.923L745.743 366.888V370.655H749.474V366.923Z" fill="#373B3D"/>
<path d="M741.294 366.923L737.563 366.888V370.655H741.294V366.923Z" fill="#373B3D"/>
<path d="M716.719 366.923L712.988 366.888V370.655H716.719V366.923Z" fill="#373B3D"/>
<path d="M708.527 366.923L704.796 366.888V370.655H708.527V366.923Z" fill="#373B3D"/>
<path d="M700.335 366.923L696.604 366.888V370.655H700.335V366.923Z" fill="#373B3D"/>
<path d="M692.143 366.923L688.412 366.888V370.655H692.143V366.923Z" fill="#373B3D"/>
<path d="M675.772 366.923L672.041 366.888V370.655H675.772V366.923Z" fill="#373B3D"/>
<path d="M667.58 366.923L663.849 366.888V370.655H667.58V366.923Z" fill="#373B3D"/>
<path d="M659.388 366.923L655.657 366.888V370.655H659.388V366.923Z" fill="#373B3D"/>
<path d="M626.633 366.923L622.902 366.888V370.655H626.633V366.923Z" fill="#373B3D"/>
<path d="M618.441 366.923L614.71 366.888V370.655H618.441V366.923Z" fill="#373B3D"/>
<path d="M610.249 366.923L606.518 366.888V370.655H610.249V366.923Z" fill="#373B3D"/>
<path d="M602.057 366.923L598.326 366.888V370.655H602.057V366.923Z" fill="#373B3D"/>
<path d="M1019.76 358.767L1016.03 358.732V362.498H1019.76V358.767Z" fill="#373B3D"/>
<path d="M1011.56 358.767L1007.83 358.732V362.498H1011.56V358.767Z" fill="#373B3D"/>
<path d="M1003.37 358.767L999.642 358.732V362.498H1003.37V358.767Z" fill="#373B3D"/>
<path d="M995.181 358.767L991.45 358.732V362.498H995.181V358.767Z" fill="#373B3D"/>
<path d="M986.989 358.767L983.258 358.731V362.498H986.989V358.767Z" fill="#373B3D"/>
<path d="M978.809 358.767L975.078 358.732V362.498H978.809V358.767Z" fill="#373B3D"/>
<path d="M970.617 358.767L966.886 358.732V362.498H970.617V358.767Z" fill="#373B3D"/>
<path d="M962.425 358.767L958.694 358.732V362.498H962.425V358.767Z" fill="#373B3D"/>
<path d="M954.233 358.767L950.502 358.732V362.498H954.233V358.767Z" fill="#373B3D"/>
<path d="M946.042 358.767L942.311 358.732V362.498H946.042V358.767Z" fill="#373B3D"/>
<path d="M937.85 358.767L934.119 358.732V362.498H937.85V358.767Z" fill="#373B3D"/>
<path d="M929.659 358.767L925.928 358.732V362.498H929.659V358.767Z" fill="#373B3D"/>
<path d="M921.478 358.767L917.747 358.732V362.498H921.478V358.767Z" fill="#373B3D"/>
<path d="M913.286 358.767L909.555 358.732V362.498H913.286V358.767Z" fill="#373B3D"/>
<path d="M905.095 358.767L901.364 358.732V362.498H905.095V358.767Z" fill="#373B3D"/>
<path d="M896.903 358.767L893.172 358.732V362.498H896.903V358.767Z" fill="#373B3D"/>
<path d="M888.711 358.767L884.98 358.731V362.498H888.711V358.767Z" fill="#373B3D"/>
<path d="M880.519 358.767L876.788 358.732V362.498H880.519V358.767Z" fill="#373B3D"/>
<path d="M872.328 358.767L868.597 358.732V362.498H872.328V358.767Z" fill="#373B3D"/>
<path d="M864.147 358.767L860.416 358.732V362.498H864.147V358.767Z" fill="#373B3D"/>
<path d="M855.956 358.767L852.225 358.732V362.498H855.956V358.767Z" fill="#373B3D"/>
<path d="M847.764 358.767L844.033 358.732V362.498H847.764V358.767Z" fill="#373B3D"/>
<path d="M839.572 358.767L835.841 358.732V362.498H839.572V358.767Z" fill="#373B3D"/>
<path d="M831.381 358.767L827.65 358.732V362.498H831.381V358.767Z" fill="#373B3D"/>
<path d="M823.189 358.768L819.458 358.732V362.499H823.189V358.768Z" fill="#373B3D"/>
<path d="M814.997 358.768L811.266 358.732V362.499H814.997V358.768Z" fill="#373B3D"/>
<path d="M806.805 358.767L803.074 358.731V362.498H806.805V358.767Z" fill="#373B3D"/>
<path d="M798.625 358.767L794.894 358.732V362.498H798.625V358.767Z" fill="#373B3D"/>
<path d="M790.433 358.767L786.702 358.732V362.498H790.433V358.767Z" fill="#373B3D"/>
<path d="M782.242 358.767L778.51 358.732V362.498H782.242V358.767Z" fill="#373B3D"/>
<path d="M774.05 358.767L770.319 358.732V362.498H774.05V358.767Z" fill="#373B3D"/>
<path d="M765.858 358.767L762.127 358.732V362.498H765.858V358.767Z" fill="#373B3D"/>
<path d="M757.666 358.767L753.935 358.732V362.498H757.666V358.767Z" fill="#373B3D"/>
<path d="M749.474 358.767L745.743 358.732V362.498H749.474V358.767Z" fill="#373B3D"/>
<path d="M741.294 358.767L737.563 358.732V362.498H741.294V358.767Z" fill="#373B3D"/>
<path d="M733.103 358.767L729.372 358.732V362.498H733.103V358.767Z" fill="#373B3D"/>
<path d="M733.103 366.923L729.372 366.888V370.655H733.103V366.923Z" fill="#373B3D"/>
<path d="M724.911 358.767L721.179 358.732V362.498H724.911V358.767Z" fill="#373B3D"/>
<path d="M716.719 358.767L712.988 358.732V362.498H716.719V358.767Z" fill="#373B3D"/>
<path d="M708.527 358.767L704.796 358.732V362.498H708.527V358.767Z" fill="#373B3D"/>
<path d="M700.335 358.767L696.604 358.732V362.498H700.335V358.767Z" fill="#373B3D"/>
<path d="M692.143 358.767L688.412 358.732V362.498H692.143V358.767Z" fill="#373B3D"/>
<path d="M683.964 358.767L680.233 358.732V362.498H683.964V358.767Z" fill="#373B3D"/>
<path d="M675.772 358.767L672.041 358.732V362.498H675.772V358.767Z" fill="#373B3D"/>
<path d="M667.58 358.767L663.849 358.732V362.498H667.58V358.767Z" fill="#373B3D"/>
<path d="M659.388 358.767L655.657 358.732V362.498H659.388V358.767Z" fill="#373B3D"/>
<path d="M651.196 358.767L647.465 358.732V362.498H651.196V358.767Z" fill="#373B3D"/>
<path d="M643.005 358.767L639.274 358.732V362.498H643.005V358.767Z" fill="#373B3D"/>
<path d="M634.813 358.767L631.082 358.732V362.498H634.813V358.767Z" fill="#373B3D"/>
<path d="M626.633 358.767L622.902 358.732V362.498H626.633V358.767Z" fill="#373B3D"/>
<path d="M618.441 358.767L614.71 358.732V362.498H618.441V358.767Z" fill="#373B3D"/>
<path d="M610.249 358.767L606.518 358.732V362.498H610.249V358.767Z" fill="#373B3D"/>
<path d="M1036.14 350.611L1032.41 350.575V354.342H1036.14V350.611Z" fill="#373B3D"/>
<path d="M1036.14 358.767L1032.41 358.732V362.498H1036.14V358.767Z" fill="#373B3D"/>
<path d="M1019.76 350.611L1016.03 350.575V354.342H1019.76V350.611Z" fill="#373B3D"/>
<path d="M1011.56 350.611L1007.83 350.575V354.342H1011.56V350.611Z" fill="#373B3D"/>
<path d="M1003.37 350.611L999.642 350.575V354.342H1003.37V350.611Z" fill="#373B3D"/>
<path d="M995.181 350.611L991.45 350.575V354.342H995.181V350.611Z" fill="#373B3D"/>
<path d="M986.989 350.611L983.258 350.575V354.342H986.989V350.611Z" fill="#373B3D"/>
<path d="M978.809 350.611L975.078 350.575V354.342H978.809V350.611Z" fill="#373B3D"/>
<path d="M970.617 350.611L966.886 350.575V354.342H970.617V350.611Z" fill="#373B3D"/>
<path d="M962.425 350.611L958.694 350.575V354.342H962.425V350.611Z" fill="#373B3D"/>
<path d="M954.233 350.611L950.502 350.575V354.342H954.233V350.611Z" fill="#373B3D"/>
<path d="M946.042 350.611L942.311 350.575V354.342H946.042V350.611Z" fill="#373B3D"/>
<path d="M937.85 350.611L934.119 350.575V354.342H937.85V350.611Z" fill="#373B3D"/>
<path d="M929.659 350.611L925.928 350.575V354.342H929.659V350.611Z" fill="#373B3D"/>
<path d="M921.478 350.611L917.747 350.575V354.342H921.478V350.611Z" fill="#373B3D"/>
<path d="M913.286 350.611L909.555 350.575V354.342H913.286V350.611Z" fill="#373B3D"/>
<path d="M905.095 350.611L901.364 350.575V354.342H905.095V350.611Z" fill="#373B3D"/>
<path d="M896.903 350.611L893.172 350.575V354.342H896.903V350.611Z" fill="#373B3D"/>
<path d="M888.711 350.611L884.98 350.575V354.342H888.711V350.611Z" fill="#373B3D"/>
<path d="M880.519 350.611L876.788 350.575V354.342H880.519V350.611Z" fill="#373B3D"/>
<path d="M872.328 350.611L868.597 350.575V354.342H872.328V350.611Z" fill="#373B3D"/>
<path d="M864.147 350.611L860.416 350.575V354.342H864.147V350.611Z" fill="#373B3D"/>
<path d="M855.956 350.611L852.225 350.575V354.342H855.956V350.611Z" fill="#373B3D"/>
<path d="M847.764 350.611L844.033 350.575V354.342H847.764V350.611Z" fill="#373B3D"/>
<path d="M839.572 350.611L835.841 350.575V354.342H839.572V350.611Z" fill="#373B3D"/>
<path d="M831.381 350.611L827.65 350.575V354.342H831.381V350.611Z" fill="#373B3D"/>
<path d="M823.189 350.611L819.458 350.576V354.342H823.189V350.611Z" fill="#373B3D"/>
<path d="M814.997 350.611L811.266 350.576V354.342H814.997V350.611Z" fill="#373B3D"/>
<path d="M806.805 350.611L803.074 350.575V354.342H806.805V350.611Z" fill="#373B3D"/>
<path d="M798.625 350.611L794.894 350.575V354.342H798.625V350.611Z" fill="#373B3D"/>
<path d="M790.433 350.611L786.702 350.575V354.342H790.433V350.611Z" fill="#373B3D"/>
<path d="M782.242 350.611L778.51 350.575V354.342H782.242V350.611Z" fill="#373B3D"/>
<path d="M774.05 350.611L770.319 350.575V354.342H774.05V350.611Z" fill="#373B3D"/>
<path d="M765.858 350.611L762.127 350.575V354.342H765.858V350.611Z" fill="#373B3D"/>
<path d="M757.666 350.611L753.935 350.575V354.342H757.666V350.611Z" fill="#373B3D"/>
<path d="M749.474 350.611L745.743 350.575V354.342H749.474V350.611Z" fill="#373B3D"/>
<path d="M741.294 350.611L737.563 350.575V354.342H741.294V350.611Z" fill="#373B3D"/>
<path d="M733.103 350.611L729.372 350.575V354.342H733.103V350.611Z" fill="#373B3D"/>
<path d="M724.911 350.611L721.179 350.575V354.342H724.911V350.611Z" fill="#373B3D"/>
<path d="M716.719 350.611L712.988 350.575V354.342H716.719V350.611Z" fill="#373B3D"/>
<path d="M708.527 350.611L704.796 350.575V354.342H708.527V350.611Z" fill="#373B3D"/>
<path d="M700.335 350.611L696.604 350.575V354.342H700.335V350.611Z" fill="#373B3D"/>
<path d="M692.143 350.611L688.412 350.575V354.342H692.143V350.611Z" fill="#373B3D"/>
<path d="M683.964 350.611L680.233 350.575V354.342H683.964V350.611Z" fill="#373B3D"/>
<path d="M675.772 350.611L672.041 350.575V354.342H675.772V350.611Z" fill="#373B3D"/>
<path d="M667.58 350.611L663.849 350.575V354.342H667.58V350.611Z" fill="#373B3D"/>
<path d="M659.388 350.611L655.657 350.575V354.342H659.388V350.611Z" fill="#373B3D"/>
<path d="M651.196 350.611L647.465 350.575V354.342H651.196V350.611Z" fill="#373B3D"/>
<path d="M643.005 350.611L639.274 350.575V354.342H643.005V350.611Z" fill="#373B3D"/>
<path d="M634.813 350.611L631.082 350.575V354.342H634.813V350.611Z" fill="#373B3D"/>
<path d="M626.633 350.611L622.902 350.575V354.342H626.633V350.611Z" fill="#373B3D"/>
<path d="M618.441 350.611L614.71 350.575V354.342H618.441V350.611Z" fill="#373B3D"/>
<path d="M610.249 350.611L606.518 350.575V354.342H610.249V350.611Z" fill="#373B3D"/>
<path d="M602.057 350.611L598.326 350.575V354.342H602.057V350.611Z" fill="#373B3D"/>
<path d="M577.482 350.611L573.751 350.575V354.342H577.482V350.611Z" fill="#373B3D"/>
<path d="M1027.95 342.466L1024.22 342.431V346.197H1027.95V342.466Z" fill="#373B3D"/>
<path d="M1019.76 342.466L1016.03 342.431V346.197H1019.76V342.466Z" fill="#373B3D"/>
<path d="M1011.56 342.466L1007.83 342.431V346.197H1011.56V342.466Z" fill="#373B3D"/>
<path d="M1003.37 342.466L999.642 342.431V346.197H1003.37V342.466Z" fill="#373B3D"/>
<path d="M995.181 342.466L991.45 342.431V346.197H995.181V342.466Z" fill="#373B3D"/>
<path d="M986.989 342.465L983.258 342.43V346.197H986.989V342.465Z" fill="#373B3D"/>
<path d="M978.809 342.466L975.078 342.431V346.197H978.809V342.466Z" fill="#373B3D"/>
<path d="M970.617 342.466L966.886 342.431V346.197H970.617V342.466Z" fill="#373B3D"/>
<path d="M962.425 342.466L958.694 342.431V346.197H962.425V342.466Z" fill="#373B3D"/>
<path d="M954.233 342.466L950.502 342.431V346.197H954.233V342.466Z" fill="#373B3D"/>
<path d="M946.042 342.466L942.311 342.431V346.197H946.042V342.466Z" fill="#373B3D"/>
<path d="M937.85 342.466L934.119 342.431V346.197H937.85V342.466Z" fill="#373B3D"/>
<path d="M929.659 342.466L925.928 342.431V346.197H929.659V342.466Z" fill="#373B3D"/>
<path d="M921.478 342.466L917.747 342.431V346.197H921.478V342.466Z" fill="#373B3D"/>
<path d="M913.286 342.466L909.555 342.431V346.197H913.286V342.466Z" fill="#373B3D"/>
<path d="M905.095 342.466L901.364 342.431V346.197H905.095V342.466Z" fill="#373B3D"/>
<path d="M896.903 342.466L893.172 342.431V346.197H896.903V342.466Z" fill="#373B3D"/>
<path d="M888.711 342.465L884.98 342.43V346.197H888.711V342.465Z" fill="#373B3D"/>
<path d="M880.519 342.466L876.788 342.431V346.197H880.519V342.466Z" fill="#373B3D"/>
<path d="M872.328 342.466L868.597 342.431V346.197H872.328V342.466Z" fill="#373B3D"/>
<path d="M864.147 342.466L860.416 342.431V346.197H864.147V342.466Z" fill="#373B3D"/>
<path d="M855.956 342.466L852.225 342.431V346.197H855.956V342.466Z" fill="#373B3D"/>
<path d="M847.764 342.466L844.033 342.431V346.197H847.764V342.466Z" fill="#373B3D"/>
<path d="M839.572 342.466L835.841 342.431V346.197H839.572V342.466Z" fill="#373B3D"/>
<path d="M831.381 342.466L827.65 342.431V346.197H831.381V342.466Z" fill="#373B3D"/>
<path d="M823.189 342.466L819.458 342.431V346.198H823.189V342.466Z" fill="#373B3D"/>
<path d="M814.997 342.466L811.266 342.431V346.198H814.997V342.466Z" fill="#373B3D"/>
<path d="M806.805 342.465L803.074 342.43V346.197H806.805V342.465Z" fill="#373B3D"/>
<path d="M798.625 342.466L794.894 342.431V346.197H798.625V342.466Z" fill="#373B3D"/>
<path d="M790.433 342.466L786.702 342.431V346.197H790.433V342.466Z" fill="#373B3D"/>
<path d="M782.242 342.466L778.51 342.431V346.197H782.242V342.466Z" fill="#373B3D"/>
<path d="M774.05 342.466L770.319 342.431V346.197H774.05V342.466Z" fill="#373B3D"/>
<path d="M765.858 342.466L762.127 342.431V346.197H765.858V342.466Z" fill="#373B3D"/>
<path d="M757.666 342.466L753.935 342.431V346.197H757.666V342.466Z" fill="#373B3D"/>
<path d="M749.474 342.466L745.743 342.431V346.197H749.474V342.466Z" fill="#373B3D"/>
<path d="M741.294 342.466L737.563 342.431V346.197H741.294V342.466Z" fill="#373B3D"/>
<path d="M733.103 342.466L729.372 342.431V346.197H733.103V342.466Z" fill="#373B3D"/>
<path d="M724.911 342.466L721.179 342.431V346.197H724.911V342.466Z" fill="#373B3D"/>
<path d="M716.719 342.466L712.988 342.431V346.197H716.719V342.466Z" fill="#373B3D"/>
<path d="M708.527 342.466L704.796 342.431V346.197H708.527V342.466Z" fill="#373B3D"/>
<path d="M700.335 342.466L696.604 342.431V346.197H700.335V342.466Z" fill="#373B3D"/>
<path d="M692.143 342.466L688.412 342.431V346.197H692.143V342.466Z" fill="#373B3D"/>
<path d="M683.964 342.466L680.233 342.431V346.197H683.964V342.466Z" fill="#373B3D"/>
<path d="M675.772 342.466L672.041 342.431V346.197H675.772V342.466Z" fill="#373B3D"/>
<path d="M667.58 342.466L663.849 342.431V346.197H667.58V342.466Z" fill="#373B3D"/>
<path d="M659.388 342.466L655.657 342.431V346.197H659.388V342.466Z" fill="#373B3D"/>
<path d="M651.196 342.466L647.465 342.431V346.197H651.196V342.466Z" fill="#373B3D"/>
<path d="M643.005 342.466L639.274 342.431V346.197H643.005V342.466Z" fill="#373B3D"/>
<path d="M634.813 342.466L631.082 342.431V346.197H634.813V342.466Z" fill="#373B3D"/>
<path d="M626.633 342.466L622.902 342.431V346.197H626.633V342.466Z" fill="#373B3D"/>
<path d="M618.441 342.466L614.71 342.431V346.197H618.441V342.466Z" fill="#373B3D"/>
<path d="M610.249 342.466L606.518 342.431V346.197H610.249V342.466Z" fill="#373B3D"/>
<path d="M577.482 342.466L573.751 342.431V346.197H577.482V342.466Z" fill="#373B3D"/>
<path d="M528.343 342.466L524.612 342.431V346.197H528.343V342.466Z" fill="#373B3D"/>
<path d="M1085.28 334.309L1081.55 334.274V338.04H1085.28V334.309Z" fill="#373B3D"/>
<path d="M1077.09 334.309L1073.36 334.274V338.04H1077.09V334.309Z" fill="#373B3D"/>
<path d="M1077.09 342.466L1073.36 342.431V346.197H1077.09V342.466Z" fill="#373B3D"/>
<path d="M1027.95 334.309L1024.22 334.274V338.04H1027.95V334.309Z" fill="#373B3D"/>
<path d="M1019.76 334.309L1016.03 334.274V338.04H1019.76V334.309Z" fill="#373B3D"/>
<path d="M1011.56 334.309L1007.83 334.274V338.04H1011.56V334.309Z" fill="#373B3D"/>
<path d="M1003.37 334.309L999.642 334.274V338.04H1003.37V334.309Z" fill="#373B3D"/>
<path d="M995.181 334.309L991.45 334.274V338.04H995.181V334.309Z" fill="#373B3D"/>
<path d="M986.989 334.309L983.258 334.274V338.04H986.989V334.309Z" fill="#373B3D"/>
<path d="M978.809 334.309L975.078 334.274V338.04H978.809V334.309Z" fill="#373B3D"/>
<path d="M970.617 334.309L966.886 334.274V338.04H970.617V334.309Z" fill="#373B3D"/>
<path d="M962.425 334.309L958.694 334.274V338.04H962.425V334.309Z" fill="#373B3D"/>
<path d="M954.233 334.309L950.502 334.274V338.04H954.233V334.309Z" fill="#373B3D"/>
<path d="M946.042 334.309L942.311 334.274V338.04H946.042V334.309Z" fill="#373B3D"/>
<path d="M937.85 334.309L934.119 334.274V338.04H937.85V334.309Z" fill="#373B3D"/>
<path d="M929.659 334.309L925.928 334.274V338.04H929.659V334.309Z" fill="#373B3D"/>
<path d="M921.478 334.309L917.747 334.274V338.04H921.478V334.309Z" fill="#373B3D"/>
<path d="M913.286 334.309L909.555 334.274V338.04H913.286V334.309Z" fill="#373B3D"/>
<path d="M905.095 334.309L901.364 334.274V338.04H905.095V334.309Z" fill="#373B3D"/>
<path d="M896.903 334.309L893.172 334.274V338.04H896.903V334.309Z" fill="#373B3D"/>
<path d="M888.711 334.309L884.98 334.274V338.04H888.711V334.309Z" fill="#373B3D"/>
<path d="M880.519 334.309L876.788 334.274V338.04H880.519V334.309Z" fill="#373B3D"/>
<path d="M872.328 334.309L868.597 334.274V338.04H872.328V334.309Z" fill="#373B3D"/>
<path d="M864.147 334.309L860.416 334.274V338.04H864.147V334.309Z" fill="#373B3D"/>
<path d="M855.956 334.309L852.225 334.274V338.04H855.956V334.309Z" fill="#373B3D"/>
<path d="M847.764 334.309L844.033 334.274V338.04H847.764V334.309Z" fill="#373B3D"/>
<path d="M839.572 334.309L835.841 334.274V338.04H839.572V334.309Z" fill="#373B3D"/>
<path d="M831.381 334.309L827.65 334.274V338.04H831.381V334.309Z" fill="#373B3D"/>
<path d="M823.189 334.309L819.458 334.274V338.04H823.189V334.309Z" fill="#373B3D"/>
<path d="M814.997 334.309L811.266 334.274V338.04H814.997V334.309Z" fill="#373B3D"/>
<path d="M806.805 334.309L803.074 334.274V338.04H806.805V334.309Z" fill="#373B3D"/>
<path d="M798.625 334.309L794.894 334.274V338.04H798.625V334.309Z" fill="#373B3D"/>
<path d="M790.433 334.309L786.702 334.274V338.04H790.433V334.309Z" fill="#373B3D"/>
<path d="M782.242 334.309L778.51 334.274V338.04H782.242V334.309Z" fill="#373B3D"/>
<path d="M774.05 334.309L770.319 334.274V338.04H774.05V334.309Z" fill="#373B3D"/>
<path d="M765.858 334.309L762.127 334.274V338.04H765.858V334.309Z" fill="#373B3D"/>
<path d="M757.666 334.309L753.935 334.274V338.04H757.666V334.309Z" fill="#373B3D"/>
<path d="M749.474 334.309L745.743 334.274V338.04H749.474V334.309Z" fill="#373B3D"/>
<path d="M741.294 334.309L737.563 334.274V338.04H741.294V334.309Z" fill="#373B3D"/>
<path d="M733.103 334.309L729.372 334.274V338.04H733.103V334.309Z" fill="#373B3D"/>
<path d="M724.911 334.309L721.179 334.274V338.04H724.911V334.309Z" fill="#373B3D"/>
<path d="M716.719 334.309L712.988 334.274V338.04H716.719V334.309Z" fill="#373B3D"/>
<path d="M708.527 334.309L704.796 334.274V338.04H708.527V334.309Z" fill="#373B3D"/>
<path d="M700.335 334.309L696.604 334.274V338.04H700.335V334.309Z" fill="#373B3D"/>
<path d="M692.143 334.309L688.412 334.274V338.04H692.143V334.309Z" fill="#373B3D"/>
<path d="M683.964 334.309L680.233 334.274V338.04H683.964V334.309Z" fill="#373B3D"/>
<path d="M675.772 334.309L672.041 334.274V338.04H675.772V334.309Z" fill="#373B3D"/>
<path d="M667.58 334.309L663.849 334.274V338.04H667.58V334.309Z" fill="#373B3D"/>
<path d="M659.388 334.309L655.657 334.274V338.04H659.388V334.309Z" fill="#373B3D"/>
<path d="M651.196 334.309L647.465 334.274V338.04H651.196V334.309Z" fill="#373B3D"/>
<path d="M643.005 334.309L639.274 334.274V338.04H643.005V334.309Z" fill="#373B3D"/>
<path d="M634.813 334.309L631.082 334.274V338.04H634.813V334.309Z" fill="#373B3D"/>
<path d="M626.633 334.309L622.902 334.274V338.04H626.633V334.309Z" fill="#373B3D"/>
<path d="M626.633 326.153L622.902 326.118V329.884H626.633V326.153Z" fill="#373B3D"/>
<path d="M618.441 334.309L614.71 334.274V338.04H618.441V334.309Z" fill="#373B3D"/>
<path d="M610.249 334.309L606.518 334.274V338.04H610.249V334.309Z" fill="#373B3D"/>
<path d="M593.865 326.153L590.134 326.118V329.884H593.865V326.153Z" fill="#373B3D"/>
<path d="M577.482 334.309L573.751 334.274V338.04H577.482V334.309Z" fill="#373B3D"/>
<path d="M528.343 334.309L524.612 334.274V338.04H528.343V334.309Z" fill="#373B3D"/>
<path d="M536.535 334.309L532.804 334.274V338.04H536.535V334.309Z" fill="#373B3D"/>
<path d="M1085.28 326.153L1081.55 326.118V329.884H1085.28V326.153Z" fill="#373B3D"/>
<path d="M1093.47 326.153L1089.74 326.118V329.884H1093.47V326.153Z" fill="#373B3D"/>
<path d="M1077.09 326.153L1073.36 326.118V329.884H1077.09V326.153Z" fill="#373B3D"/>
<path d="M1003.37 326.153L999.642 326.118V329.884H1003.37V326.153Z" fill="#373B3D"/>
<path d="M995.181 326.153L991.45 326.118V329.884H995.181V326.153Z" fill="#373B3D"/>
<path d="M986.989 326.153L983.258 326.118V329.884H986.989V326.153Z" fill="#373B3D"/>
<path d="M978.809 326.153L975.078 326.118V329.884H978.809V326.153Z" fill="#373B3D"/>
<path d="M970.617 326.153L966.886 326.118V329.884H970.617V326.153Z" fill="#373B3D"/>
<path d="M962.425 326.153L958.694 326.118V329.884H962.425V326.153Z" fill="#373B3D"/>
<path d="M954.233 326.153L950.502 326.118V329.884H954.233V326.153Z" fill="#373B3D"/>
<path d="M946.042 326.153L942.311 326.118V329.884H946.042V326.153Z" fill="#373B3D"/>
<path d="M937.85 326.153L934.119 326.118V329.884H937.85V326.153Z" fill="#373B3D"/>
<path d="M929.659 326.153L925.928 326.118V329.884H929.659V326.153Z" fill="#373B3D"/>
<path d="M921.478 326.153L917.747 326.118V329.884H921.478V326.153Z" fill="#373B3D"/>
<path d="M913.286 326.153L909.555 326.118V329.884H913.286V326.153Z" fill="#373B3D"/>
<path d="M905.095 326.153L901.364 326.118V329.884H905.095V326.153Z" fill="#373B3D"/>
<path d="M896.903 326.153L893.172 326.118V329.884H896.903V326.153Z" fill="#373B3D"/>
<path d="M888.711 326.153L884.98 326.118V329.884H888.711V326.153Z" fill="#373B3D"/>
<path d="M880.519 326.153L876.788 326.118V329.884H880.519V326.153Z" fill="#373B3D"/>
<path d="M872.328 326.153L868.597 326.118V329.884H872.328V326.153Z" fill="#373B3D"/>
<path d="M864.147 326.153L860.416 326.118V329.884H864.147V326.153Z" fill="#373B3D"/>
<path d="M855.956 326.153L852.225 326.118V329.884H855.956V326.153Z" fill="#373B3D"/>
<path d="M847.764 326.153L844.033 326.118V329.884H847.764V326.153Z" fill="#373B3D"/>
<path d="M839.572 326.153L835.841 326.118V329.884H839.572V326.153Z" fill="#373B3D"/>
<path d="M831.381 326.153L827.65 326.118V329.884H831.381V326.153Z" fill="#373B3D"/>
<path d="M823.189 326.153L819.458 326.118V329.885H823.189V326.153Z" fill="#373B3D"/>
<path d="M814.997 326.153L811.266 326.118V329.885H814.997V326.153Z" fill="#373B3D"/>
<path d="M806.805 326.153L803.074 326.118V329.884H806.805V326.153Z" fill="#373B3D"/>
<path d="M798.625 326.153L794.894 326.118V329.884H798.625V326.153Z" fill="#373B3D"/>
<path d="M790.433 326.153L786.702 326.118V329.884H790.433V326.153Z" fill="#373B3D"/>
<path d="M782.242 326.153L778.51 326.118V329.884H782.242V326.153Z" fill="#373B3D"/>
<path d="M774.05 326.153L770.319 326.118V329.884H774.05V326.153Z" fill="#373B3D"/>
<path d="M765.858 326.153L762.127 326.118V329.884H765.858V326.153Z" fill="#373B3D"/>
<path d="M757.666 326.153L753.935 326.118V329.884H757.666V326.153Z" fill="#373B3D"/>
<path d="M749.474 326.153L745.743 326.118V329.884H749.474V326.153Z" fill="#373B3D"/>
<path d="M741.294 326.153L737.563 326.118V329.884H741.294V326.153Z" fill="#373B3D"/>
<path d="M733.103 326.153L729.372 326.118V329.884H733.103V326.153Z" fill="#373B3D"/>
<path d="M724.911 326.153L721.179 326.118V329.884H724.911V326.153Z" fill="#373B3D"/>
<path d="M716.719 326.153L712.988 326.118V329.884H716.719V326.153Z" fill="#373B3D"/>
<path d="M708.527 326.153L704.796 326.118V329.884H708.527V326.153Z" fill="#373B3D"/>
<path d="M700.335 326.153L696.604 326.118V329.884H700.335V326.153Z" fill="#373B3D"/>
<path d="M692.143 326.153L688.412 326.118V329.884H692.143V326.153Z" fill="#373B3D"/>
<path d="M683.964 326.153L680.233 326.118V329.884H683.964V326.153Z" fill="#373B3D"/>
<path d="M675.772 326.153L672.041 326.118V329.884H675.772V326.153Z" fill="#373B3D"/>
<path d="M667.58 326.153L663.849 326.118V329.884H667.58V326.153Z" fill="#373B3D"/>
<path d="M659.388 326.153L655.657 326.118V329.884H659.388V326.153Z" fill="#373B3D"/>
<path d="M651.196 326.153L647.465 326.118V329.884H651.196V326.153Z" fill="#373B3D"/>
<path d="M643.005 326.153L639.274 326.118V329.884H643.005V326.153Z" fill="#373B3D"/>
<path d="M634.813 326.153L631.082 326.118V329.884H634.813V326.153Z" fill="#373B3D"/>
<path d="M618.441 326.153L614.71 326.118V329.884H618.441V326.153Z" fill="#373B3D"/>
<path d="M528.343 326.153L524.612 326.118V329.884H528.343V326.153Z" fill="#373B3D"/>
<path d="M1093.47 318.008L1089.74 317.973V321.739H1093.47V318.008Z" fill="#373B3D"/>
<path d="M1085.28 318.008L1081.55 317.973V321.739H1085.28V318.008Z" fill="#373B3D"/>
<path d="M1077.09 318.008L1073.36 317.973V321.739H1077.09V318.008Z" fill="#373B3D"/>
<path d="M1003.37 318.008L999.642 317.973V321.739H1003.37V318.008Z" fill="#373B3D"/>
<path d="M995.181 318.008L991.45 317.973V321.739H995.181V318.008Z" fill="#373B3D"/>
<path d="M986.989 318.008L983.258 317.973V321.739H986.989V318.008Z" fill="#373B3D"/>
<path d="M978.809 318.008L975.078 317.973V321.739H978.809V318.008Z" fill="#373B3D"/>
<path d="M970.617 318.008L966.886 317.973V321.739H970.617V318.008Z" fill="#373B3D"/>
<path d="M962.425 318.008L958.694 317.973V321.739H962.425V318.008Z" fill="#373B3D"/>
<path d="M954.233 318.008L950.502 317.973V321.739H954.233V318.008Z" fill="#373B3D"/>
<path d="M946.042 318.008L942.311 317.973V321.739H946.042V318.008Z" fill="#373B3D"/>
<path d="M937.85 318.008L934.119 317.973V321.739H937.85V318.008Z" fill="#373B3D"/>
<path d="M929.659 318.008L925.928 317.973V321.739H929.659V318.008Z" fill="#373B3D"/>
<path d="M921.478 318.008L917.747 317.973V321.739H921.478V318.008Z" fill="#373B3D"/>
<path d="M913.286 318.008L909.555 317.973V321.739H913.286V318.008Z" fill="#373B3D"/>
<path d="M905.095 318.008L901.364 317.973V321.739H905.095V318.008Z" fill="#373B3D"/>
<path d="M896.903 318.008L893.172 317.973V321.739H896.903V318.008Z" fill="#373B3D"/>
<path d="M888.711 318.008L884.98 317.973V321.739H888.711V318.008Z" fill="#373B3D"/>
<path d="M880.519 318.008L876.788 317.973V321.739H880.519V318.008Z" fill="#373B3D"/>
<path d="M872.328 318.008L868.597 317.973V321.739H872.328V318.008Z" fill="#373B3D"/>
<path d="M864.147 318.008L860.416 317.973V321.739H864.147V318.008Z" fill="#373B3D"/>
<path d="M855.956 318.008L852.225 317.973V321.739H855.956V318.008Z" fill="#373B3D"/>
<path d="M847.764 318.008L844.033 317.973V321.739H847.764V318.008Z" fill="#373B3D"/>
<path d="M839.572 318.008L835.841 317.973V321.739H839.572V318.008Z" fill="#373B3D"/>
<path d="M831.381 318.008L827.65 317.973V321.739H831.381V318.008Z" fill="#373B3D"/>
<path d="M823.189 318.009L819.458 317.974V321.74H823.189V318.009Z" fill="#373B3D"/>
<path d="M814.997 318.009L811.266 317.974V321.74H814.997V318.009Z" fill="#373B3D"/>
<path d="M806.805 318.008L803.074 317.973V321.739H806.805V318.008Z" fill="#373B3D"/>
<path d="M798.625 318.008L794.894 317.973V321.739H798.625V318.008Z" fill="#373B3D"/>
<path d="M790.433 318.008L786.702 317.973V321.739H790.433V318.008Z" fill="#373B3D"/>
<path d="M782.242 318.008L778.51 317.973V321.739H782.242V318.008Z" fill="#373B3D"/>
<path d="M774.05 318.008L770.319 317.973V321.739H774.05V318.008Z" fill="#373B3D"/>
<path d="M765.858 318.008L762.127 317.973V321.739H765.858V318.008Z" fill="#373B3D"/>
<path d="M757.666 318.008L753.935 317.973V321.739H757.666V318.008Z" fill="#373B3D"/>
<path d="M749.474 318.008L745.743 317.973V321.739H749.474V318.008Z" fill="#373B3D"/>
<path d="M741.294 318.008L737.563 317.973V321.739H741.294V318.008Z" fill="#373B3D"/>
<path d="M733.103 318.008L729.372 317.973V321.739H733.103V318.008Z" fill="#373B3D"/>
<path d="M724.911 318.008L721.179 317.973V321.739H724.911V318.008Z" fill="#373B3D"/>
<path d="M716.719 318.008L712.988 317.973V321.739H716.719V318.008Z" fill="#373B3D"/>
<path d="M708.527 318.008L704.796 317.973V321.739H708.527V318.008Z" fill="#373B3D"/>
<path d="M700.335 318.008L696.604 317.973V321.739H700.335V318.008Z" fill="#373B3D"/>
<path d="M692.143 318.008L688.412 317.973V321.739H692.143V318.008Z" fill="#373B3D"/>
<path d="M683.964 318.008L680.233 317.973V321.739H683.964V318.008Z" fill="#373B3D"/>
<path d="M675.772 318.008L672.041 317.973V321.739H675.772V318.008Z" fill="#373B3D"/>
<path d="M667.58 318.008L663.849 317.973V321.739H667.58V318.008Z" fill="#373B3D"/>
<path d="M659.388 318.008L655.657 317.973V321.739H659.388V318.008Z" fill="#373B3D"/>
<path d="M651.196 318.008L647.465 317.973V321.739H651.196V318.008Z" fill="#373B3D"/>
<path d="M643.005 318.008L639.274 317.973V321.739H643.005V318.008Z" fill="#373B3D"/>
<path d="M634.813 318.008L631.082 317.973V321.739H634.813V318.008Z" fill="#373B3D"/>
<path d="M602.057 318.008L598.326 317.973V321.739H602.057V318.008Z" fill="#373B3D"/>
<path d="M585.674 318.008L581.943 317.973V321.739H585.674V318.008Z" fill="#373B3D"/>
<path d="M1093.47 309.852L1089.74 309.816V313.583H1093.47V309.852Z" fill="#373B3D"/>
<path d="M1085.28 309.852L1081.55 309.816V313.583H1085.28V309.852Z" fill="#373B3D"/>
<path d="M1077.09 309.852L1073.36 309.816V313.583H1077.09V309.852Z" fill="#373B3D"/>
<path d="M1011.56 309.852L1007.83 309.816V313.583H1011.56V309.852Z" fill="#373B3D"/>
<path d="M1011.56 318.008L1007.83 317.973V321.739H1011.56V318.008Z" fill="#373B3D"/>
<path d="M1003.37 309.852L999.642 309.816V313.583H1003.37V309.852Z" fill="#373B3D"/>
<path d="M995.181 309.852L991.45 309.816V313.583H995.181V309.852Z" fill="#373B3D"/>
<path d="M986.989 309.852L983.258 309.816V313.583H986.989V309.852Z" fill="#373B3D"/>
<path d="M978.809 309.852L975.078 309.816V313.583H978.809V309.852Z" fill="#373B3D"/>
<path d="M970.617 309.852L966.886 309.816V313.583H970.617V309.852Z" fill="#373B3D"/>
<path d="M962.425 309.852L958.694 309.816V313.583H962.425V309.852Z" fill="#373B3D"/>
<path d="M954.233 309.852L950.502 309.816V313.583H954.233V309.852Z" fill="#373B3D"/>
<path d="M946.042 309.852L942.311 309.816V313.583H946.042V309.852Z" fill="#373B3D"/>
<path d="M937.85 309.852L934.119 309.816V313.583H937.85V309.852Z" fill="#373B3D"/>
<path d="M929.659 309.852L925.928 309.816V313.583H929.659V309.852Z" fill="#373B3D"/>
<path d="M921.478 309.852L917.747 309.816V313.583H921.478V309.852Z" fill="#373B3D"/>
<path d="M913.286 309.852L909.555 309.816V313.583H913.286V309.852Z" fill="#373B3D"/>
<path d="M905.095 309.852L901.364 309.816V313.583H905.095V309.852Z" fill="#373B3D"/>
<path d="M896.903 309.852L893.172 309.816V313.583H896.903V309.852Z" fill="#373B3D"/>
<path d="M888.711 309.852L884.98 309.816V313.583H888.711V309.852Z" fill="#373B3D"/>
<path d="M880.519 309.852L876.788 309.816V313.583H880.519V309.852Z" fill="#373B3D"/>
<path d="M872.328 309.852L868.597 309.816V313.583H872.328V309.852Z" fill="#373B3D"/>
<path d="M864.147 309.852L860.416 309.816V313.583H864.147V309.852Z" fill="#373B3D"/>
<path d="M855.956 309.852L852.225 309.816V313.583H855.956V309.852Z" fill="#373B3D"/>
<path d="M847.764 309.852L844.033 309.816V313.583H847.764V309.852Z" fill="#373B3D"/>
<path d="M839.572 309.852L835.841 309.816V313.583H839.572V309.852Z" fill="#373B3D"/>
<path d="M831.381 309.852L827.65 309.816V313.583H831.381V309.852Z" fill="#373B3D"/>
<path d="M823.189 309.852L819.458 309.817V313.583H823.189V309.852Z" fill="#373B3D"/>
<path d="M814.997 309.852L811.266 309.817V313.583H814.997V309.852Z" fill="#373B3D"/>
<path d="M806.805 309.852L803.074 309.816V313.583H806.805V309.852Z" fill="#373B3D"/>
<path d="M798.625 309.852L794.894 309.816V313.583H798.625V309.852Z" fill="#373B3D"/>
<path d="M790.433 309.852L786.702 309.816V313.583H790.433V309.852Z" fill="#373B3D"/>
<path d="M782.242 309.852L778.51 309.816V313.583H782.242V309.852Z" fill="#373B3D"/>
<path d="M774.05 309.852L770.319 309.816V313.583H774.05V309.852Z" fill="#373B3D"/>
<path d="M765.858 309.852L762.127 309.816V313.583H765.858V309.852Z" fill="#373B3D"/>
<path d="M757.666 309.852L753.935 309.816V313.583H757.666V309.852Z" fill="#373B3D"/>
<path d="M749.474 309.852L745.743 309.816V313.583H749.474V309.852Z" fill="#373B3D"/>
<path d="M741.294 309.852L737.563 309.816V313.583H741.294V309.852Z" fill="#373B3D"/>
<path d="M733.103 309.852L729.372 309.816V313.583H733.103V309.852Z" fill="#373B3D"/>
<path d="M724.911 309.852L721.179 309.816V313.583H724.911V309.852Z" fill="#373B3D"/>
<path d="M716.719 309.852L712.988 309.816V313.583H716.719V309.852Z" fill="#373B3D"/>
<path d="M708.527 309.852L704.796 309.816V313.583H708.527V309.852Z" fill="#373B3D"/>
<path d="M700.335 309.852L696.604 309.816V313.583H700.335V309.852Z" fill="#373B3D"/>
<path d="M692.143 309.852L688.412 309.816V313.583H692.143V309.852Z" fill="#373B3D"/>
<path d="M683.964 309.852L680.233 309.816V313.583H683.964V309.852Z" fill="#373B3D"/>
<path d="M675.772 309.852L672.041 309.816V313.583H675.772V309.852Z" fill="#373B3D"/>
<path d="M667.58 309.852L663.849 309.816V313.583H667.58V309.852Z" fill="#373B3D"/>
<path d="M659.388 309.852L655.657 309.816V313.583H659.388V309.852Z" fill="#373B3D"/>
<path d="M651.196 309.852L647.465 309.816V313.583H651.196V309.852Z" fill="#373B3D"/>
<path d="M643.005 309.852L639.274 309.816V313.583H643.005V309.852Z" fill="#373B3D"/>
<path d="M634.813 309.852L631.082 309.816V313.583H634.813V309.852Z" fill="#373B3D"/>
<path d="M618.441 309.852L614.71 309.816V313.583H618.441V309.852Z" fill="#373B3D"/>
<path d="M610.249 309.852L606.518 309.816V313.583H610.249V309.852Z" fill="#373B3D"/>
<path d="M602.057 309.852L598.326 309.816V313.583H602.057V309.852Z" fill="#373B3D"/>
<path d="M1093.47 301.695L1089.74 301.66V305.426H1093.47V301.695Z" fill="#373B3D"/>
<path d="M1085.28 301.695L1081.55 301.66V305.426H1085.28V301.695Z" fill="#373B3D"/>
<path d="M1019.76 301.695L1016.03 301.66V305.426H1019.76V301.695Z" fill="#373B3D"/>
<path d="M1011.56 301.695L1007.83 301.66V305.426H1011.56V301.695Z" fill="#373B3D"/>
<path d="M1003.37 301.695L999.642 301.66V305.426H1003.37V301.695Z" fill="#373B3D"/>
<path d="M995.181 301.695L991.45 301.66V305.426H995.181V301.695Z" fill="#373B3D"/>
<path d="M986.989 301.695L983.258 301.66V305.427H986.989V301.695Z" fill="#373B3D"/>
<path d="M978.809 301.695L975.078 301.66V305.426H978.809V301.695Z" fill="#373B3D"/>
<path d="M970.617 301.695L966.886 301.66V305.426H970.617V301.695Z" fill="#373B3D"/>
<path d="M962.425 301.695L958.694 301.66V305.426H962.425V301.695Z" fill="#373B3D"/>
<path d="M954.233 301.695L950.502 301.66V305.426H954.233V301.695Z" fill="#373B3D"/>
<path d="M946.042 301.695L942.311 301.66V305.426H946.042V301.695Z" fill="#373B3D"/>
<path d="M937.85 301.695L934.119 301.66V305.426H937.85V301.695Z" fill="#373B3D"/>
<path d="M929.659 301.695L925.928 301.66V305.426H929.659V301.695Z" fill="#373B3D"/>
<path d="M921.478 301.695L917.747 301.66V305.426H921.478V301.695Z" fill="#373B3D"/>
<path d="M913.286 301.695L909.555 301.66V305.426H913.286V301.695Z" fill="#373B3D"/>
<path d="M905.095 301.695L901.364 301.66V305.426H905.095V301.695Z" fill="#373B3D"/>
<path d="M896.903 301.695L893.172 301.66V305.426H896.903V301.695Z" fill="#373B3D"/>
<path d="M888.711 301.695L884.98 301.66V305.427H888.711V301.695Z" fill="#373B3D"/>
<path d="M880.519 301.695L876.788 301.66V305.426H880.519V301.695Z" fill="#373B3D"/>
<path d="M872.328 301.695L868.597 301.66V305.426H872.328V301.695Z" fill="#373B3D"/>
<path d="M864.147 301.695L860.416 301.66V305.426H864.147V301.695Z" fill="#373B3D"/>
<path d="M855.956 301.695L852.225 301.66V305.426H855.956V301.695Z" fill="#373B3D"/>
<path d="M847.764 301.695L844.033 301.66V305.426H847.764V301.695Z" fill="#373B3D"/>
<path d="M839.572 301.695L835.841 301.66V305.426H839.572V301.695Z" fill="#373B3D"/>
<path d="M831.381 301.695L827.65 301.66V305.426H831.381V301.695Z" fill="#373B3D"/>
<path d="M823.189 301.695L819.458 301.66V305.426H823.189V301.695Z" fill="#373B3D"/>
<path d="M814.997 301.695L811.266 301.66V305.426H814.997V301.695Z" fill="#373B3D"/>
<path d="M806.805 301.695L803.074 301.66V305.427H806.805V301.695Z" fill="#373B3D"/>
<path d="M798.625 301.695L794.894 301.66V305.426H798.625V301.695Z" fill="#373B3D"/>
<path d="M790.433 301.695L786.702 301.66V305.426H790.433V301.695Z" fill="#373B3D"/>
<path d="M782.242 301.695L778.51 301.66V305.426H782.242V301.695Z" fill="#373B3D"/>
<path d="M774.05 301.695L770.319 301.66V305.426H774.05V301.695Z" fill="#373B3D"/>
<path d="M765.858 301.695L762.127 301.66V305.426H765.858V301.695Z" fill="#373B3D"/>
<path d="M757.666 301.695L753.935 301.66V305.426H757.666V301.695Z" fill="#373B3D"/>
<path d="M749.474 301.695L745.743 301.66V305.426H749.474V301.695Z" fill="#373B3D"/>
<path d="M741.294 301.695L737.563 301.66V305.426H741.294V301.695Z" fill="#373B3D"/>
<path d="M733.103 301.695L729.372 301.66V305.426H733.103V301.695Z" fill="#373B3D"/>
<path d="M724.911 301.695L721.179 301.66V305.426H724.911V301.695Z" fill="#373B3D"/>
<path d="M716.719 301.695L712.988 301.66V305.426H716.719V301.695Z" fill="#373B3D"/>
<path d="M708.527 301.695L704.796 301.66V305.426H708.527V301.695Z" fill="#373B3D"/>
<path d="M700.335 301.695L696.604 301.66V305.426H700.335V301.695Z" fill="#373B3D"/>
<path d="M692.143 301.695L688.412 301.66V305.426H692.143V301.695Z" fill="#373B3D"/>
<path d="M683.964 301.695L680.233 301.66V305.426H683.964V301.695Z" fill="#373B3D"/>
<path d="M675.772 301.695L672.041 301.66V305.426H675.772V301.695Z" fill="#373B3D"/>
<path d="M667.58 301.695L663.849 301.66V305.426H667.58V301.695Z" fill="#373B3D"/>
<path d="M659.388 301.695L655.657 301.66V305.426H659.388V301.695Z" fill="#373B3D"/>
<path d="M651.196 301.695L647.465 301.66V305.426H651.196V301.695Z" fill="#373B3D"/>
<path d="M643.005 301.695L639.274 301.66V305.426H643.005V301.695Z" fill="#373B3D"/>
<path d="M610.249 301.695L606.518 301.66V305.426H610.249V301.695Z" fill="#373B3D"/>
<path d="M602.057 301.695L598.326 301.66V305.426H602.057V301.695Z" fill="#373B3D"/>
<path d="M1093.47 293.55L1089.74 293.515V297.281H1093.47V293.55Z" fill="#373B3D"/>
<path d="M1068.9 293.55L1065.16 293.515V297.281H1068.9V293.55Z" fill="#373B3D"/>
<path d="M1060.7 293.55L1056.97 293.515V297.281H1060.7V293.55Z" fill="#373B3D"/>
<path d="M1052.51 293.55L1048.78 293.515V297.281H1052.51V293.55Z" fill="#373B3D"/>
<path d="M1044.32 293.55L1040.59 293.515V297.281H1044.32V293.55Z" fill="#373B3D"/>
<path d="M1036.14 293.55L1032.41 293.515V297.281H1036.14V293.55Z" fill="#373B3D"/>
<path d="M1027.95 293.55L1024.22 293.515V297.281H1027.95V293.55Z" fill="#373B3D"/>
<path d="M1019.76 293.55L1016.03 293.515V297.281H1019.76V293.55Z" fill="#373B3D"/>
<path d="M1011.56 293.55L1007.83 293.515V297.281H1011.56V293.55Z" fill="#373B3D"/>
<path d="M1003.37 293.55L999.642 293.515V297.281H1003.37V293.55Z" fill="#373B3D"/>
<path d="M995.181 293.55L991.45 293.515V297.281H995.181V293.55Z" fill="#373B3D"/>
<path d="M986.989 293.55L983.258 293.515V297.281H986.989V293.55Z" fill="#373B3D"/>
<path d="M978.809 293.55L975.078 293.515V297.281H978.809V293.55Z" fill="#373B3D"/>
<path d="M970.617 293.55L966.886 293.515V297.281H970.617V293.55Z" fill="#373B3D"/>
<path d="M962.425 293.55L958.694 293.515V297.281H962.425V293.55Z" fill="#373B3D"/>
<path d="M954.233 293.55L950.502 293.515V297.281H954.233V293.55Z" fill="#373B3D"/>
<path d="M946.042 293.55L942.311 293.515V297.281H946.042V293.55Z" fill="#373B3D"/>
<path d="M937.85 293.55L934.119 293.515V297.281H937.85V293.55Z" fill="#373B3D"/>
<path d="M929.659 293.55L925.928 293.515V297.281H929.659V293.55Z" fill="#373B3D"/>
<path d="M921.478 293.55L917.747 293.515V297.281H921.478V293.55Z" fill="#373B3D"/>
<path d="M913.286 293.55L909.555 293.515V297.281H913.286V293.55Z" fill="#373B3D"/>
<path d="M905.095 293.55L901.364 293.515V297.281H905.095V293.55Z" fill="#373B3D"/>
<path d="M896.903 293.55L893.172 293.515V297.281H896.903V293.55Z" fill="#373B3D"/>
<path d="M888.711 293.55L884.98 293.515V297.281H888.711V293.55Z" fill="#373B3D"/>
<path d="M880.519 293.55L876.788 293.515V297.281H880.519V293.55Z" fill="#373B3D"/>
<path d="M872.328 293.55L868.597 293.515V297.281H872.328V293.55Z" fill="#373B3D"/>
<path d="M864.147 293.55L860.416 293.515V297.281H864.147V293.55Z" fill="#373B3D"/>
<path d="M855.956 293.55L852.225 293.515V297.281H855.956V293.55Z" fill="#373B3D"/>
<path d="M847.764 293.55L844.033 293.515V297.281H847.764V293.55Z" fill="#373B3D"/>
<path d="M839.572 293.55L835.841 293.515V297.281H839.572V293.55Z" fill="#373B3D"/>
<path d="M831.381 293.55L827.65 293.515V297.281H831.381V293.55Z" fill="#373B3D"/>
<path d="M823.189 293.55L819.458 293.515V297.281H823.189V293.55Z" fill="#373B3D"/>
<path d="M814.997 293.55L811.266 293.515V297.281H814.997V293.55Z" fill="#373B3D"/>
<path d="M806.805 293.55L803.074 293.515V297.281H806.805V293.55Z" fill="#373B3D"/>
<path d="M798.625 293.55L794.894 293.515V297.281H798.625V293.55Z" fill="#373B3D"/>
<path d="M790.433 293.55L786.702 293.515V297.281H790.433V293.55Z" fill="#373B3D"/>
<path d="M782.242 293.55L778.51 293.515V297.281H782.242V293.55Z" fill="#373B3D"/>
<path d="M774.05 293.55L770.319 293.515V297.281H774.05V293.55Z" fill="#373B3D"/>
<path d="M765.858 293.55L762.127 293.515V297.281H765.858V293.55Z" fill="#373B3D"/>
<path d="M757.666 293.55L753.935 293.515V297.281H757.666V293.55Z" fill="#373B3D"/>
<path d="M749.474 293.55L745.743 293.515V297.281H749.474V293.55Z" fill="#373B3D"/>
<path d="M741.294 293.55L737.563 293.515V297.281H741.294V293.55Z" fill="#373B3D"/>
<path d="M733.103 293.55L729.372 293.515V297.281H733.103V293.55Z" fill="#373B3D"/>
<path d="M724.911 293.55L721.179 293.515V297.281H724.911V293.55Z" fill="#373B3D"/>
<path d="M716.719 293.55L712.988 293.515V297.281H716.719V293.55Z" fill="#373B3D"/>
<path d="M708.527 293.55L704.796 293.515V297.281H708.527V293.55Z" fill="#373B3D"/>
<path d="M700.335 293.55L696.604 293.515V297.281H700.335V293.55Z" fill="#373B3D"/>
<path d="M692.143 293.55L688.412 293.515V297.281H692.143V293.55Z" fill="#373B3D"/>
<path d="M683.964 293.55L680.233 293.515V297.281H683.964V293.55Z" fill="#373B3D"/>
<path d="M675.772 293.55L672.041 293.515V297.281H675.772V293.55Z" fill="#373B3D"/>
<path d="M667.58 293.55L663.849 293.515V297.281H667.58V293.55Z" fill="#373B3D"/>
<path d="M659.388 293.55L655.657 293.515V297.281H659.388V293.55Z" fill="#373B3D"/>
<path d="M618.441 293.55L614.71 293.515V297.281H618.441V293.55Z" fill="#373B3D"/>
<path d="M610.249 293.55L606.518 293.515V297.281H610.249V293.55Z" fill="#373B3D"/>
<path d="M602.057 293.55L598.326 293.515V297.281H602.057V293.55Z" fill="#373B3D"/>
<path d="M413.682 293.55L409.951 293.515V297.281H413.682V293.55Z" fill="#373B3D"/>
<path d="M1126.23 285.394L1122.49 285.359V289.125H1126.23V285.394Z" fill="#373B3D"/>
<path d="M1118.03 285.394L1114.3 285.359V289.125H1118.03V285.394Z" fill="#373B3D"/>
<path d="M1109.84 285.394L1106.11 285.359V289.125H1109.84V285.394Z" fill="#373B3D"/>
<path d="M1101.66 285.394L1097.93 285.359V289.125H1101.66V285.394Z" fill="#373B3D"/>
<path d="M1101.66 293.55L1097.93 293.515V297.281H1101.66V293.55Z" fill="#373B3D"/>
<path d="M1093.47 285.394L1089.74 285.359V289.125H1093.47V285.394Z" fill="#373B3D"/>
<path d="M1068.9 285.394L1065.16 285.359V289.125H1068.9V285.394Z" fill="#373B3D"/>
<path d="M1060.7 285.394L1056.97 285.359V289.125H1060.7V285.394Z" fill="#373B3D"/>
<path d="M1052.51 285.394L1048.78 285.359V289.125H1052.51V285.394Z" fill="#373B3D"/>
<path d="M1044.32 285.394L1040.59 285.359V289.125H1044.32V285.394Z" fill="#373B3D"/>
<path d="M1036.14 285.394L1032.41 285.359V289.125H1036.14V285.394Z" fill="#373B3D"/>
<path d="M1027.95 285.394L1024.22 285.359V289.125H1027.95V285.394Z" fill="#373B3D"/>
<path d="M1019.76 285.394L1016.03 285.359V289.125H1019.76V285.394Z" fill="#373B3D"/>
<path d="M1011.56 285.394L1007.83 285.359V289.125H1011.56V285.394Z" fill="#373B3D"/>
<path d="M1003.37 285.394L999.642 285.359V289.125H1003.37V285.394Z" fill="#373B3D"/>
<path d="M995.181 285.394L991.45 285.359V289.125H995.181V285.394Z" fill="#373B3D"/>
<path d="M986.989 285.394L983.258 285.359V289.125H986.989V285.394Z" fill="#373B3D"/>
<path d="M978.809 285.394L975.078 285.359V289.125H978.809V285.394Z" fill="#373B3D"/>
<path d="M970.617 285.394L966.886 285.359V289.125H970.617V285.394Z" fill="#373B3D"/>
<path d="M962.425 285.394L958.694 285.359V289.125H962.425V285.394Z" fill="#373B3D"/>
<path d="M954.233 285.394L950.502 285.359V289.125H954.233V285.394Z" fill="#373B3D"/>
<path d="M946.042 285.394L942.311 285.359V289.125H946.042V285.394Z" fill="#373B3D"/>
<path d="M937.85 285.394L934.119 285.359V289.125H937.85V285.394Z" fill="#373B3D"/>
<path d="M929.659 285.394L925.928 285.359V289.125H929.659V285.394Z" fill="#373B3D"/>
<path d="M921.478 285.394L917.747 285.359V289.125H921.478V285.394Z" fill="#373B3D"/>
<path d="M913.286 285.394L909.555 285.359V289.125H913.286V285.394Z" fill="#373B3D"/>
<path d="M905.095 285.394L901.364 285.359V289.125H905.095V285.394Z" fill="#373B3D"/>
<path d="M896.903 285.394L893.172 285.359V289.125H896.903V285.394Z" fill="#373B3D"/>
<path d="M888.711 285.394L884.98 285.359V289.125H888.711V285.394Z" fill="#373B3D"/>
<path d="M880.519 285.394L876.788 285.359V289.125H880.519V285.394Z" fill="#373B3D"/>
<path d="M872.328 285.394L868.597 285.359V289.125H872.328V285.394Z" fill="#373B3D"/>
<path d="M864.147 285.394L860.416 285.359V289.125H864.147V285.394Z" fill="#373B3D"/>
<path d="M855.956 285.394L852.225 285.359V289.125H855.956V285.394Z" fill="#373B3D"/>
<path d="M847.764 285.394L844.033 285.359V289.125H847.764V285.394Z" fill="#373B3D"/>
<path d="M839.572 285.394L835.841 285.359V289.125H839.572V285.394Z" fill="#373B3D"/>
<path d="M831.381 285.394L827.65 285.359V289.125H831.381V285.394Z" fill="#373B3D"/>
<path d="M823.189 285.394L819.458 285.359V289.125H823.189V285.394Z" fill="#373B3D"/>
<path d="M814.997 285.394L811.266 285.359V289.125H814.997V285.394Z" fill="#373B3D"/>
<path d="M806.805 285.394L803.074 285.359V289.125H806.805V285.394Z" fill="#373B3D"/>
<path d="M798.625 285.394L794.894 285.359V289.125H798.625V285.394Z" fill="#373B3D"/>
<path d="M790.433 285.394L786.702 285.359V289.125H790.433V285.394Z" fill="#373B3D"/>
<path d="M782.242 285.394L778.51 285.359V289.125H782.242V285.394Z" fill="#373B3D"/>
<path d="M774.05 285.394L770.319 285.359V289.125H774.05V285.394Z" fill="#373B3D"/>
<path d="M765.858 285.394L762.127 285.359V289.125H765.858V285.394Z" fill="#373B3D"/>
<path d="M757.666 285.394L753.935 285.359V289.125H757.666V285.394Z" fill="#373B3D"/>
<path d="M749.474 285.394L745.743 285.359V289.125H749.474V285.394Z" fill="#373B3D"/>
<path d="M741.294 285.394L737.563 285.359V289.125H741.294V285.394Z" fill="#373B3D"/>
<path d="M733.103 285.394L729.372 285.359V289.125H733.103V285.394Z" fill="#373B3D"/>
<path d="M724.911 285.394L721.179 285.359V289.125H724.911V285.394Z" fill="#373B3D"/>
<path d="M716.719 285.394L712.988 285.359V289.125H716.719V285.394Z" fill="#373B3D"/>
<path d="M708.527 285.394L704.796 285.359V289.125H708.527V285.394Z" fill="#373B3D"/>
<path d="M700.335 285.394L696.604 285.359V289.125H700.335V285.394Z" fill="#373B3D"/>
<path d="M692.143 285.394L688.412 285.359V289.125H692.143V285.394Z" fill="#373B3D"/>
<path d="M683.964 285.394L680.233 285.359V289.125H683.964V285.394Z" fill="#373B3D"/>
<path d="M675.772 285.394L672.041 285.359V289.125H675.772V285.394Z" fill="#373B3D"/>
<path d="M667.58 285.394L663.849 285.359V289.125H667.58V285.394Z" fill="#373B3D"/>
<path d="M659.388 285.394L655.657 285.359V289.125H659.388V285.394Z" fill="#373B3D"/>
<path d="M610.249 285.394L606.518 285.359V289.125H610.249V285.394Z" fill="#373B3D"/>
<path d="M602.057 285.394L598.326 285.359V289.125H602.057V285.394Z" fill="#373B3D"/>
<path d="M413.682 285.394L409.951 285.359V289.125H413.682V285.394Z" fill="#373B3D"/>
<path d="M405.49 285.394L401.759 285.359V289.125H405.49V285.394Z" fill="#373B3D"/>
<path d="M397.298 285.394L393.567 285.359V289.125H397.298V285.394Z" fill="#373B3D"/>
<path d="M1134.42 277.237L1130.69 277.202V280.968H1134.42V277.237Z" fill="#373B3D"/>
<path d="M1142.61 277.237L1138.88 277.202V280.968H1142.61V277.237Z" fill="#373B3D"/>
<path d="M1126.23 277.237L1122.49 277.202V280.968H1126.23V277.237Z" fill="#373B3D"/>
<path d="M1118.03 277.237L1114.3 277.202V280.968H1118.03V277.237Z" fill="#373B3D"/>
<path d="M1109.84 277.237L1106.11 277.202V280.968H1109.84V277.237Z" fill="#373B3D"/>
<path d="M1101.66 277.237L1097.93 277.202V280.968H1101.66V277.237Z" fill="#373B3D"/>
<path d="M1093.47 277.237L1089.74 277.202V280.968H1093.47V277.237Z" fill="#373B3D"/>
<path d="M1085.28 277.237L1081.55 277.202V280.968H1085.28V277.237Z" fill="#373B3D"/>
<path d="M1077.09 277.237L1073.36 277.202V280.968H1077.09V277.237Z" fill="#373B3D"/>
<path d="M1068.9 277.237L1065.16 277.202V280.968H1068.9V277.237Z" fill="#373B3D"/>
<path d="M1060.7 277.237L1056.97 277.202V280.968H1060.7V277.237Z" fill="#373B3D"/>
<path d="M1052.51 277.237L1048.78 277.202V280.968H1052.51V277.237Z" fill="#373B3D"/>
<path d="M1044.32 277.237L1040.59 277.202V280.968H1044.32V277.237Z" fill="#373B3D"/>
<path d="M1036.14 277.237L1032.41 277.202V280.968H1036.14V277.237Z" fill="#373B3D"/>
<path d="M1027.95 277.237L1024.22 277.202V280.968H1027.95V277.237Z" fill="#373B3D"/>
<path d="M1019.76 277.237L1016.03 277.202V280.968H1019.76V277.237Z" fill="#373B3D"/>
<path d="M1011.56 277.237L1007.83 277.202V280.968H1011.56V277.237Z" fill="#373B3D"/>
<path d="M1003.37 277.237L999.642 277.202V280.968H1003.37V277.237Z" fill="#373B3D"/>
<path d="M995.181 277.237L991.45 277.202V280.968H995.181V277.237Z" fill="#373B3D"/>
<path d="M986.989 277.238L983.258 277.203V280.969H986.989V277.238Z" fill="#373B3D"/>
<path d="M978.809 277.237L975.078 277.202V280.968H978.809V277.237Z" fill="#373B3D"/>
<path d="M970.617 277.237L966.886 277.202V280.968H970.617V277.237Z" fill="#373B3D"/>
<path d="M962.425 277.237L958.694 277.202V280.968H962.425V277.237Z" fill="#373B3D"/>
<path d="M954.233 277.237L950.502 277.202V280.968H954.233V277.237Z" fill="#373B3D"/>
<path d="M946.042 277.237L942.311 277.202V280.968H946.042V277.237Z" fill="#373B3D"/>
<path d="M937.85 277.237L934.119 277.202V280.968H937.85V277.237Z" fill="#373B3D"/>
<path d="M929.659 277.237L925.928 277.202V280.968H929.659V277.237Z" fill="#373B3D"/>
<path d="M921.478 277.237L917.747 277.202V280.968H921.478V277.237Z" fill="#373B3D"/>
<path d="M913.286 277.237L909.555 277.202V280.968H913.286V277.237Z" fill="#373B3D"/>
<path d="M905.095 277.237L901.364 277.202V280.968H905.095V277.237Z" fill="#373B3D"/>
<path d="M896.903 277.237L893.172 277.202V280.968H896.903V277.237Z" fill="#373B3D"/>
<path d="M888.711 277.238L884.98 277.203V280.969H888.711V277.238Z" fill="#373B3D"/>
<path d="M880.519 277.237L876.788 277.202V280.968H880.519V277.237Z" fill="#373B3D"/>
<path d="M872.328 277.237L868.597 277.202V280.968H872.328V277.237Z" fill="#373B3D"/>
<path d="M864.147 277.237L860.416 277.202V280.968H864.147V277.237Z" fill="#373B3D"/>
<path d="M855.956 277.237L852.225 277.202V280.968H855.956V277.237Z" fill="#373B3D"/>
<path d="M847.764 277.237L844.033 277.202V280.968H847.764V277.237Z" fill="#373B3D"/>
<path d="M839.572 277.237L835.841 277.202V280.968H839.572V277.237Z" fill="#373B3D"/>
<path d="M831.381 277.237L827.65 277.202V280.968H831.381V277.237Z" fill="#373B3D"/>
<path d="M823.189 277.238L819.458 277.203V280.969H823.189V277.238Z" fill="#373B3D"/>
<path d="M814.997 277.238L811.266 277.203V280.969H814.997V277.238Z" fill="#373B3D"/>
<path d="M806.805 277.238L803.074 277.203V280.969H806.805V277.238Z" fill="#373B3D"/>
<path d="M798.625 277.237L794.894 277.202V280.968H798.625V277.237Z" fill="#373B3D"/>
<path d="M790.433 277.237L786.702 277.202V280.968H790.433V277.237Z" fill="#373B3D"/>
<path d="M782.242 277.237L778.51 277.202V280.968H782.242V277.237Z" fill="#373B3D"/>
<path d="M774.05 277.237L770.319 277.202V280.968H774.05V277.237Z" fill="#373B3D"/>
<path d="M765.858 277.237L762.127 277.202V280.968H765.858V277.237Z" fill="#373B3D"/>
<path d="M757.666 277.237L753.935 277.202V280.968H757.666V277.237Z" fill="#373B3D"/>
<path d="M749.474 277.237L745.743 277.202V280.968H749.474V277.237Z" fill="#373B3D"/>
<path d="M741.294 277.237L737.563 277.202V280.968H741.294V277.237Z" fill="#373B3D"/>
<path d="M733.103 277.237L729.372 277.202V280.968H733.103V277.237Z" fill="#373B3D"/>
<path d="M724.911 277.237L721.179 277.202V280.968H724.911V277.237Z" fill="#373B3D"/>
<path d="M716.719 277.237L712.988 277.202V280.968H716.719V277.237Z" fill="#373B3D"/>
<path d="M708.527 277.237L704.796 277.202V280.968H708.527V277.237Z" fill="#373B3D"/>
<path d="M700.335 277.237L696.604 277.202V280.968H700.335V277.237Z" fill="#373B3D"/>
<path d="M692.143 277.237L688.412 277.202V280.968H692.143V277.237Z" fill="#373B3D"/>
<path d="M683.964 277.237L680.233 277.202V280.968H683.964V277.237Z" fill="#373B3D"/>
<path d="M675.772 277.237L672.041 277.202V280.968H675.772V277.237Z" fill="#373B3D"/>
<path d="M667.58 277.237L663.849 277.202V280.968H667.58V277.237Z" fill="#373B3D"/>
<path d="M610.249 277.237L606.518 277.202V280.968H610.249V277.237Z" fill="#373B3D"/>
<path d="M602.057 277.237L598.326 277.202V280.968H602.057V277.237Z" fill="#373B3D"/>
<path d="M536.535 277.237L532.804 277.202V280.968H536.535V277.237Z" fill="#373B3D"/>
<path d="M413.682 277.237L409.951 277.202V280.968H413.682V277.237Z" fill="#373B3D"/>
<path d="M405.49 277.237L401.759 277.202V280.968H405.49V277.237Z" fill="#373B3D"/>
<path d="M397.298 277.237L393.567 277.202V280.968H397.298V277.237Z" fill="#373B3D"/>
<path d="M1142.61 269.081L1138.88 269.046V272.812H1142.61V269.081Z" fill="#373B3D"/>
<path d="M1150.8 269.081L1147.07 269.046V272.812H1150.8V269.081Z" fill="#373B3D"/>
<path d="M1134.42 269.081L1130.69 269.046V272.812H1134.42V269.081Z" fill="#373B3D"/>
<path d="M1126.23 269.081L1122.49 269.046V272.812H1126.23V269.081Z" fill="#373B3D"/>
<path d="M1118.03 269.081L1114.3 269.046V272.812H1118.03V269.081Z" fill="#373B3D"/>
<path d="M1109.84 269.081L1106.11 269.046V272.812H1109.84V269.081Z" fill="#373B3D"/>
<path d="M1101.66 269.081L1097.93 269.046V272.812H1101.66V269.081Z" fill="#373B3D"/>
<path d="M1093.47 269.081L1089.74 269.046V272.812H1093.47V269.081Z" fill="#373B3D"/>
<path d="M1085.28 269.081L1081.55 269.046V272.812H1085.28V269.081Z" fill="#373B3D"/>
<path d="M1077.09 269.081L1073.36 269.046V272.812H1077.09V269.081Z" fill="#373B3D"/>
<path d="M1068.9 269.081L1065.16 269.046V272.812H1068.9V269.081Z" fill="#373B3D"/>
<path d="M1060.7 269.081L1056.97 269.046V272.812H1060.7V269.081Z" fill="#373B3D"/>
<path d="M1052.51 269.081L1048.78 269.046V272.812H1052.51V269.081Z" fill="#373B3D"/>
<path d="M1044.32 269.081L1040.59 269.046V272.812H1044.32V269.081Z" fill="#373B3D"/>
<path d="M1036.14 269.081L1032.41 269.046V272.812H1036.14V269.081Z" fill="#373B3D"/>
<path d="M1027.95 269.081L1024.22 269.046V272.812H1027.95V269.081Z" fill="#373B3D"/>
<path d="M1019.76 269.081L1016.03 269.046V272.812H1019.76V269.081Z" fill="#373B3D"/>
<path d="M1011.56 269.081L1007.83 269.046V272.812H1011.56V269.081Z" fill="#373B3D"/>
<path d="M1003.37 269.081L999.642 269.046V272.812H1003.37V269.081Z" fill="#373B3D"/>
<path d="M995.181 269.081L991.45 269.046V272.812H995.181V269.081Z" fill="#373B3D"/>
<path d="M986.989 269.081L983.258 269.045V272.812H986.989V269.081Z" fill="#373B3D"/>
<path d="M978.809 269.081L975.078 269.046V272.812H978.809V269.081Z" fill="#373B3D"/>
<path d="M970.617 269.081L966.886 269.046V272.812H970.617V269.081Z" fill="#373B3D"/>
<path d="M962.425 269.081L958.694 269.046V272.812H962.425V269.081Z" fill="#373B3D"/>
<path d="M954.233 269.081L950.502 269.046V272.812H954.233V269.081Z" fill="#373B3D"/>
<path d="M946.042 269.081L942.311 269.046V272.812H946.042V269.081Z" fill="#373B3D"/>
<path d="M937.85 269.081L934.119 269.046V272.812H937.85V269.081Z" fill="#373B3D"/>
<path d="M929.659 269.081L925.928 269.046V272.812H929.659V269.081Z" fill="#373B3D"/>
<path d="M921.478 269.081L917.747 269.046V272.812H921.478V269.081Z" fill="#373B3D"/>
<path d="M913.286 269.081L909.555 269.046V272.812H913.286V269.081Z" fill="#373B3D"/>
<path d="M905.095 269.081L901.364 269.046V272.812H905.095V269.081Z" fill="#373B3D"/>
<path d="M896.903 269.081L893.172 269.046V272.812H896.903V269.081Z" fill="#373B3D"/>
<path d="M888.711 269.081L884.98 269.045V272.812H888.711V269.081Z" fill="#373B3D"/>
<path d="M880.519 269.081L876.788 269.046V272.812H880.519V269.081Z" fill="#373B3D"/>
<path d="M872.328 269.081L868.597 269.046V272.812H872.328V269.081Z" fill="#373B3D"/>
<path d="M864.147 269.081L860.416 269.046V272.812H864.147V269.081Z" fill="#373B3D"/>
<path d="M855.956 269.081L852.225 269.046V272.812H855.956V269.081Z" fill="#373B3D"/>
<path d="M847.764 269.081L844.033 269.046V272.812H847.764V269.081Z" fill="#373B3D"/>
<path d="M839.572 269.081L835.841 269.046V272.812H839.572V269.081Z" fill="#373B3D"/>
<path d="M831.381 269.081L827.65 269.046V272.812H831.381V269.081Z" fill="#373B3D"/>
<path d="M823.189 269.081L819.458 269.046V272.812H823.189V269.081Z" fill="#373B3D"/>
<path d="M814.997 269.081L811.266 269.046V272.812H814.997V269.081Z" fill="#373B3D"/>
<path d="M806.805 269.081L803.074 269.045V272.812H806.805V269.081Z" fill="#373B3D"/>
<path d="M798.625 269.081L794.894 269.046V272.812H798.625V269.081Z" fill="#373B3D"/>
<path d="M790.433 269.081L786.702 269.046V272.812H790.433V269.081Z" fill="#373B3D"/>
<path d="M782.242 269.081L778.51 269.046V272.812H782.242V269.081Z" fill="#373B3D"/>
<path d="M774.05 269.081L770.319 269.046V272.812H774.05V269.081Z" fill="#373B3D"/>
<path d="M765.858 269.081L762.127 269.046V272.812H765.858V269.081Z" fill="#373B3D"/>
<path d="M757.666 269.081L753.935 269.046V272.812H757.666V269.081Z" fill="#373B3D"/>
<path d="M749.474 269.081L745.743 269.046V272.812H749.474V269.081Z" fill="#373B3D"/>
<path d="M741.294 269.081L737.563 269.046V272.812H741.294V269.081Z" fill="#373B3D"/>
<path d="M733.103 269.081L729.372 269.046V272.812H733.103V269.081Z" fill="#373B3D"/>
<path d="M724.911 269.081L721.179 269.046V272.812H724.911V269.081Z" fill="#373B3D"/>
<path d="M716.719 269.081L712.988 269.046V272.812H716.719V269.081Z" fill="#373B3D"/>
<path d="M708.527 269.081L704.796 269.046V272.812H708.527V269.081Z" fill="#373B3D"/>
<path d="M700.335 269.081L696.604 269.046V272.812H700.335V269.081Z" fill="#373B3D"/>
<path d="M692.143 269.081L688.412 269.046V272.812H692.143V269.081Z" fill="#373B3D"/>
<path d="M683.964 269.081L680.233 269.046V272.812H683.964V269.081Z" fill="#373B3D"/>
<path d="M683.964 260.936L680.233 260.901V264.667H683.964V260.936Z" fill="#373B3D"/>
<path d="M675.772 269.081L672.041 269.046V272.812H675.772V269.081Z" fill="#373B3D"/>
<path d="M667.58 269.081L663.849 269.046V272.812H667.58V269.081Z" fill="#373B3D"/>
<path d="M618.441 269.081L614.71 269.046V272.812H618.441V269.081Z" fill="#373B3D"/>
<path d="M610.249 269.081L606.518 269.046V272.812H610.249V269.081Z" fill="#373B3D"/>
<path d="M602.057 269.081L598.326 269.046V272.812H602.057V269.081Z" fill="#373B3D"/>
<path d="M421.873 269.081L418.142 269.046V272.812H421.873V269.081Z" fill="#373B3D"/>
<path d="M413.682 269.081L409.951 269.046V272.812H413.682V269.081Z" fill="#373B3D"/>
<path d="M405.49 269.081L401.759 269.046V272.812H405.49V269.081Z" fill="#373B3D"/>
<path d="M397.298 269.081L393.567 269.046V272.812H397.298V269.081Z" fill="#373B3D"/>
<path d="M389.118 269.081L385.387 269.046V272.812H389.118V269.081Z" fill="#373B3D"/>
<path d="M1175.36 260.936L1171.63 260.901V264.667H1175.36V260.936Z" fill="#373B3D"/>
<path d="M1142.61 260.936L1138.88 260.901V264.667H1142.61V260.936Z" fill="#373B3D"/>
<path d="M1134.42 260.936L1130.69 260.901V264.667H1134.42V260.936Z" fill="#373B3D"/>
<path d="M1126.23 260.936L1122.49 260.901V264.667H1126.23V260.936Z" fill="#373B3D"/>
<path d="M1118.03 260.936L1114.3 260.901V264.667H1118.03V260.936Z" fill="#373B3D"/>
<path d="M1109.84 260.936L1106.11 260.901V264.667H1109.84V260.936Z" fill="#373B3D"/>
<path d="M1101.66 260.936L1097.93 260.901V264.667H1101.66V260.936Z" fill="#373B3D"/>
<path d="M1093.47 260.936L1089.74 260.901V264.667H1093.47V260.936Z" fill="#373B3D"/>
<path d="M1085.28 260.936L1081.55 260.901V264.667H1085.28V260.936Z" fill="#373B3D"/>
<path d="M1077.09 260.936L1073.36 260.901V264.667H1077.09V260.936Z" fill="#373B3D"/>
<path d="M1068.9 260.936L1065.16 260.901V264.667H1068.9V260.936Z" fill="#373B3D"/>
<path d="M1060.7 260.936L1056.97 260.901V264.667H1060.7V260.936Z" fill="#373B3D"/>
<path d="M1052.51 260.936L1048.78 260.901V264.667H1052.51V260.936Z" fill="#373B3D"/>
<path d="M1044.32 260.936L1040.59 260.901V264.667H1044.32V260.936Z" fill="#373B3D"/>
<path d="M1036.14 260.936L1032.41 260.901V264.667H1036.14V260.936Z" fill="#373B3D"/>
<path d="M1027.95 260.936L1024.22 260.901V264.667H1027.95V260.936Z" fill="#373B3D"/>
<path d="M1019.76 260.936L1016.03 260.901V264.667H1019.76V260.936Z" fill="#373B3D"/>
<path d="M1011.56 260.936L1007.83 260.901V264.667H1011.56V260.936Z" fill="#373B3D"/>
<path d="M1003.37 260.936L999.642 260.901V264.667H1003.37V260.936Z" fill="#373B3D"/>
<path d="M995.181 260.936L991.45 260.901V264.667H995.181V260.936Z" fill="#373B3D"/>
<path d="M986.989 260.937L983.258 260.901V264.668H986.989V260.937Z" fill="#373B3D"/>
<path d="M978.809 260.936L975.078 260.901V264.667H978.809V260.936Z" fill="#373B3D"/>
<path d="M970.617 260.936L966.886 260.901V264.667H970.617V260.936Z" fill="#373B3D"/>
<path d="M962.425 260.936L958.694 260.901V264.667H962.425V260.936Z" fill="#373B3D"/>
<path d="M954.233 260.936L950.502 260.901V264.667H954.233V260.936Z" fill="#373B3D"/>
<path d="M946.042 260.936L942.311 260.901V264.667H946.042V260.936Z" fill="#373B3D"/>
<path d="M937.85 260.936L934.119 260.901V264.667H937.85V260.936Z" fill="#373B3D"/>
<path d="M929.659 260.936L925.928 260.901V264.667H929.659V260.936Z" fill="#373B3D"/>
<path d="M921.478 260.936L917.747 260.901V264.667H921.478V260.936Z" fill="#373B3D"/>
<path d="M913.286 260.936L909.555 260.901V264.667H913.286V260.936Z" fill="#373B3D"/>
<path d="M905.095 260.936L901.364 260.901V264.667H905.095V260.936Z" fill="#373B3D"/>
<path d="M896.903 260.936L893.172 260.901V264.667H896.903V260.936Z" fill="#373B3D"/>
<path d="M888.711 260.937L884.98 260.901V264.668H888.711V260.937Z" fill="#373B3D"/>
<path d="M880.519 260.936L876.788 260.901V264.667H880.519V260.936Z" fill="#373B3D"/>
<path d="M872.328 260.936L868.597 260.901V264.667H872.328V260.936Z" fill="#373B3D"/>
<path d="M864.147 260.936L860.416 260.901V264.667H864.147V260.936Z" fill="#373B3D"/>
<path d="M855.956 260.936L852.225 260.901V264.667H855.956V260.936Z" fill="#373B3D"/>
<path d="M847.764 260.936L844.033 260.901V264.667H847.764V260.936Z" fill="#373B3D"/>
<path d="M839.572 260.936L835.841 260.901V264.667H839.572V260.936Z" fill="#373B3D"/>
<path d="M831.381 260.936L827.65 260.901V264.667H831.381V260.936Z" fill="#373B3D"/>
<path d="M823.189 260.936L819.458 260.901V264.667H823.189V260.936Z" fill="#373B3D"/>
<path d="M814.997 260.936L811.266 260.901V264.667H814.997V260.936Z" fill="#373B3D"/>
<path d="M806.805 260.937L803.074 260.901V264.668H806.805V260.937Z" fill="#373B3D"/>
<path d="M798.625 260.936L794.894 260.901V264.667H798.625V260.936Z" fill="#373B3D"/>
<path d="M790.433 260.936L786.702 260.901V264.667H790.433V260.936Z" fill="#373B3D"/>
<path d="M782.242 260.936L778.51 260.901V264.667H782.242V260.936Z" fill="#373B3D"/>
<path d="M774.05 260.936L770.319 260.901V264.667H774.05V260.936Z" fill="#373B3D"/>
<path d="M765.858 260.936L762.127 260.901V264.667H765.858V260.936Z" fill="#373B3D"/>
<path d="M757.666 260.936L753.935 260.901V264.667H757.666V260.936Z" fill="#373B3D"/>
<path d="M749.474 260.936L745.743 260.901V264.667H749.474V260.936Z" fill="#373B3D"/>
<path d="M741.294 260.936L737.563 260.901V264.667H741.294V260.936Z" fill="#373B3D"/>
<path d="M733.103 260.936L729.372 260.901V264.667H733.103V260.936Z" fill="#373B3D"/>
<path d="M724.911 260.936L721.179 260.901V264.667H724.911V260.936Z" fill="#373B3D"/>
<path d="M716.719 260.936L712.988 260.901V264.667H716.719V260.936Z" fill="#373B3D"/>
<path d="M708.527 260.936L704.796 260.901V264.667H708.527V260.936Z" fill="#373B3D"/>
<path d="M700.335 260.936L696.604 260.901V264.667H700.335V260.936Z" fill="#373B3D"/>
<path d="M692.143 260.936L688.412 260.901V264.667H692.143V260.936Z" fill="#373B3D"/>
<path d="M667.58 260.936L663.849 260.901V264.667H667.58V260.936Z" fill="#373B3D"/>
<path d="M659.388 260.936L655.657 260.901V264.667H659.388V260.936Z" fill="#373B3D"/>
<path d="M626.633 260.936L622.902 260.901V264.667H626.633V260.936Z" fill="#373B3D"/>
<path d="M618.441 260.936L614.71 260.901V264.667H618.441V260.936Z" fill="#373B3D"/>
<path d="M610.249 260.936L606.518 260.901V264.667H610.249V260.936Z" fill="#373B3D"/>
<path d="M503.779 260.936L500.048 260.901V264.667H503.779V260.936Z" fill="#373B3D"/>
<path d="M511.971 260.936L508.24 260.901V264.667H511.971V260.936Z" fill="#373B3D"/>
<path d="M495.587 260.936L491.856 260.901V264.667H495.587V260.936Z" fill="#373B3D"/>
<path d="M495.587 269.081L491.856 269.046V272.812H495.587V269.081Z" fill="#373B3D"/>
<path d="M487.396 260.936L483.665 260.901V264.667H487.396V260.936Z" fill="#373B3D"/>
<path d="M487.396 269.081L483.665 269.046V272.812H487.396V269.081Z" fill="#373B3D"/>
<path d="M421.873 260.936L418.142 260.901V264.667H421.873V260.936Z" fill="#373B3D"/>
<path d="M413.682 260.936L409.951 260.901V264.667H413.682V260.936Z" fill="#373B3D"/>
<path d="M405.49 260.936L401.759 260.901V264.667H405.49V260.936Z" fill="#373B3D"/>
<path d="M397.298 260.936L393.567 260.901V264.667H397.298V260.936Z" fill="#373B3D"/>
<path d="M389.118 260.936L385.387 260.901V264.667H389.118V260.936Z" fill="#373B3D"/>
<path d="M1183.56 252.78L1179.83 252.745V256.511H1183.56V252.78Z" fill="#373B3D"/>
<path d="M1175.36 252.78L1171.63 252.745V256.511H1175.36V252.78Z" fill="#373B3D"/>
<path d="M1167.17 252.78L1163.44 252.745V256.511H1167.17V252.78Z" fill="#373B3D"/>
<path d="M1158.99 252.78L1155.26 252.745V256.511H1158.99V252.78Z" fill="#373B3D"/>
<path d="M1150.8 252.78L1147.07 252.745V256.511H1150.8V252.78Z" fill="#373B3D"/>
<path d="M1142.61 252.78L1138.88 252.745V256.511H1142.61V252.78Z" fill="#373B3D"/>
<path d="M1134.42 252.78L1130.69 252.745V256.511H1134.42V252.78Z" fill="#373B3D"/>
<path d="M1126.23 252.78L1122.49 252.745V256.511H1126.23V252.78Z" fill="#373B3D"/>
<path d="M1118.03 252.78L1114.3 252.745V256.511H1118.03V252.78Z" fill="#373B3D"/>
<path d="M1109.84 252.78L1106.11 252.745V256.511H1109.84V252.78Z" fill="#373B3D"/>
<path d="M1101.66 252.78L1097.93 252.745V256.511H1101.66V252.78Z" fill="#373B3D"/>
<path d="M1093.47 252.78L1089.74 252.745V256.511H1093.47V252.78Z" fill="#373B3D"/>
<path d="M1085.28 252.78L1081.55 252.745V256.511H1085.28V252.78Z" fill="#373B3D"/>
<path d="M1077.09 252.78L1073.36 252.745V256.511H1077.09V252.78Z" fill="#373B3D"/>
<path d="M1068.9 252.78L1065.16 252.745V256.511H1068.9V252.78Z" fill="#373B3D"/>
<path d="M1060.7 252.78L1056.97 252.745V256.511H1060.7V252.78Z" fill="#373B3D"/>
<path d="M1052.51 252.78L1048.78 252.745V256.511H1052.51V252.78Z" fill="#373B3D"/>
<path d="M1044.32 252.78L1040.59 252.745V256.511H1044.32V252.78Z" fill="#373B3D"/>
<path d="M1036.14 252.78L1032.41 252.745V256.511H1036.14V252.78Z" fill="#373B3D"/>
<path d="M1027.95 252.78L1024.22 252.745V256.511H1027.95V252.78Z" fill="#373B3D"/>
<path d="M1019.76 252.78L1016.03 252.745V256.511H1019.76V252.78Z" fill="#373B3D"/>
<path d="M1011.56 252.78L1007.83 252.745V256.511H1011.56V252.78Z" fill="#373B3D"/>
<path d="M1003.37 252.78L999.642 252.745V256.511H1003.37V252.78Z" fill="#373B3D"/>
<path d="M995.181 252.78L991.45 252.745V256.511H995.181V252.78Z" fill="#373B3D"/>
<path d="M986.989 252.78L983.258 252.745V256.511H986.989V252.78Z" fill="#373B3D"/>
<path d="M978.809 252.78L975.078 252.745V256.511H978.809V252.78Z" fill="#373B3D"/>
<path d="M970.617 252.78L966.886 252.745V256.511H970.617V252.78Z" fill="#373B3D"/>
<path d="M962.425 252.78L958.694 252.745V256.511H962.425V252.78Z" fill="#373B3D"/>
<path d="M954.233 252.78L950.502 252.745V256.511H954.233V252.78Z" fill="#373B3D"/>
<path d="M946.042 252.78L942.311 252.745V256.511H946.042V252.78Z" fill="#373B3D"/>
<path d="M937.85 252.78L934.119 252.745V256.511H937.85V252.78Z" fill="#373B3D"/>
<path d="M929.659 252.78L925.928 252.745V256.511H929.659V252.78Z" fill="#373B3D"/>
<path d="M921.478 252.78L917.747 252.745V256.511H921.478V252.78Z" fill="#373B3D"/>
<path d="M913.286 252.78L909.555 252.745V256.511H913.286V252.78Z" fill="#373B3D"/>
<path d="M905.095 252.78L901.364 252.745V256.511H905.095V252.78Z" fill="#373B3D"/>
<path d="M896.903 252.78L893.172 252.745V256.511H896.903V252.78Z" fill="#373B3D"/>
<path d="M888.711 252.78L884.98 252.745V256.511H888.711V252.78Z" fill="#373B3D"/>
<path d="M880.519 252.78L876.788 252.745V256.511H880.519V252.78Z" fill="#373B3D"/>
<path d="M872.328 252.78L868.597 252.745V256.511H872.328V252.78Z" fill="#373B3D"/>
<path d="M864.147 252.78L860.416 252.745V256.511H864.147V252.78Z" fill="#373B3D"/>
<path d="M855.956 252.78L852.225 252.745V256.511H855.956V252.78Z" fill="#373B3D"/>
<path d="M847.764 252.78L844.033 252.745V256.511H847.764V252.78Z" fill="#373B3D"/>
<path d="M839.572 252.78L835.841 252.745V256.511H839.572V252.78Z" fill="#373B3D"/>
<path d="M831.381 252.78L827.65 252.745V256.511H831.381V252.78Z" fill="#373B3D"/>
<path d="M823.189 252.78L819.458 252.745V256.511H823.189V252.78Z" fill="#373B3D"/>
<path d="M814.997 252.78L811.266 252.745V256.511H814.997V252.78Z" fill="#373B3D"/>
<path d="M806.805 252.78L803.074 252.745V256.511H806.805V252.78Z" fill="#373B3D"/>
<path d="M798.625 252.78L794.894 252.745V256.511H798.625V252.78Z" fill="#373B3D"/>
<path d="M790.433 252.78L786.702 252.745V256.511H790.433V252.78Z" fill="#373B3D"/>
<path d="M782.242 252.78L778.51 252.745V256.511H782.242V252.78Z" fill="#373B3D"/>
<path d="M774.05 252.78L770.319 252.745V256.511H774.05V252.78Z" fill="#373B3D"/>
<path d="M765.858 252.78L762.127 252.745V256.511H765.858V252.78Z" fill="#373B3D"/>
<path d="M757.666 252.78L753.935 252.745V256.511H757.666V252.78Z" fill="#373B3D"/>
<path d="M749.474 252.78L745.743 252.745V256.511H749.474V252.78Z" fill="#373B3D"/>
<path d="M741.294 252.78L737.563 252.745V256.511H741.294V252.78Z" fill="#373B3D"/>
<path d="M733.103 252.78L729.372 252.745V256.511H733.103V252.78Z" fill="#373B3D"/>
<path d="M724.911 252.78L721.179 252.745V256.511H724.911V252.78Z" fill="#373B3D"/>
<path d="M716.719 252.78L712.988 252.745V256.511H716.719V252.78Z" fill="#373B3D"/>
<path d="M708.527 252.78L704.796 252.745V256.511H708.527V252.78Z" fill="#373B3D"/>
<path d="M700.335 252.78L696.604 252.745V256.511H700.335V252.78Z" fill="#373B3D"/>
<path d="M692.143 252.78L688.412 252.745V256.511H692.143V252.78Z" fill="#373B3D"/>
<path d="M667.58 252.78L663.849 252.745V256.511H667.58V252.78Z" fill="#373B3D"/>
<path d="M659.388 252.78L655.657 252.745V256.511H659.388V252.78Z" fill="#373B3D"/>
<path d="M626.633 252.78L622.902 252.745V256.511H626.633V252.78Z" fill="#373B3D"/>
<path d="M618.441 252.78L614.71 252.745V256.511H618.441V252.78Z" fill="#373B3D"/>
<path d="M610.249 252.78L606.518 252.745V256.511H610.249V252.78Z" fill="#373B3D"/>
<path d="M503.779 252.78L500.048 252.745V256.511H503.779V252.78Z" fill="#373B3D"/>
<path d="M511.971 252.78L508.24 252.745V256.511H511.971V252.78Z" fill="#373B3D"/>
<path d="M495.587 252.78L491.856 252.745V256.511H495.587V252.78Z" fill="#373B3D"/>
<path d="M487.396 252.78L483.665 252.745V256.511H487.396V252.78Z" fill="#373B3D"/>
<path d="M430.065 252.78L426.334 252.745V256.511H430.065V252.78Z" fill="#373B3D"/>
<path d="M421.873 252.78L418.142 252.745V256.511H421.873V252.78Z" fill="#373B3D"/>
<path d="M413.682 252.78L409.951 252.745V256.511H413.682V252.78Z" fill="#373B3D"/>
<path d="M405.49 252.78L401.759 252.745V256.511H405.49V252.78Z" fill="#373B3D"/>
<path d="M397.298 252.78L393.567 252.745V256.511H397.298V252.78Z" fill="#373B3D"/>
<path d="M389.118 252.78L385.387 252.745V256.511H389.118V252.78Z" fill="#373B3D"/>
<path d="M1183.56 244.624L1179.83 244.588V248.355H1183.56V244.624Z" fill="#373B3D"/>
<path d="M1175.36 244.624L1171.63 244.588V248.355H1175.36V244.624Z" fill="#373B3D"/>
<path d="M1167.17 244.624L1163.44 244.588V248.355H1167.17V244.624Z" fill="#373B3D"/>
<path d="M1158.99 244.624L1155.26 244.588V248.355H1158.99V244.624Z" fill="#373B3D"/>
<path d="M1150.8 244.624L1147.07 244.588V248.355H1150.8V244.624Z" fill="#373B3D"/>
<path d="M1142.61 244.624L1138.88 244.588V248.355H1142.61V244.624Z" fill="#373B3D"/>
<path d="M1134.42 244.624L1130.69 244.588V248.355H1134.42V244.624Z" fill="#373B3D"/>
<path d="M1126.23 244.624L1122.49 244.588V248.355H1126.23V244.624Z" fill="#373B3D"/>
<path d="M1118.03 244.624L1114.3 244.588V248.355H1118.03V244.624Z" fill="#373B3D"/>
<path d="M1109.84 244.624L1106.11 244.588V248.355H1109.84V244.624Z" fill="#373B3D"/>
<path d="M1101.66 244.624L1097.93 244.588V248.355H1101.66V244.624Z" fill="#373B3D"/>
<path d="M1093.47 244.624L1089.74 244.588V248.355H1093.47V244.624Z" fill="#373B3D"/>
<path d="M1085.28 244.624L1081.55 244.588V248.355H1085.28V244.624Z" fill="#373B3D"/>
<path d="M1077.09 244.624L1073.36 244.588V248.355H1077.09V244.624Z" fill="#373B3D"/>
<path d="M1068.9 244.624L1065.16 244.588V248.355H1068.9V244.624Z" fill="#373B3D"/>
<path d="M1060.7 244.624L1056.97 244.588V248.355H1060.7V244.624Z" fill="#373B3D"/>
<path d="M1052.51 244.624L1048.78 244.588V248.355H1052.51V244.624Z" fill="#373B3D"/>
<path d="M1044.32 244.624L1040.59 244.588V248.355H1044.32V244.624Z" fill="#373B3D"/>
<path d="M1036.14 244.624L1032.41 244.588V248.355H1036.14V244.624Z" fill="#373B3D"/>
<path d="M1027.95 244.624L1024.22 244.588V248.355H1027.95V244.624Z" fill="#373B3D"/>
<path d="M1019.76 244.624L1016.03 244.588V248.355H1019.76V244.624Z" fill="#373B3D"/>
<path d="M1011.56 244.624L1007.83 244.588V248.355H1011.56V244.624Z" fill="#373B3D"/>
<path d="M1003.37 244.624L999.642 244.588V248.355H1003.37V244.624Z" fill="#373B3D"/>
<path d="M995.181 244.624L991.45 244.588V248.355H995.181V244.624Z" fill="#373B3D"/>
<path d="M986.989 244.624L983.258 244.588V248.355H986.989V244.624Z" fill="#373B3D"/>
<path d="M978.809 244.624L975.078 244.588V248.355H978.809V244.624Z" fill="#373B3D"/>
<path d="M970.617 244.624L966.886 244.588V248.355H970.617V244.624Z" fill="#373B3D"/>
<path d="M962.425 244.624L958.694 244.588V248.355H962.425V244.624Z" fill="#373B3D"/>
<path d="M954.233 244.624L950.502 244.588V248.355H954.233V244.624Z" fill="#373B3D"/>
<path d="M946.042 244.624L942.311 244.588V248.355H946.042V244.624Z" fill="#373B3D"/>
<path d="M937.85 244.624L934.119 244.588V248.355H937.85V244.624Z" fill="#373B3D"/>
<path d="M929.659 244.624L925.928 244.588V248.355H929.659V244.624Z" fill="#373B3D"/>
<path d="M921.478 244.624L917.747 244.588V248.355H921.478V244.624Z" fill="#373B3D"/>
<path d="M913.286 244.624L909.555 244.588V248.355H913.286V244.624Z" fill="#373B3D"/>
<path d="M905.095 244.624L901.364 244.588V248.355H905.095V244.624Z" fill="#373B3D"/>
<path d="M896.903 244.624L893.172 244.588V248.355H896.903V244.624Z" fill="#373B3D"/>
<path d="M888.711 244.624L884.98 244.588V248.355H888.711V244.624Z" fill="#373B3D"/>
<path d="M880.519 244.624L876.788 244.588V248.355H880.519V244.624Z" fill="#373B3D"/>
<path d="M872.328 244.624L868.597 244.588V248.355H872.328V244.624Z" fill="#373B3D"/>
<path d="M864.147 244.624L860.416 244.588V248.355H864.147V244.624Z" fill="#373B3D"/>
<path d="M855.956 244.624L852.225 244.588V248.355H855.956V244.624Z" fill="#373B3D"/>
<path d="M847.764 244.624L844.033 244.588V248.355H847.764V244.624Z" fill="#373B3D"/>
<path d="M839.572 244.624L835.841 244.588V248.355H839.572V244.624Z" fill="#373B3D"/>
<path d="M831.381 244.624L827.65 244.588V248.355H831.381V244.624Z" fill="#373B3D"/>
<path d="M823.189 244.624L819.458 244.588V248.355H823.189V244.624Z" fill="#373B3D"/>
<path d="M814.997 244.624L811.266 244.588V248.355H814.997V244.624Z" fill="#373B3D"/>
<path d="M806.805 244.624L803.074 244.588V248.355H806.805V244.624Z" fill="#373B3D"/>
<path d="M798.625 244.624L794.894 244.588V248.355H798.625V244.624Z" fill="#373B3D"/>
<path d="M790.433 244.624L786.702 244.588V248.355H790.433V244.624Z" fill="#373B3D"/>
<path d="M782.242 244.624L778.51 244.588V248.355H782.242V244.624Z" fill="#373B3D"/>
<path d="M774.05 244.624L770.319 244.588V248.355H774.05V244.624Z" fill="#373B3D"/>
<path d="M765.858 244.624L762.127 244.588V248.355H765.858V244.624Z" fill="#373B3D"/>
<path d="M757.666 244.624L753.935 244.588V248.355H757.666V244.624Z" fill="#373B3D"/>
<path d="M749.474 244.624L745.743 244.588V248.355H749.474V244.624Z" fill="#373B3D"/>
<path d="M741.294 244.624L737.563 244.588V248.355H741.294V244.624Z" fill="#373B3D"/>
<path d="M733.103 244.624L729.372 244.588V248.355H733.103V244.624Z" fill="#373B3D"/>
<path d="M724.911 244.624L721.179 244.588V248.355H724.911V244.624Z" fill="#373B3D"/>
<path d="M716.719 244.624L712.988 244.588V248.355H716.719V244.624Z" fill="#373B3D"/>
<path d="M708.527 244.624L704.796 244.588V248.355H708.527V244.624Z" fill="#373B3D"/>
<path d="M692.143 244.624L688.412 244.588V248.355H692.143V244.624Z" fill="#373B3D"/>
<path d="M683.964 244.624L680.233 244.588V248.355H683.964V244.624Z" fill="#373B3D"/>
<path d="M675.772 244.624L672.041 244.588V248.355H675.772V244.624Z" fill="#373B3D"/>
<path d="M667.58 244.624L663.849 244.588V248.355H667.58V244.624Z" fill="#373B3D"/>
<path d="M659.388 244.624L655.657 244.588V248.355H659.388V244.624Z" fill="#373B3D"/>
<path d="M634.813 244.624L631.082 244.588V248.355H634.813V244.624Z" fill="#373B3D"/>
<path d="M626.633 244.624L622.902 244.588V248.355H626.633V244.624Z" fill="#373B3D"/>
<path d="M618.441 244.624L614.71 244.588V248.355H618.441V244.624Z" fill="#373B3D"/>
<path d="M438.257 244.624L434.526 244.588V248.355H438.257V244.624Z" fill="#373B3D"/>
<path d="M446.449 244.624L442.718 244.588V248.355H446.449V244.624Z" fill="#373B3D"/>
<path d="M430.065 244.624L426.334 244.588V248.355H430.065V244.624Z" fill="#373B3D"/>
<path d="M421.873 244.624L418.142 244.588V248.355H421.873V244.624Z" fill="#373B3D"/>
<path d="M413.682 244.624L409.951 244.588V248.355H413.682V244.624Z" fill="#373B3D"/>
<path d="M405.49 244.624L401.759 244.588V248.355H405.49V244.624Z" fill="#373B3D"/>
<path d="M397.298 244.624L393.567 244.588V248.355H397.298V244.624Z" fill="#373B3D"/>
<path d="M389.118 244.624L385.387 244.588V248.355H389.118V244.624Z" fill="#373B3D"/>
<path d="M1167.17 236.479L1163.44 236.443V240.21H1167.17V236.479Z" fill="#373B3D"/>
<path d="M1158.99 236.479L1155.26 236.443V240.21H1158.99V236.479Z" fill="#373B3D"/>
<path d="M1150.8 236.479L1147.07 236.443V240.21H1150.8V236.479Z" fill="#373B3D"/>
<path d="M1142.61 236.479L1138.88 236.443V240.21H1142.61V236.479Z" fill="#373B3D"/>
<path d="M1134.42 236.479L1130.69 236.443V240.21H1134.42V236.479Z" fill="#373B3D"/>
<path d="M1126.23 236.479L1122.49 236.443V240.21H1126.23V236.479Z" fill="#373B3D"/>
<path d="M1118.03 236.479L1114.3 236.443V240.21H1118.03V236.479Z" fill="#373B3D"/>
<path d="M1109.84 236.479L1106.11 236.443V240.21H1109.84V236.479Z" fill="#373B3D"/>
<path d="M1101.66 236.479L1097.93 236.443V240.21H1101.66V236.479Z" fill="#373B3D"/>
<path d="M1093.47 236.479L1089.74 236.443V240.21H1093.47V236.479Z" fill="#373B3D"/>
<path d="M1085.28 236.479L1081.55 236.443V240.21H1085.28V236.479Z" fill="#373B3D"/>
<path d="M1077.09 236.479L1073.36 236.443V240.21H1077.09V236.479Z" fill="#373B3D"/>
<path d="M1068.9 236.479L1065.16 236.443V240.21H1068.9V236.479Z" fill="#373B3D"/>
<path d="M1060.7 236.479L1056.97 236.443V240.21H1060.7V236.479Z" fill="#373B3D"/>
<path d="M1052.51 236.479L1048.78 236.443V240.21H1052.51V236.479Z" fill="#373B3D"/>
<path d="M1044.32 236.479L1040.59 236.443V240.21H1044.32V236.479Z" fill="#373B3D"/>
<path d="M1036.14 236.479L1032.41 236.443V240.21H1036.14V236.479Z" fill="#373B3D"/>
<path d="M1027.95 236.479L1024.22 236.443V240.21H1027.95V236.479Z" fill="#373B3D"/>
<path d="M1019.76 236.479L1016.03 236.443V240.21H1019.76V236.479Z" fill="#373B3D"/>
<path d="M1011.56 236.479L1007.83 236.443V240.21H1011.56V236.479Z" fill="#373B3D"/>
<path d="M1003.37 236.479L999.642 236.443V240.21H1003.37V236.479Z" fill="#373B3D"/>
<path d="M995.181 236.479L991.45 236.443V240.21H995.181V236.479Z" fill="#373B3D"/>
<path d="M986.989 236.479L983.258 236.444V240.21H986.989V236.479Z" fill="#373B3D"/>
<path d="M978.809 236.479L975.078 236.443V240.21H978.809V236.479Z" fill="#373B3D"/>
<path d="M970.617 236.479L966.886 236.443V240.21H970.617V236.479Z" fill="#373B3D"/>
<path d="M962.425 236.479L958.694 236.443V240.21H962.425V236.479Z" fill="#373B3D"/>
<path d="M954.233 236.479L950.502 236.443V240.21H954.233V236.479Z" fill="#373B3D"/>
<path d="M946.042 236.479L942.311 236.443V240.21H946.042V236.479Z" fill="#373B3D"/>
<path d="M937.85 236.479L934.119 236.443V240.21H937.85V236.479Z" fill="#373B3D"/>
<path d="M929.659 236.479L925.928 236.443V240.21H929.659V236.479Z" fill="#373B3D"/>
<path d="M921.478 236.479L917.747 236.443V240.21H921.478V236.479Z" fill="#373B3D"/>
<path d="M913.286 236.479L909.555 236.443V240.21H913.286V236.479Z" fill="#373B3D"/>
<path d="M905.095 236.479L901.364 236.443V240.21H905.095V236.479Z" fill="#373B3D"/>
<path d="M896.903 236.479L893.172 236.443V240.21H896.903V236.479Z" fill="#373B3D"/>
<path d="M888.711 236.479L884.98 236.444V240.21H888.711V236.479Z" fill="#373B3D"/>
<path d="M880.519 236.479L876.788 236.443V240.21H880.519V236.479Z" fill="#373B3D"/>
<path d="M872.328 236.479L868.597 236.443V240.21H872.328V236.479Z" fill="#373B3D"/>
<path d="M864.147 236.479L860.416 236.443V240.21H864.147V236.479Z" fill="#373B3D"/>
<path d="M855.956 236.479L852.225 236.443V240.21H855.956V236.479Z" fill="#373B3D"/>
<path d="M847.764 236.479L844.033 236.443V240.21H847.764V236.479Z" fill="#373B3D"/>
<path d="M839.572 236.479L835.841 236.443V240.21H839.572V236.479Z" fill="#373B3D"/>
<path d="M831.381 236.479L827.65 236.443V240.21H831.381V236.479Z" fill="#373B3D"/>
<path d="M823.189 236.479L819.458 236.443V240.21H823.189V236.479Z" fill="#373B3D"/>
<path d="M814.997 236.479L811.266 236.443V240.21H814.997V236.479Z" fill="#373B3D"/>
<path d="M806.805 236.479L803.074 236.444V240.21H806.805V236.479Z" fill="#373B3D"/>
<path d="M798.625 236.479L794.894 236.443V240.21H798.625V236.479Z" fill="#373B3D"/>
<path d="M790.433 236.479L786.702 236.443V240.21H790.433V236.479Z" fill="#373B3D"/>
<path d="M782.242 236.479L778.51 236.443V240.21H782.242V236.479Z" fill="#373B3D"/>
<path d="M774.05 236.479L770.319 236.443V240.21H774.05V236.479Z" fill="#373B3D"/>
<path d="M765.858 236.479L762.127 236.443V240.21H765.858V236.479Z" fill="#373B3D"/>
<path d="M757.666 236.479L753.935 236.443V240.21H757.666V236.479Z" fill="#373B3D"/>
<path d="M749.474 236.479L745.743 236.443V240.21H749.474V236.479Z" fill="#373B3D"/>
<path d="M741.294 236.479L737.563 236.443V240.21H741.294V236.479Z" fill="#373B3D"/>
<path d="M733.103 236.479L729.372 236.443V240.21H733.103V236.479Z" fill="#373B3D"/>
<path d="M724.911 236.479L721.179 236.443V240.21H724.911V236.479Z" fill="#373B3D"/>
<path d="M708.527 236.479L704.796 236.443V240.21H708.527V236.479Z" fill="#373B3D"/>
<path d="M692.143 236.479L688.412 236.443V240.21H692.143V236.479Z" fill="#373B3D"/>
<path d="M683.964 236.479L680.233 236.443V240.21H683.964V236.479Z" fill="#373B3D"/>
<path d="M675.772 236.479L672.041 236.443V240.21H675.772V236.479Z" fill="#373B3D"/>
<path d="M667.58 236.479L663.849 236.443V240.21H667.58V236.479Z" fill="#373B3D"/>
<path d="M659.388 236.479L655.657 236.443V240.21H659.388V236.479Z" fill="#373B3D"/>
<path d="M634.813 236.479L631.082 236.443V240.21H634.813V236.479Z" fill="#373B3D"/>
<path d="M626.633 236.479L622.902 236.443V240.21H626.633V236.479Z" fill="#373B3D"/>
<path d="M618.441 236.479L614.71 236.443V240.21H618.441V236.479Z" fill="#373B3D"/>
<path d="M446.449 236.479L442.718 236.443V240.21H446.449V236.479Z" fill="#373B3D"/>
<path d="M438.257 236.479L434.526 236.443V240.21H438.257V236.479Z" fill="#373B3D"/>
<path d="M430.065 236.479L426.334 236.443V240.21H430.065V236.479Z" fill="#373B3D"/>
<path d="M421.873 236.479L418.142 236.443V240.21H421.873V236.479Z" fill="#373B3D"/>
<path d="M413.682 236.479L409.951 236.443V240.21H413.682V236.479Z" fill="#373B3D"/>
<path d="M405.49 236.479L401.759 236.443V240.21H405.49V236.479Z" fill="#373B3D"/>
<path d="M397.298 236.479L393.567 236.443V240.21H397.298V236.479Z" fill="#373B3D"/>
<path d="M389.118 236.479L385.387 236.443V240.21H389.118V236.479Z" fill="#373B3D"/>
<path d="M380.926 236.479L377.195 236.443V240.21H380.926V236.479Z" fill="#373B3D"/>
<path d="M380.926 244.624L377.195 244.588V248.355H380.926V244.624Z" fill="#373B3D"/>
<path d="M1158.99 228.322L1155.26 228.287V232.053H1158.99V228.322Z" fill="#373B3D"/>
<path d="M1150.8 228.322L1147.07 228.287V232.053H1150.8V228.322Z" fill="#373B3D"/>
<path d="M1142.61 228.322L1138.88 228.287V232.053H1142.61V228.322Z" fill="#373B3D"/>
<path d="M1134.42 228.322L1130.69 228.287V232.053H1134.42V228.322Z" fill="#373B3D"/>
<path d="M1126.23 228.322L1122.49 228.287V232.053H1126.23V228.322Z" fill="#373B3D"/>
<path d="M1118.03 228.322L1114.3 228.287V232.053H1118.03V228.322Z" fill="#373B3D"/>
<path d="M1109.84 228.322L1106.11 228.287V232.053H1109.84V228.322Z" fill="#373B3D"/>
<path d="M1101.66 228.322L1097.93 228.287V232.053H1101.66V228.322Z" fill="#373B3D"/>
<path d="M1093.47 228.322L1089.74 228.287V232.053H1093.47V228.322Z" fill="#373B3D"/>
<path d="M1085.28 228.322L1081.55 228.287V232.053H1085.28V228.322Z" fill="#373B3D"/>
<path d="M1077.09 228.322L1073.36 228.287V232.053H1077.09V228.322Z" fill="#373B3D"/>
<path d="M1068.9 228.322L1065.16 228.287V232.053H1068.9V228.322Z" fill="#373B3D"/>
<path d="M1060.7 228.322L1056.97 228.287V232.053H1060.7V228.322Z" fill="#373B3D"/>
<path d="M1052.51 228.322L1048.78 228.287V232.053H1052.51V228.322Z" fill="#373B3D"/>
<path d="M1044.32 228.322L1040.59 228.287V232.053H1044.32V228.322Z" fill="#373B3D"/>
<path d="M1036.14 228.322L1032.41 228.287V232.053H1036.14V228.322Z" fill="#373B3D"/>
<path d="M1027.95 228.322L1024.22 228.287V232.053H1027.95V228.322Z" fill="#373B3D"/>
<path d="M1019.76 228.322L1016.03 228.287V232.053H1019.76V228.322Z" fill="#373B3D"/>
<path d="M1011.56 228.322L1007.83 228.287V232.053H1011.56V228.322Z" fill="#373B3D"/>
<path d="M1003.37 228.322L999.642 228.287V232.053H1003.37V228.322Z" fill="#373B3D"/>
<path d="M995.181 228.322L991.45 228.287V232.053H995.181V228.322Z" fill="#373B3D"/>
<path d="M986.989 228.322L983.258 228.287V232.053H986.989V228.322Z" fill="#373B3D"/>
<path d="M978.809 228.322L975.078 228.287V232.053H978.809V228.322Z" fill="#373B3D"/>
<path d="M970.617 228.322L966.886 228.287V232.053H970.617V228.322Z" fill="#373B3D"/>
<path d="M962.425 228.322L958.694 228.287V232.053H962.425V228.322Z" fill="#373B3D"/>
<path d="M954.233 228.322L950.502 228.287V232.053H954.233V228.322Z" fill="#373B3D"/>
<path d="M946.042 228.322L942.311 228.287V232.053H946.042V228.322Z" fill="#373B3D"/>
<path d="M937.85 228.322L934.119 228.287V232.053H937.85V228.322Z" fill="#373B3D"/>
<path d="M929.659 228.322L925.928 228.287V232.053H929.659V228.322Z" fill="#373B3D"/>
<path d="M921.478 228.322L917.747 228.287V232.053H921.478V228.322Z" fill="#373B3D"/>
<path d="M913.286 228.322L909.555 228.287V232.053H913.286V228.322Z" fill="#373B3D"/>
<path d="M905.095 228.322L901.364 228.287V232.053H905.095V228.322Z" fill="#373B3D"/>
<path d="M896.903 228.322L893.172 228.287V232.053H896.903V228.322Z" fill="#373B3D"/>
<path d="M888.711 228.322L884.98 228.287V232.053H888.711V228.322Z" fill="#373B3D"/>
<path d="M880.519 228.322L876.788 228.287V232.053H880.519V228.322Z" fill="#373B3D"/>
<path d="M872.328 228.322L868.597 228.287V232.053H872.328V228.322Z" fill="#373B3D"/>
<path d="M864.147 228.322L860.416 228.287V232.053H864.147V228.322Z" fill="#373B3D"/>
<path d="M855.956 228.322L852.225 228.287V232.053H855.956V228.322Z" fill="#373B3D"/>
<path d="M847.764 228.322L844.033 228.287V232.053H847.764V228.322Z" fill="#373B3D"/>
<path d="M839.572 228.322L835.841 228.287V232.053H839.572V228.322Z" fill="#373B3D"/>
<path d="M831.381 228.322L827.65 228.287V232.053H831.381V228.322Z" fill="#373B3D"/>
<path d="M823.189 228.322L819.458 228.287V232.053H823.189V228.322Z" fill="#373B3D"/>
<path d="M814.997 228.322L811.266 228.287V232.053H814.997V228.322Z" fill="#373B3D"/>
<path d="M806.805 228.322L803.074 228.287V232.053H806.805V228.322Z" fill="#373B3D"/>
<path d="M798.625 228.322L794.894 228.287V232.053H798.625V228.322Z" fill="#373B3D"/>
<path d="M790.433 228.322L786.702 228.287V232.053H790.433V228.322Z" fill="#373B3D"/>
<path d="M782.242 228.322L778.51 228.287V232.053H782.242V228.322Z" fill="#373B3D"/>
<path d="M774.05 228.322L770.319 228.287V232.053H774.05V228.322Z" fill="#373B3D"/>
<path d="M765.858 228.322L762.127 228.287V232.053H765.858V228.322Z" fill="#373B3D"/>
<path d="M757.666 228.322L753.935 228.287V232.053H757.666V228.322Z" fill="#373B3D"/>
<path d="M749.474 228.322L745.743 228.287V232.053H749.474V228.322Z" fill="#373B3D"/>
<path d="M741.294 228.322L737.563 228.287V232.053H741.294V228.322Z" fill="#373B3D"/>
<path d="M733.103 228.322L729.372 228.287V232.053H733.103V228.322Z" fill="#373B3D"/>
<path d="M708.527 228.322L704.796 228.287V232.053H708.527V228.322Z" fill="#373B3D"/>
<path d="M683.964 228.322L680.233 228.287V232.053H683.964V228.322Z" fill="#373B3D"/>
<path d="M675.772 228.322L672.041 228.287V232.053H675.772V228.322Z" fill="#373B3D"/>
<path d="M675.772 220.166L672.041 220.13V223.897H675.772V220.166Z" fill="#373B3D"/>
<path d="M667.58 228.322L663.849 228.287V232.053H667.58V228.322Z" fill="#373B3D"/>
<path d="M659.388 228.322L655.657 228.287V232.053H659.388V228.322Z" fill="#373B3D"/>
<path d="M626.633 228.322L622.902 228.287V232.053H626.633V228.322Z" fill="#373B3D"/>
<path d="M462.821 228.322L459.09 228.287V232.053H462.821V228.322Z" fill="#373B3D"/>
<path d="M454.629 228.322L450.898 228.287V232.053H454.629V228.322Z" fill="#373B3D"/>
<path d="M446.449 228.322L442.718 228.287V232.053H446.449V228.322Z" fill="#373B3D"/>
<path d="M438.257 228.322L434.526 228.287V232.053H438.257V228.322Z" fill="#373B3D"/>
<path d="M430.065 228.322L426.334 228.287V232.053H430.065V228.322Z" fill="#373B3D"/>
<path d="M421.873 228.322L418.142 228.287V232.053H421.873V228.322Z" fill="#373B3D"/>
<path d="M413.682 228.322L409.951 228.287V232.053H413.682V228.322Z" fill="#373B3D"/>
<path d="M405.49 228.322L401.759 228.287V232.053H405.49V228.322Z" fill="#373B3D"/>
<path d="M397.298 228.322L393.567 228.287V232.053H397.298V228.322Z" fill="#373B3D"/>
<path d="M389.118 228.322L385.387 228.287V232.053H389.118V228.322Z" fill="#373B3D"/>
<path d="M1142.61 220.166L1138.88 220.13V223.897H1142.61V220.166Z" fill="#373B3D"/>
<path d="M1134.42 220.166L1130.69 220.13V223.897H1134.42V220.166Z" fill="#373B3D"/>
<path d="M1126.23 220.166L1122.49 220.13V223.897H1126.23V220.166Z" fill="#373B3D"/>
<path d="M1109.84 220.166L1106.11 220.13V223.897H1109.84V220.166Z" fill="#373B3D"/>
<path d="M1101.66 220.166L1097.93 220.13V223.897H1101.66V220.166Z" fill="#373B3D"/>
<path d="M1093.47 220.166L1089.74 220.13V223.897H1093.47V220.166Z" fill="#373B3D"/>
<path d="M1085.28 220.166L1081.55 220.13V223.897H1085.28V220.166Z" fill="#373B3D"/>
<path d="M1077.09 220.166L1073.36 220.13V223.897H1077.09V220.166Z" fill="#373B3D"/>
<path d="M1068.9 220.166L1065.16 220.13V223.897H1068.9V220.166Z" fill="#373B3D"/>
<path d="M1060.7 220.166L1056.97 220.13V223.897H1060.7V220.166Z" fill="#373B3D"/>
<path d="M1052.51 220.166L1048.78 220.13V223.897H1052.51V220.166Z" fill="#373B3D"/>
<path d="M1044.32 220.166L1040.59 220.13V223.897H1044.32V220.166Z" fill="#373B3D"/>
<path d="M1036.14 220.166L1032.41 220.13V223.897H1036.14V220.166Z" fill="#373B3D"/>
<path d="M1027.95 220.166L1024.22 220.13V223.897H1027.95V220.166Z" fill="#373B3D"/>
<path d="M1019.76 220.166L1016.03 220.13V223.897H1019.76V220.166Z" fill="#373B3D"/>
<path d="M1011.56 220.166L1007.83 220.13V223.897H1011.56V220.166Z" fill="#373B3D"/>
<path d="M1003.37 220.166L999.642 220.13V223.897H1003.37V220.166Z" fill="#373B3D"/>
<path d="M995.181 220.166L991.45 220.13V223.897H995.181V220.166Z" fill="#373B3D"/>
<path d="M986.989 220.166L983.258 220.13V223.897H986.989V220.166Z" fill="#373B3D"/>
<path d="M978.809 220.166L975.078 220.13V223.897H978.809V220.166Z" fill="#373B3D"/>
<path d="M970.617 220.166L966.886 220.13V223.897H970.617V220.166Z" fill="#373B3D"/>
<path d="M962.425 220.166L958.694 220.13V223.897H962.425V220.166Z" fill="#373B3D"/>
<path d="M954.233 220.166L950.502 220.13V223.897H954.233V220.166Z" fill="#373B3D"/>
<path d="M946.042 220.166L942.311 220.13V223.897H946.042V220.166Z" fill="#373B3D"/>
<path d="M937.85 220.166L934.119 220.13V223.897H937.85V220.166Z" fill="#373B3D"/>
<path d="M929.659 220.166L925.928 220.13V223.897H929.659V220.166Z" fill="#373B3D"/>
<path d="M921.478 220.166L917.747 220.13V223.897H921.478V220.166Z" fill="#373B3D"/>
<path d="M913.286 220.166L909.555 220.13V223.897H913.286V220.166Z" fill="#373B3D"/>
<path d="M905.095 220.166L901.364 220.13V223.897H905.095V220.166Z" fill="#373B3D"/>
<path d="M896.903 220.166L893.172 220.13V223.897H896.903V220.166Z" fill="#373B3D"/>
<path d="M888.711 220.166L884.98 220.13V223.897H888.711V220.166Z" fill="#373B3D"/>
<path d="M880.519 220.166L876.788 220.13V223.897H880.519V220.166Z" fill="#373B3D"/>
<path d="M872.328 220.166L868.597 220.13V223.897H872.328V220.166Z" fill="#373B3D"/>
<path d="M864.147 220.166L860.416 220.13V223.897H864.147V220.166Z" fill="#373B3D"/>
<path d="M855.956 220.166L852.225 220.13V223.897H855.956V220.166Z" fill="#373B3D"/>
<path d="M847.764 220.166L844.033 220.13V223.897H847.764V220.166Z" fill="#373B3D"/>
<path d="M839.572 220.166L835.841 220.13V223.897H839.572V220.166Z" fill="#373B3D"/>
<path d="M831.381 220.166L827.65 220.13V223.897H831.381V220.166Z" fill="#373B3D"/>
<path d="M823.189 220.166L819.458 220.13V223.897H823.189V220.166Z" fill="#373B3D"/>
<path d="M814.997 220.166L811.266 220.13V223.897H814.997V220.166Z" fill="#373B3D"/>
<path d="M806.805 220.166L803.074 220.13V223.897H806.805V220.166Z" fill="#373B3D"/>
<path d="M798.625 220.166L794.894 220.13V223.897H798.625V220.166Z" fill="#373B3D"/>
<path d="M790.433 220.166L786.702 220.13V223.897H790.433V220.166Z" fill="#373B3D"/>
<path d="M765.858 220.166L762.127 220.13V223.897H765.858V220.166Z" fill="#373B3D"/>
<path d="M757.666 220.166L753.935 220.13V223.897H757.666V220.166Z" fill="#373B3D"/>
<path d="M774.05 220.166L770.319 220.13V223.897H774.05V220.166Z" fill="#373B3D"/>
<path d="M724.911 220.166L721.179 220.13V223.897H724.911V220.166Z" fill="#373B3D"/>
<path d="M667.58 220.166L663.849 220.13V223.897H667.58V220.166Z" fill="#373B3D"/>
<path d="M659.388 220.166L655.657 220.13V223.897H659.388V220.166Z" fill="#373B3D"/>
<path d="M471.012 220.166L467.281 220.13V223.897H471.012V220.166Z" fill="#373B3D"/>
<path d="M462.821 220.166L459.09 220.13V223.897H462.821V220.166Z" fill="#373B3D"/>
<path d="M454.629 220.166L450.898 220.13V223.897H454.629V220.166Z" fill="#373B3D"/>
<path d="M446.449 220.166L442.718 220.13V223.897H446.449V220.166Z" fill="#373B3D"/>
<path d="M438.257 220.166L434.526 220.13V223.897H438.257V220.166Z" fill="#373B3D"/>
<path d="M430.065 220.166L426.334 220.13V223.897H430.065V220.166Z" fill="#373B3D"/>
<path d="M421.873 220.166L418.142 220.13V223.897H421.873V220.166Z" fill="#373B3D"/>
<path d="M413.682 220.166L409.951 220.13V223.897H413.682V220.166Z" fill="#373B3D"/>
<path d="M405.49 220.166L401.759 220.13V223.897H405.49V220.166Z" fill="#373B3D"/>
<path d="M397.298 220.166L393.567 220.13V223.897H397.298V220.166Z" fill="#373B3D"/>
<path d="M1085.28 212.009L1081.55 211.974V215.74H1085.28V212.009Z" fill="#373B3D"/>
<path d="M1077.09 212.009L1073.36 211.974V215.74H1077.09V212.009Z" fill="#373B3D"/>
<path d="M1068.9 212.009L1065.16 211.974V215.74H1068.9V212.009Z" fill="#373B3D"/>
<path d="M1060.7 212.009L1056.97 211.974V215.74H1060.7V212.009Z" fill="#373B3D"/>
<path d="M1052.51 212.009L1048.78 211.974V215.74H1052.51V212.009Z" fill="#373B3D"/>
<path d="M1044.32 212.009L1040.59 211.974V215.74H1044.32V212.009Z" fill="#373B3D"/>
<path d="M1036.14 212.009L1032.41 211.974V215.74H1036.14V212.009Z" fill="#373B3D"/>
<path d="M1027.95 212.009L1024.22 211.974V215.74H1027.95V212.009Z" fill="#373B3D"/>
<path d="M1019.76 212.009L1016.03 211.974V215.74H1019.76V212.009Z" fill="#373B3D"/>
<path d="M1011.56 212.009L1007.83 211.974V215.74H1011.56V212.009Z" fill="#373B3D"/>
<path d="M1003.37 212.009L999.642 211.974V215.74H1003.37V212.009Z" fill="#373B3D"/>
<path d="M995.181 212.009L991.45 211.974V215.74H995.181V212.009Z" fill="#373B3D"/>
<path d="M986.989 212.01L983.258 211.975V215.741H986.989V212.01Z" fill="#373B3D"/>
<path d="M978.809 212.009L975.078 211.974V215.74H978.809V212.009Z" fill="#373B3D"/>
<path d="M970.617 212.009L966.886 211.974V215.74H970.617V212.009Z" fill="#373B3D"/>
<path d="M962.425 212.009L958.694 211.974V215.74H962.425V212.009Z" fill="#373B3D"/>
<path d="M954.233 212.009L950.502 211.974V215.74H954.233V212.009Z" fill="#373B3D"/>
<path d="M946.042 212.009L942.311 211.974V215.74H946.042V212.009Z" fill="#373B3D"/>
<path d="M937.85 212.009L934.119 211.974V215.74H937.85V212.009Z" fill="#373B3D"/>
<path d="M929.659 212.009L925.928 211.974V215.74H929.659V212.009Z" fill="#373B3D"/>
<path d="M921.478 212.009L917.747 211.974V215.74H921.478V212.009Z" fill="#373B3D"/>
<path d="M913.286 212.009L909.555 211.974V215.74H913.286V212.009Z" fill="#373B3D"/>
<path d="M905.095 212.009L901.364 211.974V215.74H905.095V212.009Z" fill="#373B3D"/>
<path d="M896.903 212.009L893.172 211.974V215.74H896.903V212.009Z" fill="#373B3D"/>
<path d="M888.711 212.01L884.98 211.975V215.741H888.711V212.01Z" fill="#373B3D"/>
<path d="M880.519 212.009L876.788 211.974V215.74H880.519V212.009Z" fill="#373B3D"/>
<path d="M872.328 212.009L868.597 211.974V215.74H872.328V212.009Z" fill="#373B3D"/>
<path d="M864.147 212.009L860.416 211.974V215.74H864.147V212.009Z" fill="#373B3D"/>
<path d="M855.956 212.009L852.225 211.974V215.74H855.956V212.009Z" fill="#373B3D"/>
<path d="M847.764 212.009L844.033 211.974V215.74H847.764V212.009Z" fill="#373B3D"/>
<path d="M839.572 212.009L835.841 211.974V215.74H839.572V212.009Z" fill="#373B3D"/>
<path d="M831.381 212.009L827.65 211.974V215.74H831.381V212.009Z" fill="#373B3D"/>
<path d="M823.189 212.009L819.458 211.974V215.74H823.189V212.009Z" fill="#373B3D"/>
<path d="M814.997 212.009L811.266 211.974V215.74H814.997V212.009Z" fill="#373B3D"/>
<path d="M806.805 212.01L803.074 211.975V215.741H806.805V212.01Z" fill="#373B3D"/>
<path d="M798.625 212.009L794.894 211.974V215.74H798.625V212.009Z" fill="#373B3D"/>
<path d="M790.433 212.009L786.702 211.974V215.74H790.433V212.009Z" fill="#373B3D"/>
<path d="M782.242 212.009L778.51 211.974V215.74H782.242V212.009Z" fill="#373B3D"/>
<path d="M757.666 212.009L753.935 211.974V215.74H757.666V212.009Z" fill="#373B3D"/>
<path d="M471.012 212.009L467.281 211.974V215.74H471.012V212.009Z" fill="#373B3D"/>
<path d="M462.821 212.009L459.09 211.974V215.74H462.821V212.009Z" fill="#373B3D"/>
<path d="M454.629 212.009L450.898 211.974V215.74H454.629V212.009Z" fill="#373B3D"/>
<path d="M446.449 212.009L442.718 211.974V215.74H446.449V212.009Z" fill="#373B3D"/>
<path d="M438.257 212.009L434.526 211.974V215.74H438.257V212.009Z" fill="#373B3D"/>
<path d="M430.065 212.009L426.334 211.974V215.74H430.065V212.009Z" fill="#373B3D"/>
<path d="M421.873 212.009L418.142 211.974V215.74H421.873V212.009Z" fill="#373B3D"/>
<path d="M413.682 212.009L409.951 211.974V215.74H413.682V212.009Z" fill="#373B3D"/>
<path d="M405.49 212.009L401.759 211.974V215.74H405.49V212.009Z" fill="#373B3D"/>
<path d="M397.298 212.009L393.567 211.974V215.74H397.298V212.009Z" fill="#373B3D"/>
<path d="M389.118 212.009L385.387 211.974V215.74H389.118V212.009Z" fill="#373B3D"/>
<path d="M380.926 212.009L377.195 211.974V215.74H380.926V212.009Z" fill="#373B3D"/>
<path d="M1150.8 203.865L1147.07 203.83V207.596H1150.8V203.865Z" fill="#373B3D"/>
<path d="M1085.28 203.865L1081.55 203.83V207.596H1085.28V203.865Z" fill="#373B3D"/>
<path d="M1077.09 203.865L1073.36 203.83V207.596H1077.09V203.865Z" fill="#373B3D"/>
<path d="M1068.9 203.865L1065.16 203.83V207.596H1068.9V203.865Z" fill="#373B3D"/>
<path d="M1060.7 203.865L1056.97 203.83V207.596H1060.7V203.865Z" fill="#373B3D"/>
<path d="M1052.51 203.865L1048.78 203.83V207.596H1052.51V203.865Z" fill="#373B3D"/>
<path d="M1044.32 203.865L1040.59 203.83V207.596H1044.32V203.865Z" fill="#373B3D"/>
<path d="M1036.14 203.865L1032.41 203.83V207.596H1036.14V203.865Z" fill="#373B3D"/>
<path d="M1027.95 203.865L1024.22 203.83V207.596H1027.95V203.865Z" fill="#373B3D"/>
<path d="M1019.76 203.865L1016.03 203.83V207.596H1019.76V203.865Z" fill="#373B3D"/>
<path d="M1011.56 203.865L1007.83 203.83V207.596H1011.56V203.865Z" fill="#373B3D"/>
<path d="M1003.37 203.865L999.642 203.83V207.596H1003.37V203.865Z" fill="#373B3D"/>
<path d="M995.181 203.865L991.45 203.83V207.596H995.181V203.865Z" fill="#373B3D"/>
<path d="M986.989 203.865L983.258 203.83V207.596H986.989V203.865Z" fill="#373B3D"/>
<path d="M978.809 203.865L975.078 203.83V207.596H978.809V203.865Z" fill="#373B3D"/>
<path d="M970.617 203.865L966.886 203.83V207.596H970.617V203.865Z" fill="#373B3D"/>
<path d="M962.425 203.865L958.694 203.83V207.596H962.425V203.865Z" fill="#373B3D"/>
<path d="M954.233 203.865L950.502 203.83V207.596H954.233V203.865Z" fill="#373B3D"/>
<path d="M946.042 203.865L942.311 203.83V207.596H946.042V203.865Z" fill="#373B3D"/>
<path d="M937.85 203.865L934.119 203.83V207.596H937.85V203.865Z" fill="#373B3D"/>
<path d="M929.659 203.865L925.928 203.83V207.596H929.659V203.865Z" fill="#373B3D"/>
<path d="M921.478 203.865L917.747 203.83V207.596H921.478V203.865Z" fill="#373B3D"/>
<path d="M913.286 203.865L909.555 203.83V207.596H913.286V203.865Z" fill="#373B3D"/>
<path d="M905.095 203.865L901.364 203.83V207.596H905.095V203.865Z" fill="#373B3D"/>
<path d="M896.903 203.865L893.172 203.83V207.596H896.903V203.865Z" fill="#373B3D"/>
<path d="M888.711 203.865L884.98 203.83V207.596H888.711V203.865Z" fill="#373B3D"/>
<path d="M880.519 203.865L876.788 203.83V207.596H880.519V203.865Z" fill="#373B3D"/>
<path d="M872.328 203.865L868.597 203.83V207.596H872.328V203.865Z" fill="#373B3D"/>
<path d="M864.147 203.865L860.416 203.83V207.596H864.147V203.865Z" fill="#373B3D"/>
<path d="M855.956 203.865L852.225 203.83V207.596H855.956V203.865Z" fill="#373B3D"/>
<path d="M847.764 203.865L844.033 203.83V207.596H847.764V203.865Z" fill="#373B3D"/>
<path d="M839.572 203.865L835.841 203.83V207.596H839.572V203.865Z" fill="#373B3D"/>
<path d="M823.189 203.865L819.458 203.83V207.596H823.189V203.865Z" fill="#373B3D"/>
<path d="M814.997 203.865L811.266 203.83V207.596H814.997V203.865Z" fill="#373B3D"/>
<path d="M806.805 203.865L803.074 203.83V207.596H806.805V203.865Z" fill="#373B3D"/>
<path d="M798.625 203.865L794.894 203.83V207.596H798.625V203.865Z" fill="#373B3D"/>
<path d="M790.433 203.865L786.702 203.83V207.596H790.433V203.865Z" fill="#373B3D"/>
<path d="M782.242 203.865L778.51 203.83V207.596H782.242V203.865Z" fill="#373B3D"/>
<path d="M741.294 203.865L737.563 203.83V207.596H741.294V203.865Z" fill="#373B3D"/>
<path d="M479.204 203.865L475.473 203.83V207.596H479.204V203.865Z" fill="#373B3D"/>
<path d="M471.012 203.865L467.281 203.83V207.596H471.012V203.865Z" fill="#373B3D"/>
<path d="M462.821 203.865L459.09 203.83V207.596H462.821V203.865Z" fill="#373B3D"/>
<path d="M454.629 203.865L450.898 203.83V207.596H454.629V203.865Z" fill="#373B3D"/>
<path d="M446.449 203.865L442.718 203.83V207.596H446.449V203.865Z" fill="#373B3D"/>
<path d="M438.257 203.865L434.526 203.83V207.596H438.257V203.865Z" fill="#373B3D"/>
<path d="M430.065 203.865L426.334 203.83V207.596H430.065V203.865Z" fill="#373B3D"/>
<path d="M421.873 203.865L418.142 203.83V207.596H421.873V203.865Z" fill="#373B3D"/>
<path d="M413.682 203.865L409.951 203.83V207.596H413.682V203.865Z" fill="#373B3D"/>
<path d="M405.49 203.865L401.759 203.83V207.596H405.49V203.865Z" fill="#373B3D"/>
<path d="M397.298 203.865L393.567 203.83V207.596H397.298V203.865Z" fill="#373B3D"/>
<path d="M389.118 203.865L385.387 203.83V207.596H389.118V203.865Z" fill="#373B3D"/>
<path d="M1052.51 195.708L1048.78 195.673V199.439H1052.51V195.708Z" fill="#373B3D"/>
<path d="M1044.32 195.708L1040.59 195.673V199.439H1044.32V195.708Z" fill="#373B3D"/>
<path d="M1036.14 195.708L1032.41 195.673V199.439H1036.14V195.708Z" fill="#373B3D"/>
<path d="M1027.95 195.708L1024.22 195.673V199.439H1027.95V195.708Z" fill="#373B3D"/>
<path d="M1019.76 195.708L1016.03 195.673V199.439H1019.76V195.708Z" fill="#373B3D"/>
<path d="M978.809 195.708L975.078 195.673V199.439H978.809V195.708Z" fill="#373B3D"/>
<path d="M970.617 195.708L966.886 195.673V199.439H970.617V195.708Z" fill="#373B3D"/>
<path d="M962.425 195.708L958.694 195.673V199.439H962.425V195.708Z" fill="#373B3D"/>
<path d="M954.233 195.708L950.502 195.673V199.439H954.233V195.708Z" fill="#373B3D"/>
<path d="M946.042 195.708L942.311 195.673V199.439H946.042V195.708Z" fill="#373B3D"/>
<path d="M937.85 195.708L934.119 195.673V199.439H937.85V195.708Z" fill="#373B3D"/>
<path d="M929.659 195.708L925.928 195.673V199.439H929.659V195.708Z" fill="#373B3D"/>
<path d="M921.478 195.708L917.747 195.673V199.439H921.478V195.708Z" fill="#373B3D"/>
<path d="M913.286 195.708L909.555 195.673V199.439H913.286V195.708Z" fill="#373B3D"/>
<path d="M905.095 195.708L901.364 195.673V199.439H905.095V195.708Z" fill="#373B3D"/>
<path d="M896.903 195.708L893.172 195.673V199.439H896.903V195.708Z" fill="#373B3D"/>
<path d="M888.711 195.708L884.98 195.673V199.439H888.711V195.708Z" fill="#373B3D"/>
<path d="M880.519 195.708L876.788 195.673V199.439H880.519V195.708Z" fill="#373B3D"/>
<path d="M872.328 195.708L868.597 195.673V199.439H872.328V195.708Z" fill="#373B3D"/>
<path d="M864.147 195.708L860.416 195.673V199.439H864.147V195.708Z" fill="#373B3D"/>
<path d="M855.956 195.708L852.225 195.673V199.439H855.956V195.708Z" fill="#373B3D"/>
<path d="M847.764 195.708L844.033 195.673V199.439H847.764V195.708Z" fill="#373B3D"/>
<path d="M839.572 195.708L835.841 195.673V199.439H839.572V195.708Z" fill="#373B3D"/>
<path d="M823.189 195.708L819.458 195.673V199.439H823.189V195.708Z" fill="#373B3D"/>
<path d="M814.997 195.708L811.266 195.673V199.439H814.997V195.708Z" fill="#373B3D"/>
<path d="M806.805 195.708L803.074 195.673V199.439H806.805V195.708Z" fill="#373B3D"/>
<path d="M790.433 195.708L786.702 195.673V199.439H790.433V195.708Z" fill="#373B3D"/>
<path d="M741.294 195.708L737.563 195.673V199.439H741.294V195.708Z" fill="#373B3D"/>
<path d="M733.103 195.708L729.372 195.673V199.439H733.103V195.708Z" fill="#373B3D"/>
<path d="M479.204 195.708L475.473 195.673V199.439H479.204V195.708Z" fill="#373B3D"/>
<path d="M479.204 212.009L475.473 211.974V215.74H479.204V212.009Z" fill="#373B3D"/>
<path d="M471.012 195.708L467.281 195.673V199.439H471.012V195.708Z" fill="#373B3D"/>
<path d="M462.821 195.708L459.09 195.673V199.439H462.821V195.708Z" fill="#373B3D"/>
<path d="M454.629 195.708L450.898 195.673V199.439H454.629V195.708Z" fill="#373B3D"/>
<path d="M446.449 195.708L442.718 195.673V199.439H446.449V195.708Z" fill="#373B3D"/>
<path d="M438.257 195.708L434.526 195.673V199.439H438.257V195.708Z" fill="#373B3D"/>
<path d="M430.065 195.708L426.334 195.673V199.439H430.065V195.708Z" fill="#373B3D"/>
<path d="M421.873 195.708L418.142 195.673V199.439H421.873V195.708Z" fill="#373B3D"/>
<path d="M413.682 195.708L409.951 195.673V199.439H413.682V195.708Z" fill="#373B3D"/>
<path d="M405.49 195.708L401.759 195.673V199.439H405.49V195.708Z" fill="#373B3D"/>
<path d="M397.298 195.708L393.567 195.673V199.439H397.298V195.708Z" fill="#373B3D"/>
<path d="M389.118 195.708L385.387 195.673V199.439H389.118V195.708Z" fill="#373B3D"/>
<path d="M380.926 195.708L377.195 195.673V199.439H380.926V195.708Z" fill="#373B3D"/>
<path d="M1036.14 187.551L1032.41 187.516V191.282H1036.14V187.551Z" fill="#373B3D"/>
<path d="M1027.95 187.551L1024.22 187.516V191.282H1027.95V187.551Z" fill="#373B3D"/>
<path d="M978.809 187.551L975.078 187.516V191.282H978.809V187.551Z" fill="#373B3D"/>
<path d="M970.617 187.551L966.886 187.516V191.282H970.617V187.551Z" fill="#373B3D"/>
<path d="M962.425 187.551L958.694 187.516V191.282H962.425V187.551Z" fill="#373B3D"/>
<path d="M954.233 187.551L950.502 187.516V191.282H954.233V187.551Z" fill="#373B3D"/>
<path d="M946.042 187.551L942.311 187.516V191.282H946.042V187.551Z" fill="#373B3D"/>
<path d="M937.85 187.551L934.119 187.516V191.282H937.85V187.551Z" fill="#373B3D"/>
<path d="M929.659 187.551L925.928 187.516V191.282H929.659V187.551Z" fill="#373B3D"/>
<path d="M921.478 187.551L917.747 187.516V191.282H921.478V187.551Z" fill="#373B3D"/>
<path d="M913.286 187.551L909.555 187.516V191.282H913.286V187.551Z" fill="#373B3D"/>
<path d="M905.095 187.551L901.364 187.516V191.282H905.095V187.551Z" fill="#373B3D"/>
<path d="M896.903 187.551L893.172 187.516V191.282H896.903V187.551Z" fill="#373B3D"/>
<path d="M888.711 187.552L884.98 187.517V191.283H888.711V187.552Z" fill="#373B3D"/>
<path d="M880.519 187.551L876.788 187.516V191.282H880.519V187.551Z" fill="#373B3D"/>
<path d="M872.328 187.551L868.597 187.516V191.282H872.328V187.551Z" fill="#373B3D"/>
<path d="M864.147 187.551L860.416 187.516V191.282H864.147V187.551Z" fill="#373B3D"/>
<path d="M855.956 187.551L852.225 187.516V191.282H855.956V187.551Z" fill="#373B3D"/>
<path d="M847.764 187.551L844.033 187.516V191.282H847.764V187.551Z" fill="#373B3D"/>
<path d="M839.572 187.551L835.841 187.516V191.282H839.572V187.551Z" fill="#373B3D"/>
<path d="M831.381 187.551L827.65 187.516V191.282H831.381V187.551Z" fill="#373B3D"/>
<path d="M790.433 187.551L786.702 187.516V191.282H790.433V187.551Z" fill="#373B3D"/>
<path d="M741.294 187.551L737.563 187.516V191.282H741.294V187.551Z" fill="#373B3D"/>
<path d="M733.103 187.551L729.372 187.516V191.282H733.103V187.551Z" fill="#373B3D"/>
<path d="M479.204 187.551L475.473 187.516V191.282H479.204V187.551Z" fill="#373B3D"/>
<path d="M471.012 187.551L467.281 187.516V191.282H471.012V187.551Z" fill="#373B3D"/>
<path d="M462.821 187.551L459.09 187.516V191.282H462.821V187.551Z" fill="#373B3D"/>
<path d="M454.629 187.551L450.898 187.516V191.282H454.629V187.551Z" fill="#373B3D"/>
<path d="M446.449 187.551L442.718 187.516V191.282H446.449V187.551Z" fill="#373B3D"/>
<path d="M438.257 187.551L434.526 187.516V191.282H438.257V187.551Z" fill="#373B3D"/>
<path d="M430.065 187.551L426.334 187.516V191.282H430.065V187.551Z" fill="#373B3D"/>
<path d="M421.873 187.551L418.142 187.516V191.282H421.873V187.551Z" fill="#373B3D"/>
<path d="M413.682 187.551L409.951 187.516V191.282H413.682V187.551Z" fill="#373B3D"/>
<path d="M405.49 187.551L401.759 187.516V191.282H405.49V187.551Z" fill="#373B3D"/>
<path d="M397.298 187.551L393.567 187.516V191.282H397.298V187.551Z" fill="#373B3D"/>
<path d="M389.118 187.551L385.387 187.516V191.282H389.118V187.551Z" fill="#373B3D"/>
<path d="M380.926 187.551L377.195 187.516V191.282H380.926V187.551Z" fill="#373B3D"/>
<path d="M978.809 179.407L975.078 179.372V183.138H978.809V179.407Z" fill="#373B3D"/>
<path d="M970.617 179.407L966.886 179.372V183.138H970.617V179.407Z" fill="#373B3D"/>
<path d="M946.042 179.407L942.311 179.372V183.138H946.042V179.407Z" fill="#373B3D"/>
<path d="M937.85 179.407L934.119 179.372V183.138H937.85V179.407Z" fill="#373B3D"/>
<path d="M929.659 179.407L925.928 179.372V183.138H929.659V179.407Z" fill="#373B3D"/>
<path d="M921.478 179.407L917.747 179.372V183.138H921.478V179.407Z" fill="#373B3D"/>
<path d="M905.095 179.407L901.364 179.372V183.138H905.095V179.407Z" fill="#373B3D"/>
<path d="M896.903 179.407L893.172 179.372V183.138H896.903V179.407Z" fill="#373B3D"/>
<path d="M888.711 179.407L884.98 179.372V183.138H888.711V179.407Z" fill="#373B3D"/>
<path d="M880.519 179.407L876.788 179.372V183.138H880.519V179.407Z" fill="#373B3D"/>
<path d="M872.328 179.407L868.597 179.372V183.138H872.328V179.407Z" fill="#373B3D"/>
<path d="M864.147 179.407L860.416 179.372V183.138H864.147V179.407Z" fill="#373B3D"/>
<path d="M855.956 179.407L852.225 179.372V183.138H855.956V179.407Z" fill="#373B3D"/>
<path d="M847.764 179.407L844.033 179.372V183.138H847.764V179.407Z" fill="#373B3D"/>
<path d="M839.572 179.407L835.841 179.372V183.138H839.572V179.407Z" fill="#373B3D"/>
<path d="M831.381 179.407L827.65 179.372V183.138H831.381V179.407Z" fill="#373B3D"/>
<path d="M790.433 179.407L786.702 179.372V183.138H790.433V179.407Z" fill="#373B3D"/>
<path d="M741.294 179.407L737.563 179.372V183.138H741.294V179.407Z" fill="#373B3D"/>
<path d="M479.204 179.407L475.473 179.372V183.138H479.204V179.407Z" fill="#373B3D"/>
<path d="M471.012 179.407L467.281 179.372V183.138H471.012V179.407Z" fill="#373B3D"/>
<path d="M462.821 179.407L459.09 179.372V183.138H462.821V179.407Z" fill="#373B3D"/>
<path d="M454.629 179.407L450.898 179.372V183.138H454.629V179.407Z" fill="#373B3D"/>
<path d="M446.449 179.407L442.718 179.372V183.138H446.449V179.407Z" fill="#373B3D"/>
<path d="M438.257 179.407L434.526 179.372V183.138H438.257V179.407Z" fill="#373B3D"/>
<path d="M430.065 179.407L426.334 179.372V183.138H430.065V179.407Z" fill="#373B3D"/>
<path d="M421.873 179.407L418.142 179.372V183.138H421.873V179.407Z" fill="#373B3D"/>
<path d="M413.682 179.407L409.951 179.372V183.138H413.682V179.407Z" fill="#373B3D"/>
<path d="M405.49 179.407L401.759 179.372V183.138H405.49V179.407Z" fill="#373B3D"/>
<path d="M397.298 179.407L393.567 179.372V183.138H397.298V179.407Z" fill="#373B3D"/>
<path d="M389.118 179.407L385.387 179.372V183.138H389.118V179.407Z" fill="#373B3D"/>
<path d="M380.926 179.407L377.195 179.372V183.138H380.926V179.407Z" fill="#373B3D"/>
<path d="M1027.95 171.251L1024.22 171.215V174.982H1027.95V171.251Z" fill="#373B3D"/>
<path d="M913.286 171.251L909.555 171.215V174.982H913.286V171.251Z" fill="#373B3D"/>
<path d="M905.095 171.251L901.364 171.215V174.982H905.095V171.251Z" fill="#373B3D"/>
<path d="M896.903 171.251L893.172 171.215V174.982H896.903V171.251Z" fill="#373B3D"/>
<path d="M888.711 171.251L884.98 171.216V174.982H888.711V171.251Z" fill="#373B3D"/>
<path d="M880.519 171.251L876.788 171.215V174.982H880.519V171.251Z" fill="#373B3D"/>
<path d="M872.328 171.251L868.597 171.215V174.982H872.328V171.251Z" fill="#373B3D"/>
<path d="M864.147 171.251L860.416 171.215V174.982H864.147V171.251Z" fill="#373B3D"/>
<path d="M855.956 171.251L852.225 171.215V174.982H855.956V171.251Z" fill="#373B3D"/>
<path d="M847.764 171.251L844.033 171.215V174.982H847.764V171.251Z" fill="#373B3D"/>
<path d="M749.474 171.251L745.743 171.215V174.982H749.474V171.251Z" fill="#373B3D"/>
<path d="M741.294 171.251L737.563 171.215V174.982H741.294V171.251Z" fill="#373B3D"/>
<path d="M487.396 171.251L483.665 171.215V174.982H487.396V171.251Z" fill="#373B3D"/>
<path d="M479.204 171.251L475.473 171.215V174.982H479.204V171.251Z" fill="#373B3D"/>
<path d="M471.012 171.251L467.281 171.215V174.982H471.012V171.251Z" fill="#373B3D"/>
<path d="M462.821 171.251L459.09 171.215V174.982H462.821V171.251Z" fill="#373B3D"/>
<path d="M454.629 171.251L450.898 171.215V174.982H454.629V171.251Z" fill="#373B3D"/>
<path d="M446.449 171.251L442.718 171.215V174.982H446.449V171.251Z" fill="#373B3D"/>
<path d="M438.257 171.251L434.526 171.215V174.982H438.257V171.251Z" fill="#373B3D"/>
<path d="M430.065 171.251L426.334 171.215V174.982H430.065V171.251Z" fill="#373B3D"/>
<path d="M421.873 171.251L418.142 171.215V174.982H421.873V171.251Z" fill="#373B3D"/>
<path d="M413.682 171.251L409.951 171.215V174.982H413.682V171.251Z" fill="#373B3D"/>
<path d="M405.49 171.251L401.759 171.215V174.982H405.49V171.251Z" fill="#373B3D"/>
<path d="M397.298 171.251L393.567 171.215V174.982H397.298V171.251Z" fill="#373B3D"/>
<path d="M389.118 171.251L385.387 171.215V174.982H389.118V171.251Z" fill="#373B3D"/>
<path d="M380.926 171.251L377.195 171.215V174.982H380.926V171.251Z" fill="#373B3D"/>
<path d="M372.734 171.251L369.003 171.215V174.982H372.734V171.251Z" fill="#373B3D"/>
<path d="M921.478 163.094L917.747 163.059V166.825H921.478V163.094Z" fill="#373B3D"/>
<path d="M913.286 163.094L909.555 163.059V166.825H913.286V163.094Z" fill="#373B3D"/>
<path d="M905.095 163.094L901.364 163.059V166.825H905.095V163.094Z" fill="#373B3D"/>
<path d="M896.903 163.094L893.172 163.059V166.825H896.903V163.094Z" fill="#373B3D"/>
<path d="M888.711 163.094L884.98 163.059V166.825H888.711V163.094Z" fill="#373B3D"/>
<path d="M880.519 163.094L876.788 163.059V166.825H880.519V163.094Z" fill="#373B3D"/>
<path d="M872.328 163.094L868.597 163.059V166.825H872.328V163.094Z" fill="#373B3D"/>
<path d="M864.147 163.094L860.416 163.059V166.825H864.147V163.094Z" fill="#373B3D"/>
<path d="M855.956 163.094L852.225 163.059V166.825H855.956V163.094Z" fill="#373B3D"/>
<path d="M847.764 163.094L844.033 163.059V166.825H847.764V163.094Z" fill="#373B3D"/>
<path d="M749.474 163.094L745.743 163.059V166.825H749.474V163.094Z" fill="#373B3D"/>
<path d="M487.396 163.094L483.665 163.059V166.825H487.396V163.094Z" fill="#373B3D"/>
<path d="M479.204 163.094L475.473 163.059V166.825H479.204V163.094Z" fill="#373B3D"/>
<path d="M471.012 163.094L467.281 163.059V166.825H471.012V163.094Z" fill="#373B3D"/>
<path d="M462.821 163.094L459.09 163.059V166.825H462.821V163.094Z" fill="#373B3D"/>
<path d="M454.629 163.094L450.898 163.059V166.825H454.629V163.094Z" fill="#373B3D"/>
<path d="M446.449 163.094L442.718 163.059V166.825H446.449V163.094Z" fill="#373B3D"/>
<path d="M438.257 163.094L434.526 163.059V166.825H438.257V163.094Z" fill="#373B3D"/>
<path d="M430.065 163.094L426.334 163.059V166.825H430.065V163.094Z" fill="#373B3D"/>
<path d="M421.873 163.094L418.142 163.059V166.825H421.873V163.094Z" fill="#373B3D"/>
<path d="M413.682 163.094L409.951 163.059V166.825H413.682V163.094Z" fill="#373B3D"/>
<path d="M405.49 163.094L401.759 163.059V166.825H405.49V163.094Z" fill="#373B3D"/>
<path d="M397.298 163.094L393.567 163.059V166.825H397.298V163.094Z" fill="#373B3D"/>
<path d="M389.118 163.094L385.387 163.059V166.825H389.118V163.094Z" fill="#373B3D"/>
<path d="M380.926 163.094L377.195 163.059V166.825H380.926V163.094Z" fill="#373B3D"/>
<path d="M372.734 163.094L369.003 163.059V166.825H372.734V163.094Z" fill="#373B3D"/>
<path d="M1052.51 154.938L1048.78 154.902V158.669H1052.51V154.938Z" fill="#373B3D"/>
<path d="M1044.32 154.938L1040.59 154.902V158.669H1044.32V154.938Z" fill="#373B3D"/>
<path d="M1027.95 154.938L1024.22 154.902V158.669H1027.95V154.938Z" fill="#373B3D"/>
<path d="M1019.76 154.938L1016.03 154.902V158.669H1019.76V154.938Z" fill="#373B3D"/>
<path d="M1011.56 154.938L1007.83 154.902V158.669H1011.56V154.938Z" fill="#373B3D"/>
<path d="M929.659 154.938L925.928 154.902V158.669H929.659V154.938Z" fill="#373B3D"/>
<path d="M921.478 154.938L917.747 154.902V158.669H921.478V154.938Z" fill="#373B3D"/>
<path d="M913.286 154.938L909.555 154.902V158.669H913.286V154.938Z" fill="#373B3D"/>
<path d="M905.095 154.938L901.364 154.902V158.669H905.095V154.938Z" fill="#373B3D"/>
<path d="M896.903 154.938L893.172 154.902V158.669H896.903V154.938Z" fill="#373B3D"/>
<path d="M888.711 154.938L884.98 154.902V158.669H888.711V154.938Z" fill="#373B3D"/>
<path d="M880.519 154.938L876.788 154.902V158.669H880.519V154.938Z" fill="#373B3D"/>
<path d="M872.328 154.938L868.597 154.902V158.669H872.328V154.938Z" fill="#373B3D"/>
<path d="M864.147 154.938L860.416 154.902V158.669H864.147V154.938Z" fill="#373B3D"/>
<path d="M855.956 154.938L852.225 154.902V158.669H855.956V154.938Z" fill="#373B3D"/>
<path d="M757.666 154.938L753.935 154.902V158.669H757.666V154.938Z" fill="#373B3D"/>
<path d="M749.474 154.938L745.743 154.902V158.669H749.474V154.938Z" fill="#373B3D"/>
<path d="M487.396 154.938L483.665 154.902V158.669H487.396V154.938Z" fill="#373B3D"/>
<path d="M479.204 154.938L475.473 154.902V158.669H479.204V154.938Z" fill="#373B3D"/>
<path d="M471.012 154.938L467.281 154.902V158.669H471.012V154.938Z" fill="#373B3D"/>
<path d="M462.821 154.938L459.09 154.902V158.669H462.821V154.938Z" fill="#373B3D"/>
<path d="M454.629 154.938L450.898 154.902V158.669H454.629V154.938Z" fill="#373B3D"/>
<path d="M446.449 154.938L442.718 154.902V158.669H446.449V154.938Z" fill="#373B3D"/>
<path d="M438.257 154.938L434.526 154.902V158.669H438.257V154.938Z" fill="#373B3D"/>
<path d="M430.065 154.938L426.334 154.902V158.669H430.065V154.938Z" fill="#373B3D"/>
<path d="M421.873 154.938L418.142 154.902V158.669H421.873V154.938Z" fill="#373B3D"/>
<path d="M413.682 154.938L409.951 154.902V158.669H413.682V154.938Z" fill="#373B3D"/>
<path d="M405.49 154.938L401.759 154.902V158.669H405.49V154.938Z" fill="#373B3D"/>
<path d="M397.298 154.938L393.567 154.902V158.669H397.298V154.938Z" fill="#373B3D"/>
<path d="M389.118 154.938L385.387 154.902V158.669H389.118V154.938Z" fill="#373B3D"/>
<path d="M380.926 154.938L377.195 154.902V158.669H380.926V154.938Z" fill="#373B3D"/>
<path d="M372.734 154.938L369.003 154.902V158.669H372.734V154.938Z" fill="#373B3D"/>
<path d="M1027.95 146.793L1024.22 146.757V150.524H1027.95V146.793Z" fill="#373B3D"/>
<path d="M1019.76 146.793L1016.03 146.757V150.524H1019.76V146.793Z" fill="#373B3D"/>
<path d="M929.659 146.793L925.928 146.757V150.524H929.659V146.793Z" fill="#373B3D"/>
<path d="M921.478 146.793L917.747 146.757V150.524H921.478V146.793Z" fill="#373B3D"/>
<path d="M913.286 146.793L909.555 146.757V150.524H913.286V146.793Z" fill="#373B3D"/>
<path d="M905.095 146.793L901.364 146.757V150.524H905.095V146.793Z" fill="#373B3D"/>
<path d="M896.903 146.793L893.172 146.757V150.524H896.903V146.793Z" fill="#373B3D"/>
<path d="M888.711 146.793L884.98 146.758V150.524H888.711V146.793Z" fill="#373B3D"/>
<path d="M880.519 146.793L876.788 146.757V150.524H880.519V146.793Z" fill="#373B3D"/>
<path d="M872.328 146.793L868.597 146.757V150.524H872.328V146.793Z" fill="#373B3D"/>
<path d="M864.147 146.793L860.416 146.757V150.524H864.147V146.793Z" fill="#373B3D"/>
<path d="M774.05 146.793L770.319 146.757V150.524H774.05V146.793Z" fill="#373B3D"/>
<path d="M765.858 146.793L762.127 146.757V150.524H765.858V146.793Z" fill="#373B3D"/>
<path d="M757.666 146.793L753.935 146.757V150.524H757.666V146.793Z" fill="#373B3D"/>
<path d="M487.396 146.793L483.665 146.757V150.524H487.396V146.793Z" fill="#373B3D"/>
<path d="M479.204 146.793L475.473 146.757V150.524H479.204V146.793Z" fill="#373B3D"/>
<path d="M471.012 146.793L467.281 146.757V150.524H471.012V146.793Z" fill="#373B3D"/>
<path d="M462.821 146.793L459.09 146.757V150.524H462.821V146.793Z" fill="#373B3D"/>
<path d="M454.629 146.793L450.898 146.757V150.524H454.629V146.793Z" fill="#373B3D"/>
<path d="M446.449 146.793L442.718 146.757V150.524H446.449V146.793Z" fill="#373B3D"/>
<path d="M438.257 146.793L434.526 146.757V150.524H438.257V146.793Z" fill="#373B3D"/>
<path d="M430.065 146.793L426.334 146.757V150.524H430.065V146.793Z" fill="#373B3D"/>
<path d="M421.873 146.793L418.142 146.757V150.524H421.873V146.793Z" fill="#373B3D"/>
<path d="M413.682 146.793L409.951 146.757V150.524H413.682V146.793Z" fill="#373B3D"/>
<path d="M405.49 146.793L401.759 146.757V150.524H405.49V146.793Z" fill="#373B3D"/>
<path d="M397.298 146.793L393.567 146.757V150.524H397.298V146.793Z" fill="#373B3D"/>
<path d="M389.118 146.793L385.387 146.757V150.524H389.118V146.793Z" fill="#373B3D"/>
<path d="M380.926 146.793L377.195 146.757V150.524H380.926V146.793Z" fill="#373B3D"/>
<path d="M372.734 146.793L369.003 146.757V150.524H372.734V146.793Z" fill="#373B3D"/>
<path d="M364.543 146.793L360.812 146.757V150.524H364.543V146.793Z" fill="#373B3D"/>
<path d="M929.659 138.636L925.928 138.601V142.367H929.659V138.636Z" fill="#373B3D"/>
<path d="M921.478 138.636L917.747 138.601V142.367H921.478V138.636Z" fill="#373B3D"/>
<path d="M913.286 138.636L909.555 138.601V142.367H913.286V138.636Z" fill="#373B3D"/>
<path d="M905.095 138.636L901.364 138.601V142.367H905.095V138.636Z" fill="#373B3D"/>
<path d="M896.903 138.636L893.172 138.601V142.367H896.903V138.636Z" fill="#373B3D"/>
<path d="M888.711 138.636L884.98 138.601V142.367H888.711V138.636Z" fill="#373B3D"/>
<path d="M782.242 138.636L778.51 138.601V142.367H782.242V138.636Z" fill="#373B3D"/>
<path d="M774.05 138.636L770.319 138.601V142.367H774.05V138.636Z" fill="#373B3D"/>
<path d="M479.204 138.636L475.473 138.601V142.367H479.204V138.636Z" fill="#373B3D"/>
<path d="M471.012 138.636L467.281 138.601V142.367H471.012V138.636Z" fill="#373B3D"/>
<path d="M462.821 138.636L459.09 138.601V142.367H462.821V138.636Z" fill="#373B3D"/>
<path d="M454.629 138.636L450.898 138.601V142.367H454.629V138.636Z" fill="#373B3D"/>
<path d="M446.449 138.636L442.718 138.601V142.367H446.449V138.636Z" fill="#373B3D"/>
<path d="M438.257 138.636L434.526 138.601V142.367H438.257V138.636Z" fill="#373B3D"/>
<path d="M430.065 138.636L426.334 138.601V142.367H430.065V138.636Z" fill="#373B3D"/>
<path d="M421.873 138.636L418.142 138.601V142.367H421.873V138.636Z" fill="#373B3D"/>
<path d="M413.682 138.636L409.951 138.601V142.367H413.682V138.636Z" fill="#373B3D"/>
<path d="M405.49 138.636L401.759 138.601V142.367H405.49V138.636Z" fill="#373B3D"/>
<path d="M397.298 138.636L393.567 138.601V142.367H397.298V138.636Z" fill="#373B3D"/>
<path d="M389.118 138.636L385.387 138.601V142.367H389.118V138.636Z" fill="#373B3D"/>
<path d="M380.926 138.636L377.195 138.601V142.367H380.926V138.636Z" fill="#373B3D"/>
<path d="M372.734 138.636L369.003 138.601V142.367H372.734V138.636Z" fill="#373B3D"/>
<path d="M364.543 138.636L360.812 138.601V142.367H364.543V138.636Z" fill="#373B3D"/>
<path d="M356.351 138.636L352.62 138.601V142.367H356.351V138.636Z" fill="#373B3D"/>
<path d="M348.159 138.636L344.428 138.601V142.367H348.159V138.636Z" fill="#373B3D"/>
<path d="M339.968 138.636L336.237 138.601V142.367H339.968V138.636Z" fill="#373B3D"/>
<path d="M331.775 138.636L328.044 138.601V142.367H331.775V138.636Z" fill="#373B3D"/>
<path d="M905.095 130.48L901.364 130.445V134.211H905.095V130.48Z" fill="#373B3D"/>
<path d="M905.095 122.336L901.364 122.3V126.067H905.095V122.336Z" fill="#373B3D"/>
<path d="M896.903 130.48L893.172 130.445V134.211H896.903V130.48Z" fill="#373B3D"/>
<path d="M610.249 130.48L606.518 130.445V134.211H610.249V130.48Z" fill="#373B3D"/>
<path d="M495.587 130.48L491.856 130.445V134.211H495.587V130.48Z" fill="#373B3D"/>
<path d="M487.396 130.48L483.665 130.445V134.211H487.396V130.48Z" fill="#373B3D"/>
<path d="M479.204 130.48L475.473 130.445V134.211H479.204V130.48Z" fill="#373B3D"/>
<path d="M471.012 130.48L467.281 130.445V134.211H471.012V130.48Z" fill="#373B3D"/>
<path d="M462.821 130.48L459.09 130.445V134.211H462.821V130.48Z" fill="#373B3D"/>
<path d="M454.629 130.48L450.898 130.445V134.211H454.629V130.48Z" fill="#373B3D"/>
<path d="M446.449 130.48L442.718 130.445V134.211H446.449V130.48Z" fill="#373B3D"/>
<path d="M438.257 130.48L434.526 130.445V134.211H438.257V130.48Z" fill="#373B3D"/>
<path d="M430.065 130.48L426.334 130.445V134.211H430.065V130.48Z" fill="#373B3D"/>
<path d="M421.873 130.48L418.142 130.445V134.211H421.873V130.48Z" fill="#373B3D"/>
<path d="M413.682 130.48L409.951 130.445V134.211H413.682V130.48Z" fill="#373B3D"/>
<path d="M405.49 130.48L401.759 130.445V134.211H405.49V130.48Z" fill="#373B3D"/>
<path d="M397.298 130.48L393.567 130.445V134.211H397.298V130.48Z" fill="#373B3D"/>
<path d="M389.118 130.48L385.387 130.445V134.211H389.118V130.48Z" fill="#373B3D"/>
<path d="M380.926 130.48L377.195 130.445V134.211H380.926V130.48Z" fill="#373B3D"/>
<path d="M372.734 130.48L369.003 130.445V134.211H372.734V130.48Z" fill="#373B3D"/>
<path d="M364.543 130.48L360.812 130.445V134.211H364.543V130.48Z" fill="#373B3D"/>
<path d="M356.351 130.48L352.62 130.445V134.211H356.351V130.48Z" fill="#373B3D"/>
<path d="M348.159 130.48L344.428 130.445V134.211H348.159V130.48Z" fill="#373B3D"/>
<path d="M339.968 130.48L336.237 130.445V134.211H339.968V130.48Z" fill="#373B3D"/>
<path d="M331.775 130.48L328.044 130.445V134.211H331.775V130.48Z" fill="#373B3D"/>
<path d="M634.813 122.336L631.082 122.3V126.067H634.813V122.336Z" fill="#373B3D"/>
<path d="M618.441 122.336L614.71 122.3V126.067H618.441V122.336Z" fill="#373B3D"/>
<path d="M610.249 122.336L606.518 122.3V126.067H610.249V122.336Z" fill="#373B3D"/>
<path d="M487.396 122.336L483.665 122.3V126.067H487.396V122.336Z" fill="#373B3D"/>
<path d="M479.204 122.336L475.473 122.3V126.067H479.204V122.336Z" fill="#373B3D"/>
<path d="M471.012 122.336L467.281 122.3V126.067H471.012V122.336Z" fill="#373B3D"/>
<path d="M462.821 122.336L459.09 122.3V126.067H462.821V122.336Z" fill="#373B3D"/>
<path d="M454.629 122.336L450.898 122.3V126.067H454.629V122.336Z" fill="#373B3D"/>
<path d="M446.449 122.336L442.718 122.3V126.067H446.449V122.336Z" fill="#373B3D"/>
<path d="M438.257 122.336L434.526 122.3V126.067H438.257V122.336Z" fill="#373B3D"/>
<path d="M430.065 122.336L426.334 122.3V126.067H430.065V122.336Z" fill="#373B3D"/>
<path d="M421.873 122.336L418.142 122.3V126.067H421.873V122.336Z" fill="#373B3D"/>
<path d="M413.682 122.336L409.951 122.3V126.067H413.682V122.336Z" fill="#373B3D"/>
<path d="M405.49 122.336L401.759 122.3V126.067H405.49V122.336Z" fill="#373B3D"/>
<path d="M397.298 122.336L393.567 122.3V126.067H397.298V122.336Z" fill="#373B3D"/>
<path d="M389.118 122.336L385.387 122.3V126.067H389.118V122.336Z" fill="#373B3D"/>
<path d="M380.926 122.336L377.195 122.3V126.067H380.926V122.336Z" fill="#373B3D"/>
<path d="M372.734 122.336L369.003 122.3V126.067H372.734V122.336Z" fill="#373B3D"/>
<path d="M364.543 122.336L360.812 122.3V126.067H364.543V122.336Z" fill="#373B3D"/>
<path d="M356.351 122.336L352.62 122.3V126.067H356.351V122.336Z" fill="#373B3D"/>
<path d="M348.159 122.336L344.428 122.3V126.067H348.159V122.336Z" fill="#373B3D"/>
<path d="M339.968 122.336L336.237 122.3V126.067H339.968V122.336Z" fill="#373B3D"/>
<path d="M331.775 122.336L328.044 122.3V126.067H331.775V122.336Z" fill="#373B3D"/>
<path d="M634.813 114.179L631.082 114.144V117.91H634.813V114.179Z" fill="#373B3D"/>
<path d="M618.441 114.179L614.71 114.144V117.91H618.441V114.179Z" fill="#373B3D"/>
<path d="M610.249 114.179L606.518 114.144V117.91H610.249V114.179Z" fill="#373B3D"/>
<path d="M495.587 114.179L491.856 114.144V117.91H495.587V114.179Z" fill="#373B3D"/>
<path d="M487.396 114.179L483.665 114.144V117.91H487.396V114.179Z" fill="#373B3D"/>
<path d="M479.204 114.179L475.473 114.144V117.91H479.204V114.179Z" fill="#373B3D"/>
<path d="M471.012 114.179L467.281 114.144V117.91H471.012V114.179Z" fill="#373B3D"/>
<path d="M462.821 114.179L459.09 114.144V117.91H462.821V114.179Z" fill="#373B3D"/>
<path d="M454.629 114.179L450.898 114.144V117.91H454.629V114.179Z" fill="#373B3D"/>
<path d="M446.449 114.179L442.718 114.144V117.91H446.449V114.179Z" fill="#373B3D"/>
<path d="M438.257 114.179L434.526 114.144V117.91H438.257V114.179Z" fill="#373B3D"/>
<path d="M430.065 114.179L426.334 114.144V117.91H430.065V114.179Z" fill="#373B3D"/>
<path d="M421.873 114.179L418.142 114.144V117.91H421.873V114.179Z" fill="#373B3D"/>
<path d="M413.682 114.179L409.951 114.144V117.91H413.682V114.179Z" fill="#373B3D"/>
<path d="M405.49 114.179L401.759 114.144V117.91H405.49V114.179Z" fill="#373B3D"/>
<path d="M397.298 114.179L393.567 114.144V117.91H397.298V114.179Z" fill="#373B3D"/>
<path d="M389.118 114.179L385.387 114.144V117.91H389.118V114.179Z" fill="#373B3D"/>
<path d="M380.926 114.179L377.195 114.144V117.91H380.926V114.179Z" fill="#373B3D"/>
<path d="M372.734 114.179L369.003 114.144V117.91H372.734V114.179Z" fill="#373B3D"/>
<path d="M364.543 114.179L360.812 114.144V117.91H364.543V114.179Z" fill="#373B3D"/>
<path d="M356.351 114.179L352.62 114.144V117.91H356.351V114.179Z" fill="#373B3D"/>
<path d="M348.159 114.179L344.428 114.144V117.91H348.159V114.179Z" fill="#373B3D"/>
<path d="M339.968 114.179L336.237 114.144V117.91H339.968V114.179Z" fill="#373B3D"/>
<path d="M331.775 114.179L328.044 114.144V117.91H331.775V114.179Z" fill="#373B3D"/>
<path d="M323.596 114.179L319.865 114.144V117.91H323.596V114.179Z" fill="#373B3D"/>
<path d="M905.095 106.022L901.364 105.987V109.753H905.095V106.022Z" fill="#373B3D"/>
<path d="M896.903 106.022L893.172 105.987V109.753H896.903V106.022Z" fill="#373B3D"/>
<path d="M888.711 106.022L884.98 105.987V109.753H888.711V106.022Z" fill="#373B3D"/>
<path d="M626.633 106.022L622.902 105.987V109.753H626.633V106.022Z" fill="#373B3D"/>
<path d="M618.441 106.022L614.71 105.987V109.753H618.441V106.022Z" fill="#373B3D"/>
<path d="M610.249 106.022L606.518 105.987V109.753H610.249V106.022Z" fill="#373B3D"/>
<path d="M602.057 106.022L598.326 105.987V109.753H602.057V106.022Z" fill="#373B3D"/>
<path d="M487.396 106.022L483.665 105.987V109.753H487.396V106.022Z" fill="#373B3D"/>
<path d="M479.204 106.022L475.473 105.987V109.753H479.204V106.022Z" fill="#373B3D"/>
<path d="M471.012 106.022L467.281 105.987V109.753H471.012V106.022Z" fill="#373B3D"/>
<path d="M462.821 106.022L459.09 105.987V109.753H462.821V106.022Z" fill="#373B3D"/>
<path d="M454.629 106.022L450.898 105.987V109.753H454.629V106.022Z" fill="#373B3D"/>
<path d="M446.449 106.022L442.718 105.987V109.753H446.449V106.022Z" fill="#373B3D"/>
<path d="M438.257 106.022L434.526 105.987V109.753H438.257V106.022Z" fill="#373B3D"/>
<path d="M430.065 106.022L426.334 105.987V109.753H430.065V106.022Z" fill="#373B3D"/>
<path d="M421.873 106.022L418.142 105.987V109.753H421.873V106.022Z" fill="#373B3D"/>
<path d="M413.682 106.022L409.951 105.987V109.753H413.682V106.022Z" fill="#373B3D"/>
<path d="M405.49 106.022L401.759 105.987V109.753H405.49V106.022Z" fill="#373B3D"/>
<path d="M397.298 106.022L393.567 105.987V109.753H397.298V106.022Z" fill="#373B3D"/>
<path d="M389.118 106.022L385.387 105.987V109.753H389.118V106.022Z" fill="#373B3D"/>
<path d="M380.926 106.022L377.195 105.987V109.753H380.926V106.022Z" fill="#373B3D"/>
<path d="M372.734 106.022L369.003 105.987V109.753H372.734V106.022Z" fill="#373B3D"/>
<path d="M364.543 106.022L360.812 105.987V109.753H364.543V106.022Z" fill="#373B3D"/>
<path d="M356.351 106.022L352.62 105.987V109.753H356.351V106.022Z" fill="#373B3D"/>
<path d="M348.159 106.022L344.428 105.987V109.753H348.159V106.022Z" fill="#373B3D"/>
<path d="M339.968 106.022L336.237 105.987V109.753H339.968V106.022Z" fill="#373B3D"/>
<path d="M331.775 106.022L328.044 105.987V109.753H331.775V106.022Z" fill="#373B3D"/>
<path d="M323.596 106.022L319.865 105.987V109.753H323.596V106.022Z" fill="#373B3D"/>
<path d="M896.903 97.8776L893.172 97.8423V101.609H896.903V97.8776Z" fill="#373B3D"/>
<path d="M880.519 97.8776L876.788 97.8423V101.609H880.519V97.8776Z" fill="#373B3D"/>
<path d="M618.441 97.8776L614.71 97.8423V101.609H618.441V97.8776Z" fill="#373B3D"/>
<path d="M610.249 97.8776L606.518 97.8423V101.609H610.249V97.8776Z" fill="#373B3D"/>
<path d="M602.057 97.8776L598.326 97.8423V101.609H602.057V97.8776Z" fill="#373B3D"/>
<path d="M495.587 97.8776L491.856 97.8423V101.609H495.587V97.8776Z" fill="#373B3D"/>
<path d="M487.396 97.8776L483.665 97.8423V101.609H487.396V97.8776Z" fill="#373B3D"/>
<path d="M479.204 97.8776L475.473 97.8423V101.609H479.204V97.8776Z" fill="#373B3D"/>
<path d="M471.012 97.8776L467.281 97.8423V101.609H471.012V97.8776Z" fill="#373B3D"/>
<path d="M462.821 97.8776L459.09 97.8423V101.609H462.821V97.8776Z" fill="#373B3D"/>
<path d="M454.629 97.8776L450.898 97.8423V101.609H454.629V97.8776Z" fill="#373B3D"/>
<path d="M446.449 97.8776L442.718 97.8423V101.609H446.449V97.8776Z" fill="#373B3D"/>
<path d="M438.257 97.8776L434.526 97.8423V101.609H438.257V97.8776Z" fill="#373B3D"/>
<path d="M430.065 97.8776L426.334 97.8423V101.609H430.065V97.8776Z" fill="#373B3D"/>
<path d="M421.873 97.8776L418.142 97.8423V101.609H421.873V97.8776Z" fill="#373B3D"/>
<path d="M413.682 97.8776L409.951 97.8423V101.609H413.682V97.8776Z" fill="#373B3D"/>
<path d="M405.49 97.8776L401.759 97.8423V101.609H405.49V97.8776Z" fill="#373B3D"/>
<path d="M397.298 97.8776L393.567 97.8423V101.609H397.298V97.8776Z" fill="#373B3D"/>
<path d="M389.118 97.8776L385.387 97.8423V101.609H389.118V97.8776Z" fill="#373B3D"/>
<path d="M380.926 97.8776L377.195 97.8423V101.609H380.926V97.8776Z" fill="#373B3D"/>
<path d="M372.734 97.8776L369.003 97.8423V101.609H372.734V97.8776Z" fill="#373B3D"/>
<path d="M364.543 97.8776L360.812 97.8423V101.609H364.543V97.8776Z" fill="#373B3D"/>
<path d="M356.351 97.8776L352.62 97.8423V101.609H356.351V97.8776Z" fill="#373B3D"/>
<path d="M348.159 97.8776L344.428 97.8423V101.609H348.159V97.8776Z" fill="#373B3D"/>
<path d="M339.968 97.8776L336.237 97.8423V101.609H339.968V97.8776Z" fill="#373B3D"/>
<path d="M888.711 89.7214L884.98 89.686V93.4524H888.711V89.7214Z" fill="#373B3D"/>
<path d="M880.519 89.7214L876.788 89.686V93.4524H880.519V89.7214Z" fill="#373B3D"/>
<path d="M872.328 89.7214L868.597 89.686V93.4524H872.328V89.7214Z" fill="#373B3D"/>
<path d="M814.997 89.7214L811.266 89.686V93.4524H814.997V89.7214Z" fill="#373B3D"/>
<path d="M643.005 89.7214L639.274 89.686V93.4524H643.005V89.7214Z" fill="#373B3D"/>
<path d="M634.813 89.7214L631.082 89.686V93.4524H634.813V89.7214Z" fill="#373B3D"/>
<path d="M626.633 89.7214L622.902 89.686V93.4524H626.633V89.7214Z" fill="#373B3D"/>
<path d="M618.441 89.7214L614.71 89.686V93.4524H618.441V89.7214Z" fill="#373B3D"/>
<path d="M610.249 89.7214L606.518 89.686V93.4524H610.249V89.7214Z" fill="#373B3D"/>
<path d="M602.057 89.7214L598.326 89.686V93.4524H602.057V89.7214Z" fill="#373B3D"/>
<path d="M495.587 89.7214L491.856 89.686V93.4524H495.587V89.7214Z" fill="#373B3D"/>
<path d="M487.396 89.7214L483.665 89.686V93.4524H487.396V89.7214Z" fill="#373B3D"/>
<path d="M479.204 89.7214L475.473 89.686V93.4524H479.204V89.7214Z" fill="#373B3D"/>
<path d="M471.012 89.7214L467.281 89.686V93.4524H471.012V89.7214Z" fill="#373B3D"/>
<path d="M462.821 89.7214L459.09 89.686V93.4524H462.821V89.7214Z" fill="#373B3D"/>
<path d="M454.629 89.7214L450.898 89.686V93.4524H454.629V89.7214Z" fill="#373B3D"/>
<path d="M446.449 89.7214L442.718 89.686V93.4524H446.449V89.7214Z" fill="#373B3D"/>
<path d="M438.257 89.7214L434.526 89.686V93.4524H438.257V89.7214Z" fill="#373B3D"/>
<path d="M430.065 89.7214L426.334 89.686V93.4524H430.065V89.7214Z" fill="#373B3D"/>
<path d="M421.873 89.7214L418.142 89.686V93.4524H421.873V89.7214Z" fill="#373B3D"/>
<path d="M413.682 89.7214L409.951 89.686V93.4524H413.682V89.7214Z" fill="#373B3D"/>
<path d="M405.49 89.7214L401.759 89.686V93.4524H405.49V89.7214Z" fill="#373B3D"/>
<path d="M397.298 89.7214L393.567 89.686V93.4524H397.298V89.7214Z" fill="#373B3D"/>
<path d="M389.118 89.7214L385.387 89.686V93.4524H389.118V89.7214Z" fill="#373B3D"/>
<path d="M380.926 89.7214L377.195 89.686V93.4524H380.926V89.7214Z" fill="#373B3D"/>
<path d="M372.734 89.7214L369.003 89.686V93.4524H372.734V89.7214Z" fill="#373B3D"/>
<path d="M364.543 89.7214L360.812 89.686V93.4524H364.543V89.7214Z" fill="#373B3D"/>
<path d="M356.351 89.7214L352.62 89.686V93.4524H356.351V89.7214Z" fill="#373B3D"/>
<path d="M348.159 89.7214L344.428 89.686V93.4524H348.159V89.7214Z" fill="#373B3D"/>
<path d="M880.519 81.5646L876.788 81.5293V85.2956H880.519V81.5646Z" fill="#373B3D"/>
<path d="M872.328 81.5646L868.597 81.5293V85.2956H872.328V81.5646Z" fill="#373B3D"/>
<path d="M864.147 81.5646L860.416 81.5293V85.2956H864.147V81.5646Z" fill="#373B3D"/>
<path d="M643.005 81.5646L639.274 81.5293V85.2956H643.005V81.5646Z" fill="#373B3D"/>
<path d="M634.813 81.5646L631.082 81.5293V85.2956H634.813V81.5646Z" fill="#373B3D"/>
<path d="M626.633 81.5646L622.902 81.5293V85.2956H626.633V81.5646Z" fill="#373B3D"/>
<path d="M487.396 81.5646L483.665 81.5293V85.2956H487.396V81.5646Z" fill="#373B3D"/>
<path d="M479.204 81.5646L475.473 81.5293V85.2956H479.204V81.5646Z" fill="#373B3D"/>
<path d="M471.012 81.5646L467.281 81.5293V85.2956H471.012V81.5646Z" fill="#373B3D"/>
<path d="M462.821 81.5646L459.09 81.5293V85.2956H462.821V81.5646Z" fill="#373B3D"/>
<path d="M454.629 81.5646L450.898 81.5293V85.2956H454.629V81.5646Z" fill="#373B3D"/>
<path d="M446.449 81.5646L442.718 81.5293V85.2956H446.449V81.5646Z" fill="#373B3D"/>
<path d="M438.257 81.5646L434.526 81.5293V85.2956H438.257V81.5646Z" fill="#373B3D"/>
<path d="M430.065 81.5646L426.334 81.5293V85.2956H430.065V81.5646Z" fill="#373B3D"/>
<path d="M421.873 81.5646L418.142 81.5293V85.2956H421.873V81.5646Z" fill="#373B3D"/>
<path d="M413.682 81.5646L409.951 81.5293V85.2956H413.682V81.5646Z" fill="#373B3D"/>
<path d="M405.49 81.5646L401.759 81.5293V85.2956H405.49V81.5646Z" fill="#373B3D"/>
<path d="M397.298 81.5646L393.567 81.5293V85.2956H397.298V81.5646Z" fill="#373B3D"/>
<path d="M389.118 81.5646L385.387 81.5293V85.2956H389.118V81.5646Z" fill="#373B3D"/>
<path d="M380.926 81.5646L377.195 81.5293V85.2956H380.926V81.5646Z" fill="#373B3D"/>
<path d="M372.734 81.5646L369.003 81.5293V85.2956H372.734V81.5646Z" fill="#373B3D"/>
<path d="M364.543 81.5646L360.812 81.5293V85.2956H364.543V81.5646Z" fill="#373B3D"/>
<path d="M356.351 81.5646L352.62 81.5293V85.2956H356.351V81.5646Z" fill="#373B3D"/>
<path d="M348.159 81.5646L344.428 81.5293V85.2956H348.159V81.5646Z" fill="#373B3D"/>
<path d="M872.328 73.4084L868.597 73.373V77.1394H872.328V73.4084Z" fill="#373B3D"/>
<path d="M864.147 73.4084L860.416 73.373V77.1394H864.147V73.4084Z" fill="#373B3D"/>
<path d="M749.474 73.4084L745.743 73.373V77.1394H749.474V73.4084Z" fill="#373B3D"/>
<path d="M716.719 73.4084L712.988 73.373V77.1394H716.719V73.4084Z" fill="#373B3D"/>
<path d="M503.779 73.4084L500.048 73.373V77.1394H503.779V73.4084Z" fill="#373B3D"/>
<path d="M495.587 73.4084L491.856 73.373V77.1394H495.587V73.4084Z" fill="#373B3D"/>
<path d="M487.396 73.4084L483.665 73.373V77.1394H487.396V73.4084Z" fill="#373B3D"/>
<path d="M479.204 73.4084L475.473 73.373V77.1394H479.204V73.4084Z" fill="#373B3D"/>
<path d="M471.012 73.4084L467.281 73.373V77.1394H471.012V73.4084Z" fill="#373B3D"/>
<path d="M462.821 73.4084L459.09 73.373V77.1394H462.821V73.4084Z" fill="#373B3D"/>
<path d="M454.629 73.4084L450.898 73.373V77.1394H454.629V73.4084Z" fill="#373B3D"/>
<path d="M446.449 73.4084L442.718 73.373V77.1394H446.449V73.4084Z" fill="#373B3D"/>
<path d="M438.257 73.4084L434.526 73.373V77.1394H438.257V73.4084Z" fill="#373B3D"/>
<path d="M430.065 73.4084L426.334 73.373V77.1394H430.065V73.4084Z" fill="#373B3D"/>
<path d="M421.873 73.4084L418.142 73.373V77.1394H421.873V73.4084Z" fill="#373B3D"/>
<path d="M413.682 73.4084L409.951 73.373V77.1394H413.682V73.4084Z" fill="#373B3D"/>
<path d="M405.49 73.4084L401.759 73.373V77.1394H405.49V73.4084Z" fill="#373B3D"/>
<path d="M397.298 73.4084L393.567 73.373V77.1394H397.298V73.4084Z" fill="#373B3D"/>
<path d="M389.118 73.4084L385.387 73.373V77.1394H389.118V73.4084Z" fill="#373B3D"/>
<path d="M380.926 73.4084L377.195 73.373V77.1394H380.926V73.4084Z" fill="#373B3D"/>
<path d="M372.734 73.4084L369.003 73.373V77.1394H372.734V73.4084Z" fill="#373B3D"/>
<path d="M364.543 73.4084L360.812 73.373V77.1394H364.543V73.4084Z" fill="#373B3D"/>
<path d="M356.351 73.4084L352.62 73.373V77.1394H356.351V73.4084Z" fill="#373B3D"/>
<path d="M348.159 73.4084L344.428 73.373V77.1394H348.159V73.4084Z" fill="#373B3D"/>
<path d="M339.968 73.4084L336.237 73.373V77.1394H339.968V73.4084Z" fill="#373B3D"/>
<path d="M872.328 65.2633L868.597 65.228V68.9944H872.328V65.2633Z" fill="#373B3D"/>
<path d="M774.05 65.2633L770.319 65.228V68.9944H774.05V65.2633Z" fill="#373B3D"/>
<path d="M765.858 65.2633L762.127 65.228V68.9944H765.858V65.2633Z" fill="#373B3D"/>
<path d="M757.666 65.2633L753.935 65.228V68.9944H757.666V65.2633Z" fill="#373B3D"/>
<path d="M749.474 65.2633L745.743 65.228V68.9944H749.474V65.2633Z" fill="#373B3D"/>
<path d="M741.294 65.2633L737.563 65.228V68.9944H741.294V65.2633Z" fill="#373B3D"/>
<path d="M724.911 65.2633L721.179 65.228V68.9944H724.911V65.2633Z" fill="#373B3D"/>
<path d="M716.719 65.2633L712.988 65.228V68.9944H716.719V65.2633Z" fill="#373B3D"/>
<path d="M511.96 65.2633L508.229 65.228V68.9944H511.96V65.2633Z" fill="#373B3D"/>
<path d="M503.779 65.2633L500.048 65.228V68.9944H503.779V65.2633Z" fill="#373B3D"/>
<path d="M495.587 65.2633L491.856 65.228V68.9944H495.587V65.2633Z" fill="#373B3D"/>
<path d="M487.396 65.2633L483.665 65.228V68.9944H487.396V65.2633Z" fill="#373B3D"/>
<path d="M479.204 65.2633L475.473 65.228V68.9944H479.204V65.2633Z" fill="#373B3D"/>
<path d="M471.012 65.2633L467.281 65.228V68.9944H471.012V65.2633Z" fill="#373B3D"/>
<path d="M462.821 65.2633L459.09 65.228V68.9944H462.821V65.2633Z" fill="#373B3D"/>
<path d="M454.629 65.2633L450.898 65.228V68.9944H454.629V65.2633Z" fill="#373B3D"/>
<path d="M446.449 65.2633L442.718 65.228V68.9944H446.449V65.2633Z" fill="#373B3D"/>
<path d="M438.257 65.2633L434.526 65.228V68.9944H438.257V65.2633Z" fill="#373B3D"/>
<path d="M430.065 65.2633L426.334 65.228V68.9944H430.065V65.2633Z" fill="#373B3D"/>
<path d="M421.873 65.2633L418.142 65.228V68.9944H421.873V65.2633Z" fill="#373B3D"/>
<path d="M413.682 65.2633L409.951 65.228V68.9944H413.682V65.2633Z" fill="#373B3D"/>
<path d="M405.49 65.2633L401.759 65.228V68.9944H405.49V65.2633Z" fill="#373B3D"/>
<path d="M397.298 65.2633L393.567 65.228V68.9944H397.298V65.2633Z" fill="#373B3D"/>
<path d="M389.118 65.2633L385.387 65.228V68.9944H389.118V65.2633Z" fill="#373B3D"/>
<path d="M380.926 65.2633L377.195 65.228V68.9944H380.926V65.2633Z" fill="#373B3D"/>
<path d="M372.734 65.2633L369.003 65.228V68.9944H372.734V65.2633Z" fill="#373B3D"/>
<path d="M364.543 65.2633L360.812 65.228V68.9944H364.543V65.2633Z" fill="#373B3D"/>
<path d="M356.351 65.2633L352.62 65.228V68.9944H356.351V65.2633Z" fill="#373B3D"/>
<path d="M348.159 65.2633L344.428 65.228V68.9944H348.159V65.2633Z" fill="#373B3D"/>
<path d="M749.474 57.1071L745.743 57.0718V60.8381H749.474V57.1071Z" fill="#373B3D"/>
<path d="M511.96 57.1071L508.229 57.0718V60.8381H511.96V57.1071Z" fill="#373B3D"/>
<path d="M503.779 57.1071L500.048 57.0718V60.8381H503.779V57.1071Z" fill="#373B3D"/>
<path d="M495.587 57.1071L491.856 57.0718V60.8381H495.587V57.1071Z" fill="#373B3D"/>
<path d="M487.396 57.1071L483.665 57.0718V60.8381H487.396V57.1071Z" fill="#373B3D"/>
<path d="M479.204 57.1071L475.473 57.0718V60.8381H479.204V57.1071Z" fill="#373B3D"/>
<path d="M471.012 57.1071L467.281 57.0718V60.8381H471.012V57.1071Z" fill="#373B3D"/>
<path d="M462.821 57.1071L459.09 57.0718V60.8381H462.821V57.1071Z" fill="#373B3D"/>
<path d="M454.629 57.1071L450.898 57.0718V60.8381H454.629V57.1071Z" fill="#373B3D"/>
<path d="M446.449 57.1071L442.718 57.0718V60.8381H446.449V57.1071Z" fill="#373B3D"/>
<path d="M438.257 57.1071L434.526 57.0718V60.8381H438.257V57.1071Z" fill="#373B3D"/>
<path d="M430.065 57.1071L426.334 57.0718V60.8381H430.065V57.1071Z" fill="#373B3D"/>
<path d="M421.873 57.1071L418.142 57.0718V60.8381H421.873V57.1071Z" fill="#373B3D"/>
<path d="M413.682 57.1071L409.951 57.0718V60.8381H413.682V57.1071Z" fill="#373B3D"/>
<path d="M405.49 57.1071L401.759 57.0718V60.8381H405.49V57.1071Z" fill="#373B3D"/>
<path d="M397.298 57.1071L393.567 57.0718V60.8381H397.298V57.1071Z" fill="#373B3D"/>
<path d="M389.118 57.1071L385.387 57.0718V60.8381H389.118V57.1071Z" fill="#373B3D"/>
<path d="M380.926 57.1071L377.195 57.0718V60.8381H380.926V57.1071Z" fill="#373B3D"/>
<path d="M372.734 57.1071L369.003 57.0718V60.8381H372.734V57.1071Z" fill="#373B3D"/>
<path d="M364.543 57.1071L360.812 57.0718V60.8381H364.543V57.1071Z" fill="#373B3D"/>
<path d="M356.351 57.1071L352.62 57.0718V60.8381H356.351V57.1071Z" fill="#373B3D"/>
<path d="M765.858 48.9508L762.127 48.9155V52.6819H765.858V48.9508Z" fill="#373B3D"/>
<path d="M511.96 48.9508L508.229 48.9155V52.6819H511.96V48.9508Z" fill="#373B3D"/>
<path d="M503.779 48.9508L500.048 48.9155V52.6819H503.779V48.9508Z" fill="#373B3D"/>
<path d="M495.587 48.9508L491.856 48.9155V52.6819H495.587V48.9508Z" fill="#373B3D"/>
<path d="M487.396 48.9508L483.665 48.9155V52.6819H487.396V48.9508Z" fill="#373B3D"/>
<path d="M479.204 48.9508L475.473 48.9155V52.6819H479.204V48.9508Z" fill="#373B3D"/>
<path d="M471.012 48.9508L467.281 48.9155V52.6819H471.012V48.9508Z" fill="#373B3D"/>
<path d="M462.821 48.9508L459.09 48.9155V52.6819H462.821V48.9508Z" fill="#373B3D"/>
<path d="M454.629 48.9508L450.898 48.9155V52.6819H454.629V48.9508Z" fill="#373B3D"/>
<path d="M446.449 48.9508L442.718 48.9155V52.6819H446.449V48.9508Z" fill="#373B3D"/>
<path d="M438.257 48.9508L434.526 48.9155V52.6819H438.257V48.9508Z" fill="#373B3D"/>
<path d="M430.065 48.9508L426.334 48.9155V52.6819H430.065V48.9508Z" fill="#373B3D"/>
<path d="M421.873 48.9508L418.142 48.9155V52.6819H421.873V48.9508Z" fill="#373B3D"/>
<path d="M413.682 48.9508L409.951 48.9155V52.6819H413.682V48.9508Z" fill="#373B3D"/>
<path d="M405.49 48.9508L401.759 48.9155V52.6819H405.49V48.9508Z" fill="#373B3D"/>
<path d="M397.298 48.9508L393.567 48.9155V52.6819H397.298V48.9508Z" fill="#373B3D"/>
<path d="M389.118 48.9508L385.387 48.9155V52.6819H389.118V48.9508Z" fill="#373B3D"/>
<path d="M380.926 48.9508L377.195 48.9155V52.6819H380.926V48.9508Z" fill="#373B3D"/>
<path d="M372.734 48.9508L369.003 48.9155V52.6819H372.734V48.9508Z" fill="#373B3D"/>
<path d="M364.543 48.9508L360.812 48.9155V52.6819H364.543V48.9508Z" fill="#373B3D"/>
<path d="M356.351 48.9508L352.62 48.9155V52.6819H356.351V48.9508Z" fill="#373B3D"/>
<path d="M495.587 40.8058L491.856 40.7705V44.5368H495.587V40.8058Z" fill="#373B3D"/>
<path d="M487.396 40.8058L483.665 40.7705V44.5368H487.396V40.8058Z" fill="#373B3D"/>
<path d="M471.012 40.8058L467.281 40.7705V44.5368H471.012V40.8058Z" fill="#373B3D"/>
<path d="M462.821 40.8058L459.09 40.7705V44.5368H462.821V40.8058Z" fill="#373B3D"/>
<path d="M454.629 40.8058L450.898 40.7705V44.5368H454.629V40.8058Z" fill="#373B3D"/>
<path d="M446.449 40.8058L442.718 40.7705V44.5368H446.449V40.8058Z" fill="#373B3D"/>
<path d="M438.257 40.8058L434.526 40.7705V44.5368H438.257V40.8058Z" fill="#373B3D"/>
<path d="M430.065 40.8058L426.334 40.7705V44.5368H430.065V40.8058Z" fill="#373B3D"/>
<path d="M421.873 40.8058L418.142 40.7705V44.5368H421.873V40.8058Z" fill="#373B3D"/>
<path d="M413.682 40.8058L409.951 40.7705V44.5368H413.682V40.8058Z" fill="#373B3D"/>
<path d="M405.49 40.8058L401.759 40.7705V44.5368H405.49V40.8058Z" fill="#373B3D"/>
<path d="M397.298 40.8058L393.567 40.7705V44.5368H397.298V40.8058Z" fill="#373B3D"/>
<path d="M389.118 40.8058L385.387 40.7705V44.5368H389.118V40.8058Z" fill="#373B3D"/>
<path d="M380.926 40.8058L377.195 40.7705V44.5368H380.926V40.8058Z" fill="#373B3D"/>
<path d="M372.734 40.8058L369.003 40.7705V44.5368H372.734V40.8058Z" fill="#373B3D"/>
<path d="M364.543 40.8058L360.812 40.7705V44.5368H364.543V40.8058Z" fill="#373B3D"/>
<path d="M471.012 32.6496L467.281 32.6143V36.3806H471.012V32.6496Z" fill="#373B3D"/>
<path d="M462.821 32.6496L459.09 32.6143V36.3806H462.821V32.6496Z" fill="#373B3D"/>
<path d="M454.629 32.6496L450.898 32.6143V36.3806H454.629V32.6496Z" fill="#373B3D"/>
<path d="M446.449 32.6496L442.718 32.6143V36.3806H446.449V32.6496Z" fill="#373B3D"/>
<path d="M438.257 32.6496L434.526 32.6143V36.3806H438.257V32.6496Z" fill="#373B3D"/>
<path d="M430.065 32.6496L426.334 32.6143V36.3806H430.065V32.6496Z" fill="#373B3D"/>
<path d="M421.873 32.6496L418.142 32.6143V36.3806H421.873V32.6496Z" fill="#373B3D"/>
<path d="M413.682 32.6496L409.951 32.6143V36.3806H413.682V32.6496Z" fill="#373B3D"/>
<path d="M405.49 32.6496L401.759 32.6143V36.3806H405.49V32.6496Z" fill="#373B3D"/>
<path d="M397.298 32.6496L393.567 32.6143V36.3806H397.298V32.6496Z" fill="#373B3D"/>
<path d="M372.734 32.6496L369.003 32.6143V36.3806H372.734V32.6496Z" fill="#373B3D"/>
<path d="M479.204 24.4928L475.473 24.4575V28.2239H479.204V24.4928Z" fill="#373B3D"/>
<path d="M471.012 24.4928L467.281 24.4575V28.2239H471.012V24.4928Z" fill="#373B3D"/>
<path d="M462.821 24.4928L459.09 24.4575V28.2239H462.821V24.4928Z" fill="#373B3D"/>
<path d="M454.629 24.4928L450.898 24.4575V28.2239H454.629V24.4928Z" fill="#373B3D"/>
<path d="M446.449 24.4928L442.718 24.4575V28.2239H446.449V24.4928Z" fill="#373B3D"/>
<path d="M438.257 24.4928L434.526 24.4575V28.2239H438.257V24.4928Z" fill="#373B3D"/>
<path d="M430.065 24.4928L426.334 24.4575V28.2239H430.065V24.4928Z" fill="#373B3D"/>
<path d="M421.873 24.4928L418.142 24.4575V28.2239H421.873V24.4928Z" fill="#373B3D"/>
<path d="M413.682 24.4928L409.951 24.4575V28.2239H413.682V24.4928Z" fill="#373B3D"/>
<path d="M405.49 24.4928L401.759 24.4575V28.2239H405.49V24.4928Z" fill="#373B3D"/>
<path d="M471.012 16.3366L467.281 16.3013V20.0676H471.012V16.3366Z" fill="#373B3D"/>
<path d="M462.821 16.3366L459.09 16.3013V20.0676H462.821V16.3366Z" fill="#373B3D"/>
<path d="M454.629 16.3366L450.898 16.3013V20.0676H454.629V16.3366Z" fill="#373B3D"/>
<path d="M446.449 16.3366L442.718 16.3013V20.0676H446.449V16.3366Z" fill="#373B3D"/>
<path d="M438.257 16.3366L434.526 16.3013V20.0676H438.257V16.3366Z" fill="#373B3D"/>
<path d="M430.065 16.3366L426.334 16.3013V20.0676H430.065V16.3366Z" fill="#373B3D"/>
<path d="M421.873 16.3366L418.142 16.3013V20.0676H421.873V16.3366Z" fill="#373B3D"/>
<path d="M421.873 8.19205L418.142 8.15674V11.9231H421.873V8.19205Z" fill="#373B3D"/>
<path d="M413.682 16.3366L409.951 16.3013V20.0676H413.682V16.3366Z" fill="#373B3D"/>
<path d="M405.49 16.3366L401.759 16.3013V20.0676H405.49V16.3366Z" fill="#373B3D"/>
<path d="M462.821 8.19205L459.09 8.15674V11.9231H462.821V8.19205Z" fill="#373B3D"/>
<path d="M454.629 8.19205L450.898 8.15674V11.9231H454.629V8.19205Z" fill="#373B3D"/>
<path d="M446.449 8.19205L442.718 8.15674V11.9231H446.449V8.19205Z" fill="#373B3D"/>
<path d="M438.257 8.19205L434.526 8.15674V11.9231H438.257V8.19205Z" fill="#373B3D"/>
<path d="M413.682 8.19205L409.951 8.15674V11.9231H413.682V8.19205Z" fill="#373B3D"/>
<path d="M454.629 0.0353094L450.898 0V3.76634H454.629V0.0353094Z" fill="#373B3D"/>
<path d="M462.821 0.0353094L459.09 0V3.76634H462.821V0.0353094Z" fill="#373B3D"/>
<path d="M446.449 0.0353094L442.718 0V3.76634H446.449V0.0353094Z" fill="#373B3D"/>
<path d="M438.269 0.0353094L434.538 0V3.76634H438.269V0.0353094Z" fill="#373B3D"/></g>
);