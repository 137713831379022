import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import ReactGA from "react-ga";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { HelmetProvider } from "react-helmet-async";

import { HashRouter } from "react-router-dom";
import theme from "./theme";

const GA_ID: string = import.meta.env.VITE_GA_ID || "";
ReactGA.initialize(GA_ID);

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <React.StrictMode>
    <HashRouter>
      <ThemeProvider theme={theme}>
        <HelmetProvider>
          <CssBaseline />
          <App />
        </HelmetProvider>
      </ThemeProvider>
    </HashRouter>
  </React.StrictMode>
);
