

import React, { Fragment, useState, ChangeEvent } from "react";
import { Controller, Control } from "react-hook-form";
import { Box, Typography, IconButton, Stack } from "@mui/material";
import CloudUploadRoundedIcon from "@mui/icons-material/CloudUploadRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { Button } from "../button/Button";
import { colors } from "../../theme";

interface FileUploadProps {
  control: Control<any, any>;
  name: string;
  label?: string;
  accept?: string;
  required?: boolean;
  loading?: boolean;
}

const FileUpload: React.FC<FileUploadProps> = ({
  control,
  name,
  label,
  accept = ".pdf",
  loading,
  required = false,
}) => {
  const [fileName, setFileName] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const MAX_FILE_SIZE_MB = 10;

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required ? "This field is required" : false,
      }}
      render={({ field: { onChange }, fieldState: { error: fieldError } }) => (
        <Fragment>
          <Stack direction='column'>
            <Typography variant='body1' gutterBottom>
              {label}
            </Typography>
            <Button
              variant='outlined'
              component='label'
              disabled={loading}
              startIcon={
                <CloudUploadRoundedIcon
                  sx={{ width: 24, height: 24, mb: 0.5 }}
                />
              }
              sx={{
                display: "flex",
                alignItems: "center",
                borderColor: colors.black,
                color: colors.black,
                width: "auto",
                "&:hover": {
                  color: colors.white,
                },
              }}>
              <Typography component='span' variant='body1' gutterBottom>
                Select File
              </Typography>
              <input
                type='file'
                hidden
                accept={accept}
                onChange={(e) => {
                  const file = e.target.files?.[0];
                  if (file) {
                    if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
                      setError(
                        `File size should not exceed ${MAX_FILE_SIZE_MB} MB.`
                      );
                      setFileName(null);
                      onChange(null);
                    } else {
                      setError(null);
                      setFileName(file.name);
                      onChange(file);
                    }
                  }
                }}
              />
            </Button>

            {(fieldError || error) && (
              <Typography variant='body2' color='error' mt={1}>
                {fieldError?.message || error}
              </Typography>
            )}
          </Stack>
          {fileName && (
            <Box display='flex' alignItems='center' mt={1}>
              <Typography variant='body2'>{fileName}</Typography>
              <IconButton
                onClick={() => {
                  setFileName(null);
                  onChange(null);
                }}
                color='error'
                aria-label='Remove File'
                sx={{ ml: 1 }}>
                <ClearRoundedIcon />
              </IconButton>
            </Box>
          )}
        </Fragment>
      )}
    />
  );
};

export default FileUpload;
